.quick-deposit-form {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 1;
  pointer-events: auto;
  background: rgba(color(primary, dark), .7);
  transition: opacity $main-transition-time;

  @include media-md {
    display: none;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .group-root:not(:empty) {
    display: flex;
    padding: 20px;

    .grid-root:first-child {
      width: auto;
      margin-right: 15px;
    }
  }
  .payment-method-field {
    width: auto;

    label {
      display: none;

      + div {
        margin-top: 0;
      }
    }
    .select-selectMenu {
      span {
        display: none;
      }
    }
  }
  .input-valuesLabel,
  .input-values {
    display: none;
  }
  .field-inputValues {
    > label {
      display: none;
    }
    .input-root {
      margin-top: 0;
    }
  }
  .input-underline {
    padding: 8px 10px;
    background: color(primary, light);

    &::before,
    &::after {
      right: 10px;
      bottom: 7px;
      left: 10px;
    }
  }
  .input-inputSingleline[type="number"] {
    max-width: 100px;
    height: 30px;
    padding: 0;
    font-size: 18px;
    text-align: center;
  }
  .field-helper {
    position: absolute;
    bottom: -14px;
    white-space: nowrap;

    &:empty {
      margin: 0;
    }
  }
  .button-primary {
    width: 100%;
    height: 46px;
    font-size: 20px;
  }
  .select-select {
    color: color(primary, light);
  }
  .field-select label[data-shrink="true"] {
    color: color(primary, light);
  }
  .select-icon {
    fill: color(primary, light);
  }
}
.applied-country--swe {
  // #root
  [data-url="/group/all"],
  [data-url="/all-games"] {
    .quick-deposit-form {
      display: none;
    }
  }
}
.games-groups-container {
  position: relative;
  margin: 17px auto 40px;
  flex: auto;

  @include media-xs {
    margin-top: 18px;
  }

  &:empty {
    &::before {
      content: attr(data-not-available);
      display: block;
      padding: 10px 0;
      background: rgba(color(primary, dark), 0.5);
      color: $white;
      font-size: 18px;
      text-align: center;
    }
  }
}
#game-list-anchor {
  position: relative;
  top: 0;
}
