.guides {
  display: flex;
  flex-direction: column;
  .static-page__content {
    max-width: 100%;
    margin: 0 0 0 var(--lg-resize);
    @media (max-width: 1283px) {
      margin: 0;
    }
  }
  &__block {
    &--description {
      margin-bottom: 20px;
    }
    &--links {
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid lightgray;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    &--text {
      padding: 0 10px 10px 10px;
    }
    &--title {
      margin-bottom: 20px;
      a {
        color: black;
      }
    }
    &--image {
      flex: 0 0 200px;
      padding-right: 10px;
      img {
        max-width: 100%;
      }
    }
  }
}

.section {
  &__title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 20px;


    &--title {
      h2 {
        margin-bottom: 0;
        font-size: 20px;
      }
    }
    &--guides {
      display: flex;
      border-bottom: 1px solid lightgray;

      @media (max-width: $size-sm) {
        display: block;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

