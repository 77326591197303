.home-slider {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  &__wrapper {
    min-height: calc(100vw / 5);
    background-color: $black-1;
    @media (max-width: $size-sm) and (orientation:portrait){
      min-height: 370px;
    }
    &--mobile {
      .home-slider__banner {
        @media (max-width: $size-md) and (orientation:landscape) {
          height: calc(100vh - $header-height-mobile - $home-menu-mobile-height);
        }
      }
    }
  }
  &__slide {
    display: inline-flex;
    height: 100%;
    width: 100%;
    position: relative;
    justify-content: center;
    &-container {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: start;
      flex-direction: column;
      width: 100%;
      height: 100%;
      z-index: 15;
      @include media-md {
        padding: 30px;
      }
      @include media-md {
        &.darkening {
          .container {
            position: relative;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,1,1,0.6446953781512605) 80%, rgba(0,0,0,1) 100%);
          }
        }
      }
      @media (max-width: 560px) {
        padding: 0 10px 25px;
      }
      &--short {
        @media (max-width: $size-lg) {
          padding: 0 100px 50px;
        }
        @media (max-width: $size-md) {
          .button-root {
            display: flex;
            width: 140px;
            white-space: nowrap;
            font-size: 20px;
            padding: 10px 20px;
          }
        }
        @media (max-width: $size-sm) and (orientation:portrait) {
          padding: 0;
          .container {
            position: absolute;
            top: 5vw;
            left: 10vw;
            right: 0;
            bottom: 0;
            @media (max-width: 445px) {
              left: 7vw;
            }
          }
          .banner-mobile {
            max-width: 100%;
          }
          .home-slider__slide-mobile-btn {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 250px;
          }
        }
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      white-space: normal;
      color: $gray-4;

      @include media-lg {
        font-size: 20px;
      }
      @media (max-width: 560px) {
        font-size: 14px !important;
      }
      &--big {
        font-size: 50px;
        max-width: 600px;
        text-transform: uppercase;
        white-space: normal;
        font-weight: 800;
        color: $gray-4;
        @media (max-width: $size-ml) {
          font-size: 40px;
        }
        @media (max-width: $size-md) {
          font-size: 24px;
          max-width: 370px;
        }
      }
      &--gradient {
        font-size: 65px;
        max-width: 600px;
        font-weight: 800;
        color: $gray-4;
        text-transform: uppercase;
        @media (max-width: $size-lg) {
          font-size: 50px;
        }
        @media (max-width: $size-ml) {
          font-size: 40px;
        }
        @media (max-width: $size-md) {
          font-size: 30px;
        }
        @media (max-width: $size-sm) {
          margin-bottom: 20px;
          line-height: 0.9;
          span {
            &:first-child {
              font-size: 40px;
              display: block;
            }
            &:last-child {
              font-size: 60px;
            }
          }
        }
        span {
          &:first-child {
            background: linear-gradient(to right, #FFB528, #FF7E00);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }
      }
    }
    &-list {
      list-style: none;
      color: $white;
      padding: 0;
      line-height: 1.5;
      counter-reset: home-list-item;
      @media (max-width: $size-sm) {
        display: block;
      }
      li {
        text-transform: uppercase;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: $size-lg) {
          font-size: 20px;
        }
        @media (max-width: $size-ml) {
          font-size: 18px;
        }
        @media (max-width: $size-md) {
          font-size: 16px;
        }
        &:before {
          content: counter(home-list-item);
          counter-increment: home-list-item;
          width: 31px;
          height: 31px;
          background: linear-gradient(to right, #FFB528, #FF7E00);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 18px;
          margin-right: 10px;
          @media (max-width: $size-lg) {
            width: 24px;
            height: 24px;
            font-size: 14px;
          }
          @media (max-width: $size-md) {
            width: 18px;
            height: 18px;
            font-size: 12px;
          }
        }
      }
    }
    &-content {
      max-width: 600px;
      margin-top: 22px;
      font-size: 40px;
      font-weight: 700;
      white-space: normal;
      color: $gray-4;

      @include media-lg {
        font-size: 28px;
      }
      @media (max-width: 560px) {
        margin-top: 10px;
        font-size: 22px;
      }
      @include media-xs {
        font-size: 20px;
      }
    }
    a {
      max-width: 100%;
      margin-top: 20px;
      padding: 15px 40px;
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      text-decoration: none;
      overflow-wrap: break-word;
      white-space: normal;

      @media (max-width: 560px) {
        width: 100%;
        margin-top: 10px;
      }
      @include media-mobile {
        padding: 10px 0;
        font-size: 18px;
        border-radius: 5px;
      }
    }
  }
  &__banner {
    position: relative;
    z-index: 10;
  }
  &__banner-img {
    object-fit: cover;
    width: 100%;
    min-width: 99vw;
    height: 100%;
  }
  &__video-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 5;
    &--mobile {

    }
  }
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__prev, &__next {
    position: absolute;
    top: calc(50% - 21px);

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
    &:before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 42px;
    }
  }
  &__prev {
    left: 2%;

    &:before {
      content: '';
      background: url('/cms/img/slider/prev.svg') no-repeat center center /contain;
    }
  }
  &__next {
    right: 2%;

    &:before {
      content: '';
      background: url('/cms/img/slider/next.svg') no-repeat center center /contain;
    }
  }
}
