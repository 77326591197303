.registration-form {
  .fields-container {
    justify-content: space-between;
    margin-top: 20px;

    @media (min-width: 768px) {
      &__grid-root {
        margin: 0 -10px;
        padding: 0 10px;
      }
    }
  }
  &__successful {
    margin-top: 20px;
    text-align: center;

    .markdown-root {
      margin-bottom: 20px;
    }
  }
  &__gdpr-notification {
    font-size: 14px;
    text-align: justify;
    color: color(text, main);
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;

    @include media-sm {
      margin-bottom: 20px;
    }
    button + button {
      margin-left: 30px;
    }
  }
  .fields-container__grid-root--ageChecked,
  .fields-container__grid-root--ageAndPolicyChecked {
    max-width: 100%;
    flex-basis: 100%;
    order: 10;
  }
  .fields-container__grid-root--privacyPolicyChecked,
  .fields-container__grid-root--receiveEmail,
  .fields-container__grid-root--receiveSms,
  .fields-container__grid-root--receiveEmailAndSms,
  .fields-container__grid-root--statementPrivacyChecked,
  .fields-container__grid-root--statementLegislationChecked,
  .fields-container__grid-root--statementLegallyCompetentChecked,
  .fields-container__grid-root--statementOwnAccountChecked,
  .fields-container__grid-root--statementNoFraudAmlChecked,
  .fields-container__grid-root--statementIdentificationChecked,
  .fields-container__grid-root--statementPersonalUseAndMoney,
  .fields-container__grid-root--publicPerson,
  .fields-container__grid-root--campaignsEnabled{
    max-width: 100%;
    flex-basis: 100%;
    order: 11;
  }
  .fields-container__grid-root--receiveEmail {
    label {
      align-items: flex-start;
    }
  }
  .fields-container__grid-root--selected-country-ROU {
    &.fields-container__grid-root--birthDate {
      display: none;
    }
  }
  &__logging-in {
    margin-top: 10px;
    text-align: center;

    &-text {
      margin-top: 10px;
    }
  }
}
