.modal-root .modal-contentRoot {
  .incode-verification-form {
    margin: 0 -50px;

    @include media-sm {
      margin: 0 -20px;
    }
  }
}
.modal-root,
.popup-decorator {
  overflow: visible;
  border-radius: 0;

  @include media-xs {
    top: $header-height-mobile;
  }

  &.is-submodal {
    height: auto;

    .modal-paper {
      width: 420px;
      margin: 16px;

      .modalContentWrapper {
        .modal-titleRoot {
          text-align: center;
          padding-bottom: 30px;
        }

        .modal-contentRoot {
          padding-right: 25px;
          padding-left: 25px;

          .markdown-root {
            text-align: center;
          }

          .confirmation-modal__buttons {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }

  .modal-buttonRoot {
    @include close-button;
  }
  .modal-rootButtons {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 10000;
  }
  .modal-rootButton {
    > .button-default {
      min-height: 32px;
      padding: 4px 8px;
      font-size: 16px;
      text-decoration: none;

      &:after {
        display: none;
      }
      > .button-label {
        white-space: nowrap;
      }
    }
  }
  .modal-paper {
    width: 800px;
    max-width: 100%;
    border-radius: 6px;
    background: color(background, modal);

    .modalContentWrapper {
      display: flex;
      flex-direction: column;
      padding: 50px 0;
      border-radius: inherit;
      overflow-y: auto; // [FIN-21054] - wrong style in FireFox
      background: color(background, modal);

      @include media-mobile {
        padding: var(--xl-resize) var(--lg-resize) var(--md-resize);
      }
      .modal-titleRoot {
        padding: 0 50px var(--xl-resize);

        @include media-mobile {
          padding: 0 10px var(--xl-resize);
        }

        h2 {
          @include field-font__h3;
          text-transform: none;
          color: color(modal, title);
        }
        & + .modal-contentRoot {
          padding-top: 0;

          h1 {
            display: none;
          }
        }
      }

      .modal-contentRoot,
      .popup-decorator__content {
        color: color(modal, text);
        border: 0;
        border-radius: 0;
      }
      .modal-contentRoot {
        padding: 0 50px 0;
        overscroll-behavior: none;
        overflow-x: hidden;
        color: color(modal, text);
        border: 0;
        border-radius: 0;

        @include media-mobile {
          padding: 0 10px 0;
        }

        .field-helperHint {
          font-size: 14px;
        }
      }
      .modal-contentRoot > div:not(.notification-root):not(.grid-root):not(.deposit-form):not(.withdrawal-form):not(.resume-form):not(.verification-form):not(.game-search) {
        display: block;
        height: 100%;

        @include isMozillaFirefox {
          height: auto;
        }

        @include isMicrosoftEdge {
          height: auto;
        }
      }
      .field-root {
        padding-right: 0;
      }
    }
  }
  .content__wrapper {
    width: 100%;

    .group-root {
      width: 100%;
    }
  }
}
.page-modal--id-terms-conditions,
.page-modal--id-privacy-policy,
.page-modal--id-responsible-gambling,
.page-modal--id-bonus-terms {
  .modal-paper {
    width: 1168px;
  }
  .underlined-title {
    display: none;
  }
}
.modal__gambling-message {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  margin: 20px 0;
  padding-bottom: 0 !important; // override: .modal-root .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child `padding-bottom`
  text-align: center;

  @include media-sm {
    text-align: left;
  }

  img {
    @include size(32px);
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 8px;
    filter: brightness(0) grayscale(100%);
  }

  p {
    margin: 0;
    font-size: 14px;
    color: color(text, light);
  }
}
.confirmation-modal__buttons {
  margin-top: 20px;
  justify-content: center;

  .gridItem-root {
    max-width: initial;
    flex-basis: auto;
  }
  .button-accent {
    @include button-primary;
  }
}

/* FreeSpins Finished modal */

.free-spins-finished-modal {
  &__buttons {
    text-align: center;

    button,
    button + button {
      margin: 0 7.5px;
    }
    @include media-xs {
      button + button {
        margin-top: 15px;
      }
    }
  }
}

.campaigns__modal {
  .markdown-root {
    text-align: center;
  }
  .modal-paper .modalContentWrapper .modal-titleRoot {
    text-align: center;
    padding: 0 50px 30px;
  }
  .modal-paper {
    width: 500px;
  }
}

@include media-mobile {
  .modal-root {
    .modal-contentRoot {
      display: flex;
      flex-direction: column;
    }
    .modal-paper {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      justify-content: center;
      border-radius: 0;
    }
  }
}

.modal-confirmation-small {
  .modal-paper {
    width: 600px;
  }
  .group-root--buttons {
    padding-top: var(--md-resize);
  }
  .modal-paper .modalContentWrapper .modal-titleRoot {
    padding-bottom: var(--md-resize);
  }
  .group-root--buttons {
    @include flexGap($columns: 2);
    @include buttons-container;
  }
}
