.game-name-filter {
  &__icon {
    &::before {
      @include svg-icon(custom);
      content: '\0059';
      font-size: 30px;
      vertical-align: middle;
      color: #9692B6;
    }
  }
  &__clear {
    @include close-button;
    position: absolute;
    min-height: 25px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
    &::before {
      padding: 6px;
      border-radius: 50%;
      border: 2px solid $gray-2;
      font-weight: $font-weight-alt;
      font-size: 13px;
      color: $gray-10;

      @include media-sm {
        border: initial;
      }
    }
  }
}

.home__games-filter__name {
  width: 525px;
  max-width: 90%;
  height: 48px;
  margin-top: 17px;

  &::before, &::after {
    display: none;
  }
  .input-before {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
  }
  input {
    width: 100%;
    height: 100%;
    border: 1px solid #9692B6;
    border-radius: 9px;
    padding: 10px 60px 10px 55px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    font-family: $font-primary;
    background-color: $white;

    @include media-sm {
      box-shadow: initial;
      border-radius: 7px;
    }
    &::placeholder {
      line-height: initial;
      font-size: 18px;
      text-transform: uppercase;
      color: #9692B6;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  @include media-md {
    transition: all 0.3s ease;
    max-width: 100%;
  }
  @include media-sm {
    .game-name-filter__icon {
      &::after{
        display: none;
      }
      &::before {
        font-size: 24px;
      }
    }
    input {
      padding: 10px 60px;
      background-color: $white;
      border-radius: 4px;
    }
  }
}
