/* Icons */
@font-face {
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter/Glyphter.woff') format('woff');
}
/* Icons */
@font-face {
  font-family: 'Glyphter-custom';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-custom/Glyphter.woff') format('woff');
}
