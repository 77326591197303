.home-banner {
  height: 100vh;
  min-height: 470px;
  margin-top: 0;
  padding-top: 0;
  background: {
    color: color(primary, dark);
    position: center;
    repeat: no-repeat;
    size: cover;
  }

  @include media-sm {
    min-height: 680px;
  }
  @include media-md {
    margin-top: 0;
    padding-top: 0;
  }
  .container {
    height: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 30px;
    font-weight: $font-weight-base;
    text-transform: uppercase;
    color: color(primary, light);
    text-align: center;
    white-space: pre;

    @include media-md {
      white-space: normal;
    }
  }
  &__buttons {
    text-align: center;

    .button-root {
      min-width: 260px;
      margin: 0 10px;

      + .button-root {
        @include media-sm {
          margin-top: 15px;
        }
      }
    }
    .button-secondary {
      color: color(primary, light);
    }
  }
  .field-inputValues {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    label {
      font-size: 18px;
      color: color(primary, light);

      &:first-child {
        position: static;
        order: 1;
        margin-right: 10px;
        align-self: flex-end;
      }
    }
    &.field-error {
      label:first-child {
        color: color(error);
      }
    }
    .input-valuesLabel,
    .input-values,
    .field-helper {
      width: 100%;
    }
    .input-values .input-value {
      border-color: color(primary, light);

      button {
        min-height: 50px;
        font-size: 18px;
        color: color(primary, light);

        @include media-xs {
          font-size: 14px;
        }
      }
      &.input-selectedValue {
        background: color(primary, main);

        button {
          color: color(primary, dark);
        }
      }
    }
    .input-underline {
      flex: 1;
      order: 2;

      &::before {
        background: color(primary, light);
      }
    }
    .input-inputSingleline {
      text-align: center;
      font-size: 22px;
      color: color(primary, light);
    }
    input::-moz-placeholder {
      opacity: 0;
    }
    input::-webkit-input-placeholder {
      opacity: 0;
    }
    .field-helper {
      order: 3;
    }
  }
  .quick-deposit-button {
    width: 100%;
    height: 50px;;
    margin-top: 20px;
    font-size: 20px;
    text-transform: uppercase;
  }
  .select-select {
    color: color(primary, light);
  }
  label[data-shrink="true"] {
    color: color(primary, light);
  }
  .select-icon {
    fill: color(primary, light);
  }
}
