.games-search-result {
  &__games-search-result {
    margin: 70px auto 0 auto;
    @media (max-width: 1023px) {
      margin: 15px auto 0 auto;
    }
    &--mobile {
      margin-bottom: 30px;
    }
  }
}
