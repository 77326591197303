.tournament {
  &__banner {
    &-container {
      position: relative;
    }

    img {
      width: 100%;
      height: 300px;
    }
  }

  &__timer-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-size: 24px;
      text-transform: uppercase;

      @media (max-width: 1265px) {
        font-size: 18px;
      }

      @media (max-width: 890px) {
        font-size: 16px;
      }
    }

    .timer {
      display: flex;
      gap: 5px;

      &__item {
        text-align: center;

        span {
          font-size: 10px;
        }
      }

      &__value {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(217, 217, 217, 0.2);

        > p {
          color: $white;
        }

        @include media-ml {
          height: 30px;
          font-size: 18px;
        }
      }
    }
  }

  &__description {
    display: flex;
    gap: 30px;
    margin: 40px 0;

    &-block {
      height: 380px;
      min-width: 175px;
      background-color: $white;
      border-radius: 10px;
      flex: 1;

      &-header {
        height: 50px;
        background: #9692B6;
        color: #FFFFFF;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 15px;
        white-space: nowrap;
        border-radius: 10px 10px 0 0;

        @include media-mobile {
          border-radius: 5px 5px 0 0;
        }
      }

      &-content {
        padding: 20px 17px;
        height: 100%;
      }
    }

    @include media-md {
      flex-wrap: wrap;

      &-block {
        width: calc(50% - 15px);
      }
      .tournament__description-block:last-child {
        width: calc(50% - 15px);
      }
    }

    @include media-mobile {
      display: flex;
      flex-direction: column;

      &-block {
        width: inherit;
        height: 100%;

        &-item {
          display: flex;
          flex-direction: row;
          font-size: 18px;
        }

        &-content {
          padding: 20px 17px;
        }
      }
      .tournament__description-block:last-child {
        width: 100%;
      }
    }
  }

  // Buttons
  &__buttons {
    text-align: left;
    padding: 10px 15px;

    &:after {
      content: '';
      min-width: 5px;
    }

    button {
      margin-right: 10px;

      @include media-sm {
        min-width: initial;
        white-space: nowrap;
        padding: 6px 12px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    h1,
    .markdown-root {
      text-align: left;
    }

    h1 {
      font-size: 30px;
    }
  }

  &__item {
    &-content {
      display: flex;
      color: color(primary, light);
      padding: 10px 15px 30px;
      text-align: left;
      position: relative;

      h3 {
        font-size: 48px;
        text-transform: uppercase;
      }
    }

    &-banner {
      border-radius: 15px 15px 0 0;
      background-color: $black-1;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        border-radius: 15px 15px 0 0;
      }

      @include media-mobile {
        border-radius: 15px;

        img {
          border-radius: 15px;
        }
      }
    }
  }

  &__block-item {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    &:last-child {
      display: flex;
      flex-direction: row;

      p,
      span {
        display: inline-block;
        margin-bottom: 0;
      }

      p {
        margin-right: 5px;
      }
    }
  }

  &__block--prize-item {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }


  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;

    @include media-mobile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      &-item {
        &:last-child {
          flex-basis: 100%;
        }
      }
    }
  }

  &__win-criteria {
    height: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    background: #FFFFFF;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 19.5px;
    }

    @include media-mobile {
      margin-bottom: 0;
      padding-bottom: 0;
      gap: 20px;
    }
  }

  &__time-title {
    padding-right: 5px;
    padding-bottom: 5px;
  }

  &__win-criteria-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 39.01px;
    margin-bottom: 35px;
  }

  &__win-criteria {
    p {
      margin-bottom: 15px !important;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    tr {
      &:not(&--bold) {
        border-top: none;
      }
    }

    td {
      padding: 8px;
      text-align: left;
      border: none;

      &:first-child {
        text-align: left;
      }
    }

    .tournament__row--bold {
      font-weight: bold;
    }

    @include media-mobile {
      td {
        padding: 0 0 15px 0;
      }
    }
  }

  &__links {
    a {
      color: orange;
      text-decoration: none;
      font-weight: bold;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  &__opt-in {
    .tournament__content_loading {
      justify-content: flex-start;
      min-height: 42px; // Same as button height
    }

    .buttons {
      text-align: left;
    }

    .notification-root {
      margin-bottom: 0;

      .notification-message {
        color: color(primary, light);

        a {
          color: color(primary, light);
        }
      }
    }
  }

  // Loading
  &__content_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__game-list {
    margin-top: 20px;

    .game-list__games--grid .game-list__games-wrapper {
      grid-template-columns: repeat(4, 1fr);

      @include media-mobile {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    h2 {
      height: 50px;
      background: #9692B6;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 15px;
      white-space: nowrap;
      border-radius: 15px;

      span {
        margin-right: 5px;
      }

      @include media-mobile {
        border-radius: 5px;
      }
    }
  }
}

.banner {
  &__description {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 30px;
    overflow: hidden;
    color: $white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-mobile {
      padding: 10px 20px 20px 20px;
    }

    @include media-xs {
      padding: 5px 20px 10px 20px;
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 800;
    color: transparent;
    background: linear-gradient(91.23deg, #FFFFFF -55.36%, #FFB528 98.38%);
    background-clip: text;
    -webkit-background-clip: text;

    h2 {
      font-size: 50px;

      @media (max-width: 1640px) {
        font-size: 38px;
      }
      @media (max-width: 1370px) {
        font-size: 42px;
      }
      @media (max-width: 1110px) {
        font-size: 38px;
      }

      @include media-md {
        font-size: 42px;
      }

      @include media-sm {
        font-size: 34px;
      }

      @media (max-width: 655px) {
        font-size: 30px;
      }

      @include media-mobile {
        font-size: 34px;
      }
    }

    @include media-xs {
      width: 80%;
      h2 {
        font-size: 28px;
      }
    }
  }

  &__info {
    .info-item {
      display: flex;
      align-items: center;
      gap: 6px;

      p {
        text-transform: uppercase;
        line-height: 21px;
        font-size: 24px;
      }

      span {
        font-weight: bold;
        font-size: 24px;
      }

      @media (max-width: 1265px) {
        p, span {
          font-size: 18px;
        }
      }

      @media (max-width: 890px) {
        p, span {
          font-size: 16px;
        }
      }
    }
  }


  &__buttons {
    a {
      text-transform: uppercase;

      @include media-mobile {
        width: 110px;
        min-width: 110px;
        padding: 0;
        border-radius: 5px;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.Rankings-section {
  width: 100%;
  flex: initial;
}
