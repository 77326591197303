@mixin button-primary {
  color: color(button--primary, text);
  background: rgb(255,182,40);
  background: linear-gradient(180deg, rgba(255,182,40,1) 0%, rgba(255,150,22,1) 50%, rgba(254,112,0,1) 100%);

  &:hover {
    color: color(button--primary, text);
    opacity: 0.8;
  }
}
@mixin button-secondary {
  color: color(button--secondary, text);
  background: rgb(84,255,0);
  background: linear-gradient(180deg, rgba(84,255,0,1) 0%, rgba(0,165,0,1) 100%);

  &:hover {
    color: color(button--primary, text);
    opacity: 0.8;
  }
}
@mixin button-default($color: color(primary, main)) {
  @include field-font__h4;
  min-width: auto;
  position: relative;
  color: $color;
  background: none;
  text-transform: initial;

  &:hover {
    background-color: transparent;
  }
}
@mixin button-small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  min-width: initial;
  min-height: 40px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  text-transform: initial;
  letter-spacing: -0.58px;

  @include media-xs {
    width: auto;
    max-width: 131px;;
  }
  &::after {
    display: none;
  }
}
@mixin button-root {
  @include field-font__h4;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  min-width: 140px;
  min-height: 40px;
  padding: 5px 15px;
  border-radius: 10px;
  font-family: $font-primary;
  font-weight: 700;
  color: color(text, main);
  text-transform: initial;
  text-align: center;

  &::before, >:nth-child(n), &::after {
    margin: 0 5px;
  }
  @include media-lg {
    font-size: 16px;
    min-height: 36px;
  }
  .button-label {
    width: initial;
    line-height: initial;
  }
  &.button-loading {
    //color: transparent;
  }
  & > span + span {
    display: none;
  }
  .markdown-root {
    p {
      margin: 0;
    }
  }
}
.button-root {
  @include button-root;
}
a.button-root {
  text-decoration: none;
}
.button-small {
 @include button-small;
}
.button-default {
  @include button-default;
}
.button-primary {
  transition: color $main-transition-time, background-color $main-transition-time, opacity $main-transition-time;
  @include button-primary;
}
.button-secondary {
  transition: color $main-transition-time, background-color $main-transition-time, opacity $main-transition-time;
  @include button-secondary;
}
.button-white {
  transition: color $main-transition-time, background-color $main-transition-time, opacity $main-transition-time;
  @include button-root;
  border: 1px solid $gray-3;
}
.button-disabled {
  cursor: not-allowed;
  color: color(button--disabled, text);
  background: color(button--disabled, background);
}
.group-root--buttons {
  margin-top: 15px;
  margin-bottom: 15px;

  button + button, button + a {
    margin-left: 15px;
  }
  a {
    text-decoration: none;
  }
}
.group-root--password-buttons {
  @include media-sm {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .button-root {
      margin: 0;
    }
  }
}

// Close button
@mixin close-button {
  @include size(32px);
  position: relative;
  min-width: 32px;
  min-height: 32px;
  padding: 0;
  flex: 0 0 auto;
  font-size: 0;
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  background-color: #596668;
  backface-visibility: hidden;

  &::before {
    @include svg-icon(custom);
    @include field-font__h5;
    content: '\0045';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: color(secondary, light);
    transition: all $main-transition-time;
  }
  &:hover {
    &::before {
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
  }
  & > span + span {
    display: none;
  }
}

a[role="button"] {
  > span {
    white-space: nowrap;
  }
}
