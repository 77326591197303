.promotions {
  &__error {
    margin: var(--lg-resize) 0;
    @include media-sm {
      margin: var(--lg-resize) var(--md-resize);
    }
  }
  &__forfeit {
    padding: 0 15px;
    margin: 40px 0;

    @include media-sm {
      margin: 20px 0;
    }
  }
  &__noCampaigns {
    background-color: white;
    border-radius: 10px;
    padding: 90px 80px;
    h2 {
      font-size: 32px;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
    }

    @include media-mobile {
      h2 {
        font-size: 22px;
      }
      p {
        font-size: 16px;
      }
      padding: 40px 20px;
    }
  }
}
