.game-thumbnail {
  background-color: $white;
  border-radius: 3px;
  overflow: hidden;

  &__tags {
    display: flex;
    gap: 5px;
    position: absolute;
    right: 13px;
    top: 10px;
  }

  &__new {
    width: max-content;
    right: 13px;
    top: 10px;
    font-size: 18px;
    font-weight: 700;
    color: color(white);
    z-index: 0;
    background-color: $orange-1;
    padding: 2px 5px;
    border-radius: 3px;
    text-transform: uppercase;
    @include media-lg {
      font-size: 14px;
    }
  }
  &__content {
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    transition: $main-transition-time;
    background: rgba(0, 0, 0, 0.7);
  }
  &__title {
    margin-top: -50px;
    padding: 18px 10px 0 10px;
    color: $gray-9;
    font-size: 18px;
    text-align: center;
    transition: all 0.3s ease;

    .desktop & {
      @media (min-width: 768px) {
        @include truncate;
      }
    }
    @include media-lg {
      padding: 10px 10px 0 10px;
      font-size: 14px;
    }
    @include media-md {
      display: none;
    }
  }
  &__buttons {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding-top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;

    @include media-md {
      padding: 0;
    }
  }
  .image-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {
      .game-thumbnail__content {
        opacity: 1;
        border-radius: 3px;

        @include media-md {
          opacity: 0;
        }
      }
      .game-thumbnail__title {
        margin-top: 0;
        position: relative;
        z-index: 2;
      }
      .game-thumbnail__buttons {
        padding-top: 0;
      }
      .game-thumbnail__badges,
      .game-thumbnail__last-results,
      .game-thumbnail__info,
      .game-thumbnail__jackpot-amount,
      .game-thumbnail__jackpot-badge {
        opacity: 0;
      }
    }
  }

  &__placeholder {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
  &--loaded {
    &::after,
    .game-thumbnail__placeholder:not(.game-thumbnail__placeholder_rendering) {
      display: none;
    }
  }
  &__badges {
    display: none;
  }
  &__jackpot-amount {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 34px;
    padding-left: 15px;
    line-height: 34px;
    color: $gray-9;
    font-size: 16px;
    font-weight: $font-weight-alt;
    text-align: left;
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    transition: opacity $main-transition-time;
    border: none;
  }
  > div {
    width: 100%;
    height: 100%;

    img {
      flex-shrink: 0;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
      transition: all 0.3s ease;
      font-size: 0;
      &[src=""] {
        visibility: hidden;
      }
      &:not([src=""]) {
        width: 100%;
        height: 100%;
      }
    }
  }
  &:hover > img {
    transform: scale(1.1);
  }
  &__title {
    .desktop .game-slider & {
      @include media-md {
        font-size: 1.75vw;
      }
    }
  }
  &__button {
    position: relative;
    &--real,
    &--demo {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      justify-content: center;
      z-index: 10000;
      font-size: 0;
      border-radius: 50%;
      transition: color 0.1s ease-in;

      &::after {
        @include svg-icon(custom);
        content: '\006e';
        font-size: 80px;
        color: color(white);
        transition: all 0.3s ease;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        @include media-lg {
          font-size: 56px;
        }
      }
      &:hover{
        background: transparent;

        &::after {
          filter: brightness(0.7);
        }
      }
      .button-label {
        margin: 0;
      }
    }
  }
  &__button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    pointer-events: none;

    @media (pointer: coarse) {
      pointer-events: auto;
    }
  }
  &__last-results {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 6px 3px 33px;
    background: {
      color: rgba(255, 255, 255, .25);
      position: 0 50%;
      repeat: no-repeat;
      size: contain;
    };
    border-radius: 16px;
    transition: opacity $main-transition-time;

    @include media-sm {
      transform: translateX(-50%) scale(.9);
    }

    &--roulette {
      background-image: url('/cms/img/games/game-thumbnail/last-results-roulette.svg');
    }
    &--blackjack {
      background-image: url('/cms/img/games/game-thumbnail/last-results-blackjack.svg');
    }
    &--baccarat {
      background-image: url('/cms/img/games/game-thumbnail/last-results-baccarat.svg');
    }
  }
  &__last-results-item {
    @include size(20px);
    border-radius: 50%;
    color: $white;
    font-size: 10px;
    font-weight: $font-weight-alt;
    line-height: 20px;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 3px;
    }

    &:first-of-type {
      @include size(24px);
      font-size: 12px;
      line-height: 24px;
    }

    &--red {
      background: #ff4747;
    }
    &--black {
      background: $black;
    }
    &--green {
      background: #71ac31;
    }
  }
  &__dealer {
    max-width: 100%;
  }
  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    width: 100%;
    transition: opacity $main-transition-time;

    @include media-sm {
      padding: 12px;
    }
  }
  &__info-item {
    color: $white;
    font-weight: $font-weight-alt;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &--name {
      font-size: 18px;

      .desktop & {
        @media (min-width: 768px) {
          @include truncate;
        }
      }
    }
    &--bets,
    &--seats {
      font-size: 12px;
    }
    &--seats {
      &-open {
        color: color(success);
      }
      &-taken {
        color: color(error);
      }
    }
  }

  .featured-games-slider & {
    .game-thumbnail__badges,
    .game-thumbnail__jackpot-amount,
    .game-thumbnail__jackpot-badge {
      display: none;
    }

    &__title {
      font-size: 20px;
    }
    &__button--real {
      padding-top: 36px;

      &::before {
        display: block;
      }
    }
  }
  &__rtp {
    position: absolute;
    left: 13px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 700;
    color: color(white);
    z-index: 1;
    @include media-lg {
      font-size: 14px;
    }
    span {
      &:first-child {
        font-size: 14px;
        font-weight: 400;

        @include media-lg {
          font-size: 12px;
        }
      }
    }
  }
  &__provider {
    position: absolute;
    right: 13px;
    bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: color(white);
    text-decoration: none;
    z-index: 999;

    @include media-lg {
      font-size: 14px;
    }
  }
  .volatility {
    position: absolute;
    right: 13px;
    bottom: 10px;
  }
}
