.post-login-content-wrap--self-exclusion {
  h2 {
    display: none;
  }
  legend {
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--body-text-dark-color);
  }
}
