* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
body #root {
  overflow-x: hidden;
}
#root > {
  div:not([class]),
  .center-content {
    display: none;
  }
}
/** put footer to the bottom of the page */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}


h1 {
  @include field-font__h1
}
h2 {
  @include field-font__h2
}
h3 {
  @include field-font__h3
}
h4 {
  @include field-font__h4
}
h5 {
  @include field-font__h5
}
h6 {
  @include field-font__h6
}
.main-decorator {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 0 auto;
    min-height: 100vh;
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-md {
      margin-top: 0;
    }
  }
}
/* **/

html,
body {
  width: 100%;
  height: 100%;
}
html {
  -webkit-text-size-adjust: 100%; // Prevent font scaling in landscape while allowing user zoom
}
body {
  @include field-font__h4;

  margin: 0;
  font-family: $font-primary;
  color: color(text, dark);
  -webkit-overflow-scrolling: touch;
  background: $black;
  scroll-behavior: smooth; // do not add scroll-behavior on html it causes page scroll for modals in FF [NFP-2880]

  #root {
    // Prevent FOUC while main.css is still loading
    display: block;
    background: {
      color: color(background, body);
    }
  }
  &.preloading #preloader::before {
    content: '';
    position: fixed;
    width: $preloading-width;
    height: $preloading-height;
    top: 50%;
    left: 50%;
    margin-top: 60px;
    margin-left: calc($preloading-width/-2);
    background: color(primary, maim);
    border-left: 0 solid color(accent, primary);
    z-index: 100000;
    animation: preloading $preloading-time cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    box-sizing: border-box;
  }

  &:not(.preloading) > #preloader{
    display: none;
  }
}
@keyframes preloading {
  0% {
    border-left: 0 solid color(accent, primary);;
  }
  100% {
    border-left: $preloading-width solid color(accent, primary);;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  html {
    scroll-behavior: smooth; // scroll-behavior on body doesn't work in webkit
  }
}
#root {
  height: 100%;

  .mobile &,
  .tablet & {
    @include customVh;
  }

  &[aria-hidden='true'] {
    .header,
    .responsible-widget,
    .quick-deposit-form,
    .game-decorator__footer {
      right: var(--scrollbarWidth);
      transition: none;
    }

    ~ .notifications {
      right: calc(15px + var(--scrollbarWidth));
      transition: none;
    }
  }
}
button {
  font-family: $font-primary;
  margin: 0;
}
p {
  margin: 0;
  line-height: normal;
}
ul {
  margin: 0;
}

.content, .markdown-root {
  ul {
    padding-left: var(--md);
  }
  p, ul {
    margin-bottom: var(--md-resize);

    &:last-child {
      margin-bottom: 0;
    }
  }
  li {
    margin-bottom: var(--sm-resize);

    &:last-child {
      margin-bottom: 0;
    }
  }
  a:not(.link):not(.button-root):not(.list-of-header__link):not(.general-info__accent):not(.general-info__button):not(.section-answer__button):not(.explanatory-information__link) {
    outline: none;
    text-decoration: none;
    transition: $main-transition-time;
    font-weight: 700;
    color: color(link--secondary, text);

    &:hover {
      color: color(link--secondary, text--hover);
      text-decoration: none;
    }
  }
}

pre {
  white-space: pre-line;
  font-family: $font-primary;
}
.main-loading {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/cms/img/logo.svg') center center / 250px no-repeat $black-1;
  z-index: 99999;
}
// preloader
div[role="progressbar"] {
  color: color(primary, main);

  .button-primary & {
    color: $black;
  }
  svg {
    min-width: 22px;
    animation: unset;
  }
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.content-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}
.content-center {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.content-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.center-content {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.group-root {
  max-width: 100%;
}
.container {
  width: 100%;
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: 1640px) {
    max-width: $size-lg;
  }
  @media (max-width: $size-lg) {
    max-width: $size-ml;
  }

  &--modal-paper {
    position: relative;
    max-width: 600px;
    margin: 60px auto;
    padding: 10px;
    border-radius: 6px;
    background: $white;

    @media (min-width: $size-mobile) {
      padding: 100px;
    }
    @include media-mobile {
      flex: 1;
      margin: 0 auto;
    }
  }
}
.static-iconSuccess::before {
  @include svg-icon;
  content: '\0043';
  color: white;
}

/* Pagination */

.paginator {
  &-root {
    display: block;
    padding: 10px;
    border: 0;
    border-radius: 0;
    background: transparent;
  }
  &-button {
    display: inline-block;
    margin: 4px;
    padding: 5px 10px;
    vertical-align: middle;
    flex: none;
    border: 0;
    background: none;

    &:hover {
      color: $gray-1;
      background: none;
    }
  }
  &-selected {
    color: $gray-1;
    border-bottom: 1px solid $gray-1;
  }
  &-first,
  &-last,
  &-prev,
  &-next {
    font-size: 0;

    &::before {
      @include svg-icon;
      padding: 4px 0;
      font-size: 11px;
    }
  }
  &-first,
  &-last {
    &::before {
      content: '\0063';
    }
  }
  &-prev,
  &-next {
    &::before {
      content: '\0062';
    }
  }
  &-first,
  &-prev {
    &::before {
      transform: rotate(180deg);
    }
  }
  &-disabled,
  &-disabled:hover {
    opacity: 0.3;
  }
}

/* Progress bar */

.progress {
  display: flex;
  width: 100%;
  height: 20px;
  overflow: hidden;
  font-size: .75em;
  background: color(turnover-progress, background);

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: color(turnover-progress, text);
    text-align: center;
    white-space: nowrap;
    background: color(accent, secondary);
    transition: width .6s ease;

    span {
      padding: 0 .25em;
      white-space: nowrap;
    }
  }
}

/* Stepper */

.stepper-root {
  padding: 0;
  counter-reset: list 0;
}
.stepper-step {
  padding: 0;

  .stepper-item {
    display: flex;
    justify-content: center;

    &:before {
      position: absolute;
    }
    p {
      display: none;
    }
  }
  .stepper-item.stepper-item--completed {
    svg {
      padding: 0;
      border-width: 5px;
      fill: color(secondary, dark);
    }
    &:before {
      @include svg-icon(custom);
      content: '\0044';
      font-size: 20px;
      color: color(accent, secondary);
    }
  }
  & div:nth-child(2) {
    top: calc(100% - 34px);
    left: calc(50% + 30px);
    right: calc(-50% + 30px);

    @media (max-width: 560px) {
      top: calc(100% - 29px);
      left: calc(50% + 24px);
      right: calc(-50% + 24px);
    }
    span {
      border-top-width: 7px;
      border-color: color(secondary, dark);
    }
  }
  p {
    margin-top: 5px;
    font-weight: $font-weight-base;
  }
  svg {
    width: 61px;
    height: 61px;
    padding: 6px;
    border-radius: 50%;
    background: color(secondary, dark);
    fill: transparent;

    @media (max-width: 560px) {
      width: 50px;
      height: 50px;
    }
    circle {
      r: 12;
    }
    text {
      fill: color(background, post-login-content);
      font-weight: 700;
    }
  }
  &.active {
    svg {
      fill: color(accent, secondary);
    }
  }
}
.currency-symbol {
  &:not(&--1) {
    padding-right: 3px;
    line-height: 1;
    font-size: 12px;
  }
}
.hidden {
  display: none;
}
@media (min-width: $size-sm) {
  .hidden--desktop {
    display: none;
  }
}
.page {
  &--not-found {
    .main-decorator__content {
      margin-bottom: 0;
    }
  }
}
.failed-import__root {
  padding: 25px 0;
  font-size: 18px;
  text-align: center;
}

.link {
  @include link--root;
  @include linkGenerator(default);
  @include linkGenerator(light);
  @include linkGenerator(primary);
  @include linkGenerator(secondary);

  @include linkGeneratorBefore(back);
  @include linkGeneratorBefore(user);
  @include linkGeneratorBefore(forgot);
  @include linkGeneratorBefore(success);

  &--row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--sm);
    height: 40px;
    color: $white;
    background: $black-1;

    &:before {
      color: color(success);
    }
    &:after {
      @include svg-icon(custom);
      content: '\0041';
    }
    .markdown-root {
      flex: 1;
    }
  }
}
.title {
  @include field-font__h2;
  font-weight: 700;
}

div.form {
  &--deposit, &--withdrawal {
    display: grid;
    grid-template-columns: calc(50% - 0.5px) calc(50% - 0.5px);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'section-1 section-3'
      'section-2 section-3';
    grid-column-gap: 1px;
    padding: 0;
    background: color(background, body);

    .section {
      &--1 {
        padding: 0 60px 0 0;
      }
      &--2 {
        padding: 30px 60px 0 0;
      }
      &--3 {
        padding: 0 0 0 60px;
      }
    }
    @include media-lg {
      .section {
        &--1 {
          padding: 0 30px 0 0;
        }
        &--2 {
          padding: 15px 30px 0 0;
        }
        &--3 {
          padding: 0 0 0 30px;
        }
      }
    }
    @include media-sm {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;
      grid-template-areas:
      'section-1'
      'section-2'
      'section-3';
      grid-gap: initial;
      background: color(background, body);

      .section {
        &--1 {
          padding: 30px 0 0 0;
        }
        &--2 {
          padding: 30px 0;
        }
        &--3 {
          padding: 30px 0;
        }
      }
    }
  }

  .section {
    background: color(background, post-login-content);

    &--1 {
      grid-area: section-1;
    }
    &--2 {
      grid-area: section-2;

      @include media-sm {
        border-bottom: 1px solid $gray-4;
      }
    }
    &--3 {
      grid-area: section-3;
    }
  }
}

.password-confirmation {
  .adornment-password span {
    font-size: 0;
  }
}
//zendesk widget
body iframe#launcher {
  z-index: 999998!important;
}
body.zendeskHide div div[role="presentation"] > div {
  bottom: 235px !important;
}
body.zendeskHide div div[role="presentation"] iframe {
  bottom: 160px !important;
}
#root ~ div > div > iframe{
  display: none;
}
#root ~ div > iframe{
  display: none;
}
#root[data-url*="/support"] ~ div > div > iframe,
#root[data-url*="/contact"] ~ div > div > iframe,
#root[data-url*="/help"] ~ div > div > iframe {
  display: inline !important;
}
#root[data-url*="/support"] ~ div > iframe,
#root[data-url*="/contact"] ~ div > iframe,
#root[data-url*="/help"] ~ div > iframe {
  display: inline !important;
}

.home-menu a, .home-menu a+span {
  @include media-md {
    padding: 15px 10px;
  }
}

