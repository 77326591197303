.account-info {
  @mixin indent-between-items {
    padding-top: 25px;
  }
  @mixin accent-font {
    font-size: 18px;
    font-weight: 700;
    color: $green-1;
  }
  &__wrapper {
    width: 100%;
  }
  &__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: 100%;

    &__links {
      display: flex;
      align-items: center;
      margin-right: 40px;
    }
    &__balance {
      position: relative;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      justify-content: center;
      height: 100%;
      padding-right: 20px;
      padding-left: 20px;
      border-right: 2px solid #f5f5f5;
      border-left: 2px solid #f5f5f5;
    }
    &__profile {
      display: flex;
      justify-content: center;
      margin-left: 100px;
    }
  }
  &__profile {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &__item {
      width: 100%;
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      color: $gray-6;

      &:not(:first-child) {
        padding-top: 5px;
      }
    }
  }
  &__loyalty {
    min-height: 50px;
  }
  .settings-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    margin: initial;
  }
  .settings-menu__item-override {
    @include indent-between-items;

    a {
      @include accent-font;
      text-align: left;
      text-transform: none;
      text-decoration: initial;
    }
  }
  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    text-align: right;
    text-decoration: none;
    text-transform: uppercase;
    transition: $main-transition-time;
  }
  .balance {
    &__user,
    &__logout {
      display: none;
    }
    &__title {
      display: flex;
      flex-direction: column;
      width: initial;
      font-size: 14px;
      color: $gray-6;
      a {
        text-decoration: none;
        color: inherit;
      }
      span {
        white-space: nowrap;
      }
    }
    &__value {
      @include accent-font;
      min-width: initial;
      margin-left: 30px;
      text-align: right;
      white-space: nowrap;
      a {
        @include accent-font;
      }
    }
    &__row {
      justify-content: space-between;
      margin-bottom: initial;
      @include indent-between-items;
    }
  }
  &__logout {
    min-height: 40px;
    border: 2px solid #898989;
    font-weight: 400;
    color: color(text, dark);
    background-color: $gray-4;

    &::after {
      @include svg-icon(custom);
      content: '\0051';
      color: $gray-1;
    }
    span {
      justify-content: left;
    }
  }
}
