/*----------------*\
       Fonts
\*----------------*/

$font-fallback:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
    // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
    // Windows
  "Segoe UI",
    // Android
  "Montserrat",
    // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-primary: 'Montserrat', $font-fallback;
$font-weight-base: 400;
$font-weight-alt: 700;

$field-font__h1: 40px; // Hero banner , Montserrat
$field-font__h1--mobile: 26px; // Banner and title hero, game title , Montserrat Bold

$field-font__h2: 26px; // Banner text , Montserrat
$field-font__h2--mobile: 18px; // Paragraph title  ,  Montserrat Bold

$field-font__h3: 24px; // Paragraph title , Montserrat
$field-font__h3--mobile: 18px; // Text fields in forms and smaller text titles, button titles , Montserrat Bold, Navigation links

$field-font__h4: 18px; // Text fields in forms and paragraph titles, button titles, navigation links, game title , Montserrat
$field-font__h4--mobile: 14px;

$field-font__h5: 16px; // Footer, and smallest text , Montserrat
$field-font__h5--mobile: 12px; // Text fields in forms and smaller text titles, Montserrat Bold, Paragraph text, Montserrat Regular

$field-font__h6: 14px; // Search text , Montserrat
$field-font__h6--mobile: $field-font__h5--mobile;

@mixin field-font__h1 {
  font-size: $field-font__h1;
  font-weight: 700;
  font-family: $font-primary;

  @include media-mobile {
    font-size: $field-font__h1--mobile;
  }
}
@mixin field-font__h2 {
  font-size: $field-font__h2;
  font-weight: 700;
  font-family: $font-primary;

  @include media-mobile {
    font-size: $field-font__h2--mobile;
  }
}
@mixin field-font__h3 {
  font-size: $field-font__h3;
  font-weight: 700;
  font-family: $font-primary;

  @include media-mobile {
    font-size: $field-font__h3--mobile;
  }
}
@mixin field-font__h4 {
  font-family: Montserrat;
  font-size: $field-font__h4;
  font-weight: 500;
  font-family: $font-primary;

  @include media-lg {
    font-size: 16px;
  }
  @include media-mobile {
    font-size: $field-font__h4--mobile;
  }
}
@mixin field-font__h5 {
  font-size: $field-font__h5;
  font-weight: 500;
  font-family: $font-primary;

  @include media-mobile {
    font-size: $field-font__h5--mobile;
  }
}
@mixin field-font__h6 {
  font-size: $field-font__h6;
  font-weight: 400;
  font-family: $font-primary;

  @include media-mobile {
    font-size: $field-font__h6--mobile;
  }
}

/*----------------*\
       Colors
\*----------------*/

$black: #000000;
$black-1: #1f2222;
$black-2: #202222;
$black-3: #363636;
$black-4: #302f30;
$black-5: #1B1829;
$black-6: #1E1A29;

$white: #ffffff;

$orange-1: #fd8f00;
$orange-2: #f4b87b;
$orange-3: #ee9436;

$green-1: #368b14;
$green-2: #7bb364;
$green-3: #e0ffd3;
$green-4: #61ca1c;

$gray-1: #36393a; //+
$gray-2: #8a8a8a; //+
$gray-3: #e1e1e1; //+
$gray-4: #efefef; //+
$gray-5: #918F9A;
$gray-6: #4e5858;
$gray-7: #b7b7b7;
$gray-8: #ebebeb;
$gray-9: #fefefe;
$gray-10: #5c6668;
$gray-11: #C0C4C4;

$red-1: #d70909;

$violet: #2C2843;
$violet-accent: #5F3292;

$colors: (
  black: $black-1,
  white: $white,

  accent: (
    primary: $orange-1,
    secondary: $green-1
  ),
  text: (
    main: $gray-1,
    light: $gray-4,
    dark: $black-1
  ),

  primary: (
    main: $black-1,
    light: $gray-1,               // need to check
    dark: $gray-1,                // need to check
  ),
  secondary: (
    main: $gray-7,
    light: $white,                  // need to check
    dark: $gray-3                 // need to check
  ),

  background: (
    header: $black-5,
    header-menu: $gray-1,
    footer: $black-5,
    footer-menu: $violet,
    game-window: $black-1,
    body: $gray-4,
    modal: $white,
    post-login-menu: $black-5,
    post-login-menu-item-root: $black-5,
    post-login-content: $white,
    aside-menu: $black-5,
    aside-menu-footer: $black-5,
    aside-menu-item: $black-5,
    promotions-card: $black-5,
    static-menu: $white,           // need to configure
    static-menu-item: $black-5,
    static-content: $white,        // need to configure
    filter: $white,                 // need to configure
    game-name-filter-bg: $gray-11,
  ),
  modal: (
    title: $black-1,
    text: $black-1,
  ),
  footer: (
    text: $white,
    link: $white,
    link-active: $gray-7,
  ),
  content: (
    title: $black-1,
    background: $white
  ),
  label: (
    filled: $gray-1,
    unfilled: $gray-1,
    disabled: $gray-1
  ),
  // NEED TO CHECK

  link: (
    default: $black-1,
    hover: $black-1,
    active: $black-1
  ),
  info: #ffcc00,
  error: #ed3030,
  success: $green-1,
  warning: $orange-1,
  expired: #d7b354,
  turnover-progress: (
    text: $white,
    progress: $black-1,
    background: $white
  ),
  main-menu: (
    link: $black-1,
    link-active: $orange-1,
    background: $white,
    background-active: $white
  ),
  static-menu: (
    border: $white,
    background: $white
  ),
  loyalty-statuses: (
    bronze: #6398e6,
    silver: #f9813f,
    gold: #ffcd13,
    platinum: #339999,
  ),
  limit-amount: (
    progress-back: $gray-7,
    progress-path: $green-1,
    switch-default: $white,
    background: $white,
    color: $gray-7,
    date-background: $white
  ),
  promotions-card: (
    title: $black-1,
    text: #2e3233,
    link: #2e3233,
  ),
  table: (
    even-background: $white,
  ),

  field--primary: (
    text--normal: $gray-2,
    background--normal: $white,
    border--normal: $gray-2,

    text--filled: $gray-1,
    background--filled: $white,
    border--filled: $gray-2,

    text--success: $gray-1,
    background--success: $white,
    border--success: $green-1,

    text--error: $gray-2,
    background--error: $white,
    border--error: $red-1,

    text--disabled: $black-1,
    background--disabled: $gray-4,
    border--disabled: $gray-2,

    hint__text: $gray-1,
    hint__background: $gray-3,

    hint__text--error: $red-1,
    hint__background--error: $white
  ),
  field--dark: (
    text--normal: $gray-2,
    background--normal: $black-1,
    border--normal: $gray-2,

    text--filled: $gray-2,
    background--filled: $black-1,
    border--filled: $gray-2,

    text--success: $gray-2,
    background--success: $green-3,
    border--success: $green-1,

    text--error: $gray-2,
    background--error: $black-1,
    border--error: $orange-1,

    text--disabled: $gray-2,
    background--disabled: $gray-1,
    border--disabled: $gray-2,

    hint__text: $gray-1,
    hint__background: $gray-3,

    hint__text--error: $white,
    hint__background--error: $orange-1
  ),
  button--primary: (
    text: $white,
    background: $orange-1,
    background--hover: $orange-2
  ),
  button--secondary: (
    text: $white,
    background: $green-1,
    background--hover: $green-2
  ),
  button--disabled: (
    text: $white,
    background: $gray-3,
  ),
  checkbox--primary: (
    text: $white,
    background: $gray-3,
  ),

  link--default: (
    text: $gray-1,
    text--hover: $gray-3,
  ),
  link--light: (
    text: $white,
    text--hover: $gray-4,
  ),
  link--dark: (
    text: $gray-2,
    text--hover: $gray-3,
  ),
  link--primary: (
    text: $orange-1,
    text--hover: $orange-2,
  ),
  link--secondary: (
    text: $green-1,
    text--hover: $green-2,
  )
);

$preloading-width: 120px;
$preloading-height: 4px;
$preloading-time: 5s;

// retrieve color from map. Example: 'color: color(primary, main);', 'background: color(background)'
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    @return map-get(map-get($colors, $color-name), $color-variant);
  }
  @else {
    @return map-get($colors, $color-name);
  }
}

/*----------------*\
       Mixins
\*----------------*/

@mixin svg-icon($name: 'Glyphter') {
  display: inline-block;
  @if $name == 'custom' {
    font-family: 'Glyphter-custom'!important;
  } @else {
    font-family: 'Glyphter'!important;
  }
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$main-transition-time: .25s;

$size-xl: 1920px;
$size-lg: 1440px;
$size-ml: 1280px;
$size-md: 1024px;
$size-sm: 767px;
$size-xs: 480px;

$size-mobile: 560px; // check table from https://www.mydevice.io/

$home-menu-mobile-height: 78px;
$header-height-mobile: 63px;

@mixin media($max, $min: null) {
  @if ($min == null) {
    @media (max-width: $max) {
      @content;
    }
  } @else if($max == null) {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @media (max-width: $max) and (min-width: $min) {
      @content;
    }
  }
}
@mixin media-xl {
  @media (max-width: $size-xl) {
    @content;
  }
}
@mixin media-lg {
  @media (max-width: $size-lg) {
    @content;
  }
}
@mixin media-ml {
  @media (max-width: $size-ml) {
    @content;
  }
}
@mixin media-md {
  @media (max-width: $size-md) {
    @content;
  }
}
@mixin media-sm-landscape {
  @media (max-width: $size-sm) and (orientation: landscape) {
    @content;
  }
}
@mixin media-sm {
  @media (max-width: $size-sm) {
    @content;
  }
}
@mixin media-xs {
  @media (max-width: $size-xs) {
    @content;
  }
}
@mixin media-mobile {
  @media (max-width: $size-mobile) {
    @content;
  }
}

// Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin customVh($vh: 100) {
  height: calc(var(--vh, 1vh) * #{$vh});
  will-change: height;
}

// Browsers check hacks
// Microsoft Edge
@mixin isMicrosoftEdge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Mozilla Firefox
@mixin isMozillaFirefox {
  @-moz-document url-prefix() {
    @content;
  }
}

// Links
@mixin link--root {
  @include field-font__h4;
  font-weight: 700;
  text-decoration: none;
  transition: $main-transition-time;
  z-index: 1000;

  &:hover {
    cursor: pointer;
  }
}
@mixin link--color($type) {
  color: color(link--#{$type}, text);
};
@mixin link--hover($type) {
  &:hover, &:active {
    @if $type == 'light' {
      opacity: .5;
    } @else {
      color: color(link--#{$type}, text--hover);
    }
  }
}
@mixin link--border {
  border-bottom: 1px solid;

  &:hover, &:active {
    border-color: transparent;
  }
}
@mixin link--type($type) {
  display: inline-flex;
  align-items: center;

  &:before {
    @include svg-icon(custom);
    @include field-font__h4;
    margin-right: 5px;

    @if $type == 'back' {
      content: '\0041';
      transform: rotate(180deg);
    }
    @if $type == 'user' {
      content: '\0065';
    }
    @if $type == 'forgot' {
      content: '\0046';
    }
    @if $type == 'success' {
      content: '\0044';
    }
  }
}

@mixin linkGenerator($type) {
  &--#{$type} {
    @include link--color($type);
  }
  &--#{$type}#{&}--hover {
    @include link--hover($type);
  }
  &--#{$type}#{&}--border {
    @include link--border;
  }
}
@mixin linkGeneratorBefore($type) {
  &--#{$type} {
    @include link--type($type)
  }
}

@mixin flexGap ($row: var(--md-resize), $column: $row, $columns: 1) {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{$column});
  max-width: initial;
  max-height: initial;
  margin: calc(-1 * #{$row}) calc(-1 * #{$column} / 2) 0;

  & > div:not(.notification-root), & > label {
    padding: #{$row} calc(#{$column} / 2) 0;

    @if ($columns == auto) {
      flex: 0 1 auto;
    } @else {
      @if ($columns == 1) {
        flex : 1 1 100%;
      }
      @if ($columns > 1) {
        flex: 0 1 calc(100% / #{$columns});
      }
    }
    @include media-mobile {
      flex-grow : 1;
    }
  }
  & > div.notification-root, & > button, & > a[role="button"] {
    margin: #{$row} calc(#{$column} / 2) 0;

    @if ($columns == auto) {
      flex : 0 1 auto;
    } @else {
      @if ($columns == 1) {
        flex : 1 1 100%;
      }
      @if ($columns > 1) {
        flex: 0 1 calc(100% / #{$columns} - #{$column});
      }
    }
    @include media-mobile {
      flex : 1 0 auto;
    }
  }
}


/*----------------*\
   CSS variables
\*----------------*/

:root {
  --xs: 5px;
  --sm: 10px;
  --md: 20px;
  --lg: 40px;
  --xl: 60px;

  --xs-resize: var(--xs);
  --sm-resize: var(--sm);
  --md-resize: var(--md);
  --lg-resize: var(--lg);
  --xl-resize: var(--xl);
}
@media (max-width: 1024px) {
  :root {
    --xs-resize: 5px;
    --sm-resize: 10px;
    --md-resize: 20px;
    --lg-resize: 35px;
    --xl-resize: 60px;
  }
}
@media (max-width: 560px) {
  :root {
    --xs-resize: 5px;
    --sm-resize: 7px;
    --md-resize: 15px;
    --lg-resize: 25px;
    --xl-resize: 30px;
  }
}
