.limits-confirmation-modal {
  // Type
  &__type {
    margin: 30px 0;

    @include media-sm {
      margin: 20px 0;
    }

    &-title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: $font-weight-base;

      @include media-sm {
        margin-bottom: 10px;
      }
    }
  }

  // Period
  &__period {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px 30px;
    background-color: #eee;

    @include media-sm {
      padding: 15px 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #c7c7c7;
    }

    &-title {
      font-weight: $font-weight-alt;
    }

    &-arrow {
      margin: 0 10px;
    }
  }
}
