.static-page-promotions {
  padding: 0 var(--lg-resize);
  @media screen and (max-width: 1440px) {
    padding-right: 0;
  }
  @media screen and (max-width: 1284px){
    padding-left: 0;
  }
}
.promotions-root {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 55px;
  background: $gray-4;

  @media (max-width: 749px) {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}
// post-login
.promotions {
  &__card-list {
    @media (max-width: 749px) {
      margin-top: -30px;
    }
    @include media-mobile {
      margin-top: -20px;
    }
    .promotion-card {
      border-radius: 15px;
      &__img {
        max-height: 320px;
        p {
          left: 20px;
          top: 20px;
          font-size: 60px;

          @media (max-width: 1639px) {
            width: initial;
            top: 25px;
            font-size: 50px;
          }

          @include media-lg {
            width: 250px;
            top: 35px;
          }

          @media (max-width: 1163px) {
            width: initial;
            font-size: 50px;
          }

          @media (max-width: 1075px) {
            font-size: 44px;
          }

          @include media-md {
            top: 20px;
            font-size: 60px;
            width: 250px;
          }

          @media (max-width: 900px) {
            top: 10px;
            width: 250px;
            font-size: 45px;
          }

          @media (max-width: 790px) {
            width: initial;
          }

          @media (max-width: 749px) {
            top: 90px;
            font-size: 60px;
          }

          @media (max-width: 598px) {
            top: 70px;
            font-size: 55px;
          }

          @media (max-width: 489px) {
            top: 50px;
          }

          @media (max-width: 458px) {
            font-size: 34px;
          }

          @media (max-width: 375px) {
            top: 40px;
            font-size: 28px;
          }
        }
      }
      &__bottom {
        @media (max-width: 749px) {
          flex-direction: column;
        }
        @include media-mobile {
          padding: 10px;
          bottom: 2%;
        }
      }
      &__buttons {
        @media (max-width: 749px) {
          margin-top: 20px;
          padding-left: 0;
          width: 100%;
          .button-root {
            flex: 1 1 auto;
          }
        }
        @include media-mobile {
          margin-top: 10px;
        }
      }
      &__bottom-text {
        @media (max-width: 749px) {
          width: 100%;
        }
      }
    }
  }
}

// promo-single page
.post-login-content-wrap--promotions-opened {
  .promotion-card {
    &__img {
      img {
        border-radius: 15px;
      }
      p {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &__content {
      padding: 45px 60px;
      @include media-mobile {
        padding: 30px;
      }
      .promo-content__description {
        margin-top: 15px;
        margin-bottom: 40px;
        font-weight: 400;
      }
      .promo-content__list-item {
        font-size: 16px;
      }
    }
    &__footer-details {
      padding: 0px 60px 30px 60px;
      @include media-mobile {
        padding: 0 30px 30px 30px;
      }
    }
  }
}

// pre-login
.promotions-page-static {
  .promotion-card {
    border-radius: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.promotion-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
    @include media-mobile {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 749px) {
    margin-bottom: 20px;
  }
  &__img {
    position: relative;
    img {
      max-width: 100%;
      display: block;
    }
    p {
      width: 250px;
      color: $white;
      text-transform: uppercase;
      font-weight: 800;

      position: absolute;
      left: 20px;
      top: 20px;

      font-size: 60px;
      line-height: 1;
      max-width: 45%;
      text-align: left;

      @media (max-width: 1640px) {
        width: initial;
        font-size: 44px;
      }

      @media (max-width: 1440px) {
        width: 250px;
        font-size: 60px;
      }

      @media (max-width: $size-ml) {
        font-size: 100px;
        top: 30px;
      }

      @media (max-width: 1120px) {
        width: 250px;
        font-size: 60px;
        top: 20px;
      }

      @media (max-width: 920px) {
        width: 250px;
        font-size: 45px;
      }

      @media (max-width: 749px) {
        top: 60px
      }

      @media (max-width: 520px) {
        font-size: 45px;
        top: 15%;
      }

      @media (max-width: 430px) {
        font-size: 35px;
      }

      &[data-color="blue"] {
        color: transparent;
        background: linear-gradient(to right,#28FFBE, #0085FF);
        background-clip: text;
        -webkit-background-clip: text;
      }
      &[data-color="yellow"] {
        color: transparent;
        background: linear-gradient(to right, #FFFFFF, #FFB528);
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }
  &__title {
    font-size: 20px;
    display: none;
  }
  &__static-description {
    p {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      white-space: break-spaces;

      @media (max-width: 749px) {
        font-size: 18px;
      }
      @media (max-width: $size-xs) {
        font-size: 14px;
      }
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: rgba(27, 24, 41, 0.8);
    padding: 29px 20px;

    @media (max-width: 749px) {
      bottom: 5%;
      padding: 15px;
    }
  }
  &__bottom-text {
    flex: 1 1 auto;
    color: $white;
    text-align: left;

    h3, p {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    @media (max-width: $size-xs) {
      h3, p {
        font-size: 14px;
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    padding-left: 10px;
    .button-root {
      text-transform: uppercase;
      margin-right: 10px;
      white-space: nowrap;
      .markdown-root {
        p {
          white-space: nowrap;
          @media (max-width: $size-xs) {
            font-size: 14px;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: $size-xs) {
        font-size: 14px;
        width: 110px;
        min-width: 110px;
      }
    }
  }
  &__conditions, &__turnover {
    display: none;
  }
}
