.post-login-content-wrap--limits {
  display: flex;
  flex-direction: column;

  .ndtInfo {
    display: flex;
    background: $gray-3;
    border-radius: 8px;
    align-items: center;

    .icon-info {
      font-size: 53px;
      background: $orange-1;
      color: $white;
      padding: 13px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      line-height: 1;
      height: 79px;
    }

    .markdown-root {
      padding: 0 18px;

      a {
        display: block;
      }
    }
  }

  &-responsible-gambling,
  &-self-exclusion {
    background: color(background, post-login-content);
  }
  .group-root {
    @include media-sm {
      text-align: center;
    }
  }
  .buttons-container {
    justify-content: flex-end;
  }
  &-time {
    .field-primary {
      .input-before {
        display: inline-flex;
        padding-right: 5px;
      }
      &.field-empty .input-before {
        font-style: italic;
        font-weight: normal;
      }
      input {
        &:focus::-webkit-input-placeholder {
          color: transparent;
        }
      }
    }
  }
}
.post-login-content-wrap--limits-responsible-gambling .limits__title {
  display: none;
}
.limits {
  &__accordion {
    gap: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0;

    h6 {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Clash Display', sans-serif;
      text-transform: uppercase;
      margin: 0;
    }

    .accordion {
      &-panel {
        border-radius: 0;
        border-top: 1px solid $gray-2;
        box-shadow: none;
        background: $gray-4;
        min-height: 62px;

        &:last-child {
          border-bottom: 1px solid $gray-2;
        }

        &--expanded {
          margin: 0;
          background: none;

          .accordion-summary > div:after {
            transform: rotate(-90deg);
          }
        }
      }
      &-summary {
        padding: 0 11px;


        &--content-expanded {
          min-height: 48px;
        }

        &--content {
          display: flex;
          margin: 12px 0;
          flex-direction: column;
          flex-grow: inherit;

          > div:first-child {
            text-transform: capitalize;
          }

          &--active {
            padding-left: 20px;

            &:before {
              content: '\0025';
              font-family: 'Glyphter-custom';
              position: absolute;
              left: 11px;
              top: 13px;
            }

            > div:last-child {
              color: $green-1;
            }
          }

          &--inactive {
            strong {
              color: #EE756E;
            }
          }

          .markdown-root {
            p {
              margin: 0;
              text-align: right;
            }
            strong {
              display: block;
              font-weight: normal;
              text-transform: capitalize;
            }
          }
        }

        > div {
          &:after {
            content: '\0041';
            font-family: 'Glyphter-custom';
            position: absolute;
            right: 11px;
            top: calc(50% - 8px);
            transform: rotate(90deg);
            color: $orange-1;
          }
        }
      }
      &-details {
        padding: 0 0 48px 0;
        flex-direction: column;

        .limit-amount {
          width: 100%;
          padding: 0 24px 20px 24px;

          &--deposit {
            .available-limit-amount {
              &--deposit {
                height: 100px;
                flex: 0 0 134px;

                svg {
                  top: 0;
                  width: 134px;
                }
              }

              &__content {
                margin: 0;
                align-self: end;
                height: 40px;

                .available-percents {
                   &--title {
                     margin: 0;
                     padding: 0;
                     font-size: 19px;
                   }
                }
              }
            }
          }
        }

        .confirm-button {
          width: 282px;
          max-width: none;
        }

        .buttons-container--deposit {

          @include media-sm {
            padding: 0 24px 0 24px;
            max-width: 330px;
            margin: 0 auto;
          }

          button {
            width: 282px;

            @include media-sm {
              margin: 0;
            }
          }
        }

        .limit-form__buttons {
          padding: 0 24px 24px 24px;

          button {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
  &__title {
    margin: 0;
    padding: 40px 0 10px;
    font-size: 26px;
  }

  .field-primary {
    .input-root,
    .field-helper {
      background-color: initial;
    }

    .field-helper, .extendedHint {
      padding-right: 0;
      padding-left: 0;
      font-size: 14px;

      p {
        font-size: 14px;
      }
    }

    .extendedHint {
      margin-top: 5px;
      color: rgb(54, 57, 58);
    }
  }
}
.limit-form {
  &__deposit-item {
    border: 1px solid $gray-1;
    max-width: none;
    flex-basis: unset;
    flex-grow: 1;
    padding: 15px 25px;
    display: flex;
    justify-content: center;
  }

  .deposit-form &,
  .cumulative-deposit-limits-modal & {
    margin-bottom: 0;
  }
  &__net-deposit {
    height: 100%;
    margin-bottom: 0;

    .limit-form__item {
      padding-top: 0;
    }
  }
  .deposit-form &,
  .cumulative-deposit-limits-modal &,
  .game-decorator__error-content--limits & {
    .button-secondary {
      margin-top: 20px;
    }
  }
  &--space {
    margin-bottom: 0;
  }
  &__item {
    &--amount {
      padding-right: 40px;

      @include media-sm {
        margin: 0;
        padding: 0;
      }
    }
    &--period {
      padding-left: 40px;

      @include media-sm {
        padding-left: 0;
      }
    }
    &--date {
      align-self: flex-start;
    }
  }
  &__field legend {
    margin-bottom: 10px;
  }
  &__radiogroup {
    @include media-xs {
      flex-direction: column;

      label {
        margin-bottom: 10px;

        + label {
          margin-left: 0;
        }
      }
    }
  }
  & &__radio {
    white-space: normal;

    @include media-xs {
      margin-bottom: 8px;
    }
    .formControlLabel-label {
      text-transform: uppercase;
    }
  }
  &__checkbox-wrapper {
    align-self: flex-end;
    margin-left: auto;
  }
  &__checkbox {
    margin-top: 15px;
    padding-left: 40px;

    @include media-sm {
      padding-left: 0;
    }
  }
  &__deposit-spacer {
    display: flex;
    gap: 30px;
  }

  .buttons-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &--right {
      justify-content: right;
    }
  }

  &__spacer {
    @include flexGap($columns: 2);

    &--deposit {
      button {
        margin: 0 auto;
        text-transform: uppercase;
        font-weight: 600;
        flex: none;
      }
    }

    @include media-sm {
      @include flexGap($columns: 1)
    }
  }
  &__title {
    &--registration {
      margin-top: 10px;
      font-size: $field-font__h4;
      font-weight: bold;
      @media (max-width: $size-mobile) {
        font-size: $field-font__h5;
      }
    }
    &--large {
      @include field-font__h3;
      font-weight: 700;
    }
  }
}
.confirm-button__cancel {
  @include media-mobile{
    margin-bottom: 15px;
  }
}
