.input-date-select {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin: -10px 0 0 -24px;

  & > :nth-child(n) {
    flex: 1;
    margin: 10px 0 0 24px;

    &::after {
      height: 0;
    }
  }
}
