.post-login-content-wrap--delete-account {
  .button-secondary {
    margin: 30px 0 15px;
  }
}

.account-deletion-form {
  &__password-button {
    position: absolute;
    visibility: hidden;
  }
}
