.pending-withdrawal-modal {
  &.modal-root .modal-paper {
    max-width: 661px;
  }
  .modal-contentRoot {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal-titleRoot {
    text-align: center;
  }
  .buttons {
    padding-top: 65px;

    @include media-sm {
      padding-top: 35px;
    }
    .button-root {
      width: 100%;
    }
  }
  .pending-withdrawal {
    padding: 14px 0;

    @include media-xs {
      padding: 8px 0;
    }
    &__not-found {
      text-align: center;
    }
    &__img {
      height: 27px;

      @include media-xs {
        height: 12px;
      }
    }
    &__btn {
      padding: 16px 32px;
      font-size: 18px;
      font-weight: $font-weight-base;

      @include media-sm {
        padding: 5px 10px;
        font-size: 14px;
      }
    }
    &__info {
      margin: 0 16px 0 16px;
      font-size: 18px;

      @include media-sm {
        margin: 0 16px 0 9px;
        font-size: 14px;
      }
    }
  }
}
