.game-limits {
  position: absolute;
  bottom: 0;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 65px;
  padding: var(--sm-resize) var(--md-resize);
  color: $white;
  background-color: $orange-3;
  z-index: 11;

  @include media-mobile {
    flex-wrap: wrap;
    position: initial;
  }

  &__item {
    @include field-font__h4;

    display: flex;
    flex-wrap: wrap;
    padding: var(--sm-resize) var(--md-resize);
    white-space: nowrap;
    flex-shrink: 1;

    &__subtitle {
      padding-right: 5px;
      font-weight: 700;
    }
  }

  .linearProgress-root {
    padding: 10px 0;
  }
}
