.registration-page {
  flex: 1 ;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 30px 20px 60px;

  @include media-mobile {
    padding: 0;
  }
  &__container-wrap {
    width: 100%;
    max-width: 600px;
    background: $white;
  }
  .top-registration-form {
    &--step1,
    &--step2,
    &--step3 {
      padding: 40px 50px;
      @include media-mobile {
        padding: 10px 10px 20px;
      }
    }
  }
  .cddn-registration-form,
  .top-registration-form {
    &--step4,
    &--step5,
    &--step6,
    &--step8,
    &--step9,
    &--step10, {
      padding: 40px 50px;
      @include media-mobile {
        padding: 10px 10px 20px;
      }
    }
  }
}
