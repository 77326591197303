.input-code {
  display: grid;
  grid-template-columns: repeat(4, minmax(50px, 75px));
  grid-gap: var(--sm);
  justify-content: center;

  .input-root {
    height: 50px;
    padding: 0;
    border-radius: 12px;
  }

  .input-input {
    padding: 0;
    text-align: center;
  }

  &__input {
    &--active {
      transform: translateY(-3px);

      -webkit-box-shadow: 0px 3px 1px 0px $gray-4;
      -moz-box-shadow: 0px 3px 1px 0px $gray-4;
      box-shadow: 0px 3px 1px 0px $gray-4;
    }
  }
}
