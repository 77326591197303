.forgot-password {
  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
    background: var(--static-page-background);
  }
  .button-primary {
    margin-top: 20px;
  }
  &__title {
    text-align: center;
  }
  &__submit {
    text-align: center;
  }
  &__receive-email {
    padding-top: 30px;
    text-align: center;
  }
  &__sent-link {
    padding: 0;
    text-align: center;
  }
}
.forgot {
  h4 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: 700;

    @include media-md {
      color: $white;
    }
  }
  &__popover {
    transform: translateY(10px) !important;
    padding: 38px;
    width: 100%;
    max-width: 380px;
    border-radius: 4px;
    overflow: initial !important;

    &::before {
      content: '';
      position: absolute;
      top: -14px;
      right: calc(50% - 8px);
      border: 8px solid transparent;
      border-bottom: 8px solid $white;
    }
    .gridItem-root {
      max-width: 100%;
      flex-basis: 100%;
    }
    .forgot-password__receive-email {
      display: none;
    }
  }
}
