.bet-limit-form {
  padding: 10px 30px;

  &__title {
    text-align: center;
  }
  &__amount {
    display: flex;
    justify-content: space-between;
    max-width: 350px;
    margin: 10px auto;
  }
  &__amount-value {
    font-weight: $font-weight-base;
  }
}
