.tournament-card {
  overflow: hidden;
  margin-bottom: 40px;

  &__img {
    position: relative;
    margin-bottom: 15px;

    img {
      width: 100%;
      border-radius: 15px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
    text-transform: uppercase;
  }

  &__description {
    position: absolute;
    top: 0;
    color: white;
    max-width: 55%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    padding: 20px 0 20px 40px;

    h2 {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 40px;
      color: transparent;
      background: linear-gradient(91.23deg, #FFFFFF -55.36%, #FFB528 98.38%);
      background-clip: text;
      -webkit-background-clip: text;
    }

    .short-info {
      &__item {
        display: flex;
        align-items: baseline;
        gap: 10px;

        p {
          margin-bottom: 5px;
          text-transform: uppercase;
          opacity: 0.9;
        }

        span {
          font-weight: bold;
        }
      }
      @media (max-width: 680px) {
        p, span {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 1080px) {
      padding: 10px 0 20px 40px;

      h2 {
        font-size: 34px;
      }
    }

    @media (max-width: 680px) {
      h2 {
        font-size: 26px;
      }
    }
  }

  &__description--mobile-container {
    position: absolute;
    top: 5%;
    right: 0;
    left: 0;

    .tournament-card__description--mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;

      background: rgba(27, 24, 41, 0.8);
      padding: 29px 20px;

      @media (max-width: 749px) {
        bottom: 15%;
        padding: 20px;
      }

      @include media-xs {
        padding: 10px;
        gap: 5px;
      }

      .mobile-text {
        flex: 1 1 auto;
        color: $white;
        text-align: left;

        h3, p {
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
        }

        @media (max-width: $size-xs) {
          h3, p {
            font-size: 14px;
          }
        }
      }

      .mobile-btn {
        a {
          padding: 16px 5px;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 600;
          border-radius: 5px;

          @include media-xs {
            min-width: 110px;
            font-size: 14px;
            padding: 10px;
          }
        }
      }

      .short-info {
        &__item {
          display: flex;
          align-items: baseline;
          gap: 10px;

          p {
            margin-bottom: 5px;
            text-transform: uppercase;
            opacity: 0.9;
            font-size: 18px;
            font-weight: normal;
          }

          span {
            font-weight: bold;
            font-size: 18px;
          }

          @include media-xs {
            gap: 5px;

            p, span {
              font-size: 14px;
            }
          }
        }
      }
    }

    @include media-xs {
      h2 {
        width: 65%;
        padding: 0 10px;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 24px;
        color: transparent;
        background: linear-gradient(91.23deg, #FFFFFF -55.36%, #FFB528 98.38%);
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }
}

.actions-container {
  a {
    padding: 10px 24px;
    text-transform: uppercase;
  }
}
