.authentication {
  &__methods {
    margin-bottom: 20px;
    .radioGroup-root {
      label {
        min-width: 20%;
      }
    }
  }
  &__success {
    text-align: center;
  }
}
