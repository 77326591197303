.limit-amount {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 420px;

  &--session {
    .confirm-button {
      display: block;
      margin: 20px auto 0 auto;
    }
  }

  &--deposit {
    max-width: 330px;
    margin: 0 auto;

    .extendedHint {
      margin-top: 5px;
      color: rgb(54, 57, 58);
      font-size: 14px;
    }

    .available-limit-amount--deposit {
      flex: 0 0 134px;
    }

    .limit-amount__amount {
      display: flex;
      flex-direction: column;
    }

    span.available-percents--title {
      border: none!important;
    }

    .limit-amount__circular-title {
      align-items: normal;
      margin-bottom: 20px;
      gap: 20px;
      font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Montserrat", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

      .icon-info {
        width: 76px;
        height: 76px;
        color: $orange-1;
        font-size: 76px;
        margin: 0 auto;
      }

      .markdown-root {
        text-align: center;

        p {
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
        }

        strong {
          display: block;
        }
      }

      .markdown-root + .markdown-root {
        margin-top: 17px;
      }

    }

    .confirm-button {
      margin: 10px auto 10px auto;
      font-size: 18px;
      font-weight: 600;
      max-width: 213px;
      padding: 0;

      @include media-sm {
        margin: 20px auto 0 auto;
      }
    }
  }


  &__amount-input.limit-amount__other--children--empty {
    margin-top: var(--sm-resize);
    .input-before {
      display: block !important;
    }
    &.limit-amount__other {
      position: absolute;
      left: 1px;
      width: calc(100% - 42px);
      top: 101px;
      background-color: $white;
      .input-root {
        border: none;
        .input-before {
          margin-right: 0;
          @media (min-width: 1620px) {
            line-height: 38px;
          }
          @media (max-width: $size-mobile) {
            line-height: 33px;
          }
        }
      }
      @media (max-width: 1023px) {
        top: 92px
      }
      @media (max-width: $size-mobile) {
        top: 96px;
      }
    }
  }
  .input-values {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-row-gap: var(--md-resize);
    grid-column-gap: var(--xs-resize);
  }

  .field-helper {
    min-height: initial !important;
    font-style: initial !important;
  }
  &__title {
    margin-bottom: 10px;
    font-size: 18px;
  }
  &__renew,
  &__amount {
    padding: 0;

    .field-primary {
      &--session {
        input {
          text-align: left;
        }
        .input-root {
          padding-right: 10px;
        }
      }
      &--deposit {
        .limit-amount__circular-title {
          justify-content: normal;
          align-items: center;
          flex-direction: column;
          font-size: 18px;
          font-weight: 700;
        }
        .field-label {
          display: flex;
          flex-direction: column-reverse;
        }

        .input-before {
          font-size: 14px;
        }
        .input-root, .field-helper {
          max-width: 200px;
          margin: 0 auto;
          font-size: 14px;

          input, p {
            font-size: 14px;
          }

          input::placeholder {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__progress {
    margin-top: 5px;

    & + div {
      margin-top: 10px;
      color: $gray-2;

      .button-root {
        min-width: initial;
        max-width: initial;
        width: 100%;
      }
    }
    svg {
      display: none;
    }
  }
  .input-before {
    margin-right: 3px;
  }
  &__revert-button,
  &__remove-button {
    border-radius: 6px;
    min-height: 26px;
    min-width: 26px;
    padding: 0;

    &:not(.button-loading) .button-label:before {
      @include svg-icon;
    }
  }
  &__revert-button .button-label:before {
    content: '\007d';
  }
  &__remove-button .button-label:before {
    content: '|';
  }
  label h4 {
    font-weight: 700;
  }
  .field-inputValues {
    .input-root {
      display: none;
    }
  }
  &__registration-form {
    .field-primary.field-selectWithPlaceholder legend {
      margin-bottom: var(--xl);
    }
    .limit-amount__amount-input {
      margin-top: var(--lg-resize);
    }
  }
  &__select-label {
    position: absolute;
    bottom: 45px;
  }
  &__circular-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .available-limit-amount {
    flex: 0 0 90px;
    height: 90px;
    @media (min-width: $size-md) {
      flex: 0 0 100px;
      height: 100px;
    }
    svg {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &__content {
      text-transform: none;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      position: absolute;
      top: 23px;
      height: 100%;
    }
    &__title {
      margin-top: 15px;
      white-space: nowrap;
      &--empty {
        margin-top: 34px;
      }
    }
    &__available {
      font-weight: $font-weight-alt;
      font-size: $field-font__h4;

      .available-percents {
        color: #fff;
        background: $green-1;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 700;
        padding: 3px;
        display: inline-block;
        margin-bottom: 10px;

        &--title {
          margin-bottom: 20px;
          display: block;
          border-bottom: 1px solid $gray-2;
          padding-bottom: 20px;
        }
      }
    }
    .circularProgress-back {
      stroke: color(limit-amount, progress-back);
    }
    .circularProgress-path {
      stroke: color(limit-amount, progress-path);
    }

    &--deposit {
      height: auto;
      display: flex;
      width: 100%;

      svg {
        width: 112px;
        margin: 0 auto;
      }

      .available-limit-amount {
        &__content {
          margin-top: 40px;
          position: static;
          flex-grow: 1;
        }

        &__available {
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          width: 100%;

          .markdown-root {
            p {
              font-size: 14px;
            }
          }

          &:not(:has(.available-percents)) {
            display: none;
          }
        }
      }

      .markdown-root {
        font-weight: 400;

        strong {
          display: block;
        }
      }
    }
  }
  &--maxBalance {
    max-width: initial;

    .available-limit-amount__available {
      font-size: 16px;
    }

    .available-limit-amount {
      flex: 0 0 125px;
    }

    .limit-amount__amount-input:not(.limit-amount__other--children--empty) {
      &.limit-amount__other {
        position: absolute;
        left: 11px;
        width: calc(100% - 62px);
        top: 121px;
        @media (max-width: 1023px) {
          top: 111px
        }
        @media (max-width: $size-mobile) {
          top: 110px;
        }
      }
    }
  }

  &__contact-link {
    margin-left: 4px;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
#root[data-url='/my/limits/auto-withdrawal'] {
  .limit-amount .available-limit-amount__title {
    text-transform: lowercase;
  }
}
