.game-list--all {
  .game-list__title {
    display: none;
  }
  .game-thumbnail {
    @media (min-width: 768px) {
      width: calc(33.33333% - 12px);
    }
    @media (min-width: 1025px) {
      width: calc(25% - 12px);
    }
  }
}
.games-filter {
  margin-left: 20px;
  padding: 30px 20px 25px;
  text-align: left;
  background-color: color(background, filter);

  &__show {
    position: absolute;
    right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 5px;
  }
  @include media-md {
    top: 49px;
  }
  @include media-sm {
    max-height: 100px;
    overflow: hidden;
    margin-left: 0;
    margin-bottom: 20px;

    &--show {
      max-height: none;
    }
  }
  &__group + &__group {
    margin-top: 15px;
  }
  &__group-title {
    margin-bottom: 8px;
    padding-bottom: 9px;
    border-bottom: 4px solid color(primary, main);
    font-size: 12px;
    font-weight: $font-weight-base;
    color: $black-3;
    text-transform: uppercase;
  }
  .radio-list {
    .formControlLabel-root {
      display: inline-flex;
      margin-right: 10px;
      float: none;
      clear: none;
    }
    .formControlLabel-label {
      padding-left: 5px;
    }
  }
  .range-range {
    background: color(primary, main);
    background-clip: content-box;
  }
  &__name {
    width: 100%;
  }
  &__show {
    font-size: 12px;
    color: color(link, default);
    white-space: nowrap;
    float: right;
    text-transform: none;
    cursor: pointer;

    @media (min-width: $size-sm) {
      display: none;
    }
  }
}
.games-filter__group {
  .field-helper {
    display: none;
  }
  .field-checkbox {
    margin: 0;
    max-width: 100%;
  }
  .field-checkbox .checkbox-default + span,
  .field-checkbox .checkbox-checked + span {
    color: $black-3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkbox-default {
    display: none;

    & + span {
      margin-right: 6px;
      padding: 6px 8px;
      font-size: 12px;
      text-transform: uppercase;

      &:hover {
        box-shadow: inset 0 0 0 1px color(primary, main);
      }
    }
    &.checkbox-checked + span {
      background-color: color(primary, main);
    }
  }

  &--sort {
    .radio-list {
      .formControlLabel-label {
        white-space: nowrap;
      }
    }
  }

  @include media-sm {
    &--hide {
      max-height: 100px;
      overflow: hidden;
    }
  }
}
.games-filter__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .button-default {
    padding-right: 0;
    padding-left: 0;

    &::after {
      width: 100%;
    }
  }
}
.games-filter__footer-number {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: $font-weight-base;
}
@import '../GameFeaturesFilter/GameFeaturesFilter';
