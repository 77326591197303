.low-balance-modal {
  &__buttons {
    margin-top: 20px;
    text-align: center;
  }
  &__button {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
