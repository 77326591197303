.support-form-custom {
  &--description {
    margin: 20px 0 40px;
    font-size: 16px;
  }

  .support {
    &__title {
      font-size: 32px!important;
    }
  }
  .gridItem-root {
    &--firstName,
    &--email,
    &--message,
    &--subject,
    &--lastName, {
      display: none;
    }

    &--occupation, &--income {
      .field-input--primary .field-label {
        @include media-sm {
          font-size: 16px;
        }
      }
    }

    &--income-source-title {
      font-size: 18px;

      @include media-sm {
        font-size: 16px;
      }
    }

    &--income-source {
      display: grid;
      grid-template-columns: 50% 50%;

      .formControlLabel-root {
        .checkbox-checked {
          color: $green-1;
        }

        .formControlLabel-label {
          font-size: 16px;
          font-weight: 500;
          font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Montserrat", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        }
      }

      @include media-sm {
        grid-template-columns: 100%;
      }
    }
  }
}
