@keyframes toTop {
  from {
    transform: translateY(100%);
    fill: $white;
  }
  to {
    transform: translateY(0);
    fill: $orange-1;
  }
}
.volatility {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
  color: $white;

  @include media-lg {
    font-size: 12px;
  }
  .rating {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
    transition: all 1s;

    &__item {
      fill: $white;

      &--active {
        animation: toTop 1s ease-in-out;
        fill: $orange-1;
      }
    }
  }
}
