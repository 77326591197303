.game-list {
  margin-bottom: 20px;

  @include media-xs {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    position: relative;
    margin: 0 0 20px;
    padding: 10px 15px 10px 15px;
    text-align: center;
    text-transform: uppercase;
    color: $black-4;
    font-size: 14px;
    font-weight: $font-weight-base;
    background-color: color(primary, main);

    .on-home & {
      padding: 10px 90px;

      @include media-xs {
        padding: 10px 70px 10px 15px;
        text-align: left;
      }
    }
    &::before {
      @include svg-icon;
      position: relative;
      top: 3px;
      left: -6px;
      line-height: 0;
      font-size: 18px;
    }
    @include media-xs {
      margin-bottom: 8px;
    }
  }
  &__title-link {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 14px;
    color: $black-4;
    text-transform: none;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $black-4;
      opacity: 1;
      transition: .1s;
    }
    &:hover {
      color: $black-4;

      &::after {
        opacity: 0;
        bottom: -2px;
      }
    }
  }
  &__empty,
  &__not-available {
    padding: 10px 0;
    font-size: 18px;
    text-align: center;
    color: #8e8e8e;
  }
  &__not-available {
    grid-column: auto / span 5;

    @include media-md {
      grid-column: auto / span 4;
    }
    @media (max-width: 900px) {
      grid-column: auto / span 3;
    }
    @include media-xs {
      grid-column: auto / span 2;
    }

    #root[data-url="/group/all"] &,
    #root[data-url="/all-games"] & {
      grid-column: auto / span 4;

      @media (max-width: 900px) {
        grid-column: auto / span 3;
      }
      @include media-xs {
        grid-column: auto / span 2;
      }
    }
  }
  &--popular {
    .game-list__title {
      color: $white;
      background-color: #ff3030;

      &::before {
        content: '\0079';
      }
    }
  }
  &--slots {
    .game-list__title {
      &::before {
        content: '\007a';
      }
    }
  }
  &--jackpot {
    .game-list__title {
      &::before {
        content: '\0030';
      }
    }
  }
  &--table {
    .game-list__title {
      &::before {
        content: '\0031';
      }
    }
  }
  &--favourite {
    .game-list__title {
      &::before {
        content: '\0058';
        top: 4px;
      }
    }
  }
  &--liveBlackjack {
    .game-list__title {
      &::before {
        content: '\002d';
      }
    }
  }
  &--livePoker {
    .game-list__title {
      &::before {
        content: '\002e';
      }
    }
  }
  &--liveRoulette {
    .game-list__title {
      &::before {
        content: '\002f';
      }
    }
  }
  &--liveBaccarat {
    .game-list__title {
      &::before {
        content: '\005b';
      }
    }
  }
  &--liveAll {
    .game-list__title {
      &::before {
        content: '\005c';
      }
    }
  }
  &__games {
    margin: 0 -6px;
    max-width: calc(100% + 12px);

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &--grid {
      margin: 0;
      max-width: 100%;

      .game-list__games-wrapper {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 15px;
        flex-shrink: 0;

        @include media-md {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 900px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include media-xs {
          grid-template-columns: repeat(2, 1fr);
        }
        .featured-games {
          grid-area: 1 / 1 / 3 / 4;
          @media (max-width: 900px) {
            grid-area: 1 / 1 / 3 / 4;
          }
        }

        .game-thumbnail {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  &__buttons-panel {
    margin-top: 20px;
    text-align: center;
    grid-column: 1 / 7;

    @include media-md {
      grid-column: 1 / 5;
    }
    @media (max-width: 900px) {
      grid-column: 1 / 4;
    }
    @include media-xs {
      grid-column: 1 / 3;
    }
    #root[data-url="/group/all"] &,
    #root[data-url="/all-games"] & {
      grid-column: 1 / 7;
      @include media-md {
        grid-column: 1 / 5;
      }
      @media (max-width: 900px) {
        grid-column: 1 / 4;
      }
      @include media-xs {
        grid-column: 1 / 3;
      }
    }
  }
  &__button {
    @include media-xs {
      min-width: 120px;
    }
  }
}
