.main-decorator__footer {
  position: relative;
  @include media-md {
    padding-bottom: $home-menu-mobile-height;
  }
}
.session-timer__container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 14px;
  }
}
.footer {
  position: relative;
  margin: auto;
  color: color(text, light);

  &__border {
    height: 2px;
    margin: 0 4px;
    background: $gray-2;
  }
  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media-mobile {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .session-timer {
      @include field-font__h4;
      display: flex;
      align-items: center;
      color: color(footer, text);

      &__container {
        border: 1px solid red;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 10px;
      }
      .session-time__title {
        @include field-font__h4;

        display: flex;
        align-items: center;
        margin: 0;

        &::after {
          @include field-font__h2;
          @include svg-icon(custom);

          content: '\0034';
          margin: 0 23px;
          color: color(text, light);
        }
      }

      @include media-mobile {
        display: none;
        font-size: 14px;
      }
    }
    p {
      @include field-font__h5;

      display: flex;
      align-items: center;
      margin: 0;
      line-height: 1;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;
      color: color(text, light);

      @include media-lg {
        font-size: 14px;
      }
      &::after {
        @include field-font__h2;
        @include svg-icon(custom);

        content: '\0051';
        margin-left: 20px;
        color: color(text, light);

        @include media-lg {
          margin-left: 10px;
          font-size: 20px;
        }
      }
      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
    }
  }
  &__logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 512px;
    margin: 0 auto 30px;
    color: $white;
    text-decoration: none;
    text-align: center;

    @include media-sm {
      width: calc(100% - 130px);
    }
    @include media-mobile {
      width: 100%;
      margin: 15px 0;
    }
    img {
      width: 100%;
      height: 100%;
      @include media-mobile {
        width: 100%;
      }
    }
  }
  &__message {
    &--responsibility,
    &--spelinspektionen,
    &--stodlinjen,
    &--requisites {
      white-space: pre-line;
      margin-bottom: 10px;

      @include media-sm {
        margin-bottom: 20px;
      }
      .markdown-root {
        em {
          font-style: normal;
          font-weight: $font-weight-base;
        }
        p {
          &:first-child {
            img {
              display: block;
              max-width: 100%;
              height: 40px;
              margin-bottom: 10px;
              object-fit: contain;
              object-position: center;
            }
          }
        }
        @include media-sm {
          p {
            padding-left: 40%;

            &:first-child {
              position: relative;

              a {
                margin-left: -40%;
                padding-left: 40%;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: none;
                width: 35%;
              }
            }
          }
        }
      }
    }
    &--spelinspektionen,
    &--stodlinjen {
      font-weight: $font-weight-base;
      padding: 0 10px;

      @include media-sm {
        padding: 0;
      }

      .markdown-root {
        a {
          display: block;
        }
      }
    }
    &--responsibility {
      padding-right: 10px;
      font-weight: $font-weight-base;

      @include media-sm {
        padding-right: 0;
      }
      .markdown-root {
        @include media-sm {
          position: relative;
        }

        p {
          &:first-child {
            img {
              display: inline-block;
              width: auto;
              margin: 0 3px;
              vertical-align: middle;
            }

            @include media-sm {
              position: absolute;
              top: 0;
              left: 0;
              width: 35%;
              padding-left: 0;
              text-align: center;

              img {
                position: static;
                height: 32px;
              }
            }

            @include media-xs {
              img {
                display: block;
                width: 100%;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
    &--spelinspektionen {
      em {
        @include field-font__h4;

        color: color(text, main);
      }
    }

    &--requisites {
      padding-left: 10px;

      @include media-sm {
        padding-left: 0;
      }

      em {
        @include field-font__h4;

        display: block;
        margin: -5px 0 5px;
      }
    }
    &--responsible-gambling-message {
      @include field-font__h6;
      color: color(footer, text);

      .markdown-root {
        p {
          margin: 0;

          @media (max-width: 560px) {
            max-width: 370px;
          }
        }
        img {
          @include size(29px);

          display: inline-block;
          margin-left: 10px;
          vertical-align: middle;
          filter: brightness(1) grayscale(100%);
        }
        a {
          color: color(footer, text);
          white-space: nowrap;

          &:hover,
          &.active {
            color: color(footer, link-active);
          }
        }
      }
    }
  }
  &__seo-section {
    padding: 40px 0;
    background: color(background, footer);

    &--new {
      @media (min-width: 1540px) {
        padding-top: 60px;
      }
    }

    &:empty {
      display: none;
    }
  }
  &__top-section {
    padding-bottom: 30px;
    background: color(background, footer-menu);

    @include media-mobile {
      padding-bottom: 15px;
    }
    .container.footer__group {
      max-width: 1200px;
      padding-top: 25px;

      @include media-ml {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
      @include media-mobile {
        padding-top: 10px;
      }
    }
  }
  &__top-section &__message {
    &--responsible-gambling-message {
      margin-bottom: 30px;

      @media (min-width: $size-mobile) {
        display: flex;
        justify-content: center;
      }
      @include media-mobile {
        margin-bottom: 15px;
      }
      .markdown-root {
        margin-top: 9px;
        margin-bottom: 13px;
        color: color(accent, primary);

        p {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          max-width: 100%;
          font-size: 24px;

          @include media-mobile {
            font-size: 16px;
          }
          img {
            @include size(21px);
            margin-left: initial;
            margin-right: 8px;
          }
        }
      }
    }
  }
  &__bottom-section {
    padding-bottom: 15px;
    background: color(background, footer);

    .pay-and-play & {
      padding-bottom: 100px;

      @include media-md {
        padding-bottom: 0;
      }
    }
  }
  &__bottom-section &__group {
    display: flex;
    justify-content: space-between;

    @include media-md {
      display: block;
    }
    &--left {
      max-width: 1080px;
      padding-top: 25px;

      @include media-lg {
        max-width: none;
      }
      @include media-mobile {
        padding-top: 16px;
      }
    }
    &--right {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 25px 0 25px 15px;
      @include media-md {
        padding: 0;
        align-items: center;
      }
      img {
        flex: 1 0 auto;
        width: 170px;
        height: 108.3px;
        margin-bottom: 15px;
        @include media-md {
          margin: 10px 0;
        }
      }
      .logos {
        display: flex;
        align-items: flex-start;

        a {
          img {
            height: 52px;
          }
        }
      }
    }
  }
  &__bottom-section &__message {
    &--responsible-gambling-message {
      .markdown-root {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include media-md {
          justify-content: center;
        }
      }
    }
  }

  .third-party-logos {
    &__gambleaware {
      img {
        max-width: 150px;
      }
    }
    &__ibas {
      img {
        max-width: 100px;
      }
    }
    &__gamstop {
      img {
        width: 130px;
      }
    }
    @include media-md {
      &__gambleaware {
        img {
          max-width: 120px;
        }
      }
      &__ibas {
        img {
          max-width: 80px;
        }
      }
      &__gamstop {
        img {
          width: 100px;
        }
      }
    }
  }
  &__license-text {
    @include field-font__h6;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    text-align: justify;
    color: $white;

    @include media-mobile {
      margin-top: 0;
      margin-bottom: 10px;
    }
    a {
      @include link--root;
      @include link--color(light);
      @include link--hover(light);
    }
    p {
      margin: 0;
      line-height: normal;
    }
  }
  &__footer-timer {
      @include field-font__h4;
      color: color(footer, text);
      padding-bottom: 25px;
      white-space: nowrap;
      svg {
        margin: 0 10px 0 23px;
        color: color(text, light);
      }
    @include media-md {
      padding: 0;
      margin-bottom: 10px;
    }
  }
  .footer__messages--swe {
    @include field-font__h5;
    color: color(text, main);
    flex-wrap: wrap;

    p {
      margin-top: 0;
      margin-bottom: 5px;
    }
    a {
      color: color(text, main);
    }
  }
  &__version {
    display: none;
    @include field-font__h6;

    margin-top: 10px;
    text-align: center;
    color: color(footer, text);

    &-visible {
      display: block!important;
    }
  }
  &__copyright {
    @include field-font__h6;

    margin-top: 10px;
    text-align: center;
  }
  .settings-menu {
    margin: -25px -50px;

    @include media-lg {
      margin: 0;
    }
    @include media-mobile {
      margin: -6px -12px;
    }
  }
  &-seo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 30px;

    @include media-md {
      grid-template-columns: 1fr;
    }

    &__toggle-expand {
      margin-top: 20px;
      margin-bottom: -15px;
      text-align: center;
      cursor: pointer;
    }

    &__block {
      display: grid;
      align-content: flex-start;
      grid-row-gap: 20px;
      color: $white;

      &--expand {
        .footer-seo__content {
          transition: all .3s ease;
          overflow: hidden;
          height: 0;
        }

        .check {
          position: absolute;
          width: 1px;
          height: 1px;
          overflow: hidden;
          clip: rect(0 0 0 0);
          margin: -1px;
        }

        .check:checked ~ .footer-seo__content {
          height: auto;
          margin-top: 15px;
        }

        .check:checked ~ .footer-seo__toggle-expand {
          color: green;
          display: none;
        }
      }
    }

    &__title {
      font-size: $field-font__h4;
      font-weight: 400;
    }

    &__text {
      font-size: $field-font__h6;
      line-height: 24px;
      letter-spacing: -0.35px;
      font-weight: 400;
    }
  }
}
