/* Styles for the top-level menu */
.post-login-page {
  display: flex;
  justify-content: center;
  flex: 1;

  &.container {
    padding-top: 40px;
    padding-bottom: 55px;
    background: color(background, body);

    @include media-sm {
      background: #fff;
    }

    //@include media-mobile {
    //  padding: 0;
    //  flex-grow: 1;
    //}
  }
}
.post-login__menu {
  min-width: 300px;
  max-width: 300px;

  @include media-md {
    display: none;
  }
  &:empty {
    display: none;
  }
}
.post-login-right-content-wrap {
  min-width: 300px;
  max-width: 300px;

  @include media-lg {
    display: none;
  }
}
.post-login-menu_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  /*
  Styles for the submenu
  */
  .settings-menu {
    width: 100%;
    margin:0;

    .settings-menu {
      background-color: $gray-7;
    }
    .sub-menu {
      background-color: $gray-3;
      color: $black-1;
      text-transform: none;
      position: relative;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 1px;
      }
      &::after {
        @include svg-icon(custom);
        position: absolute;
        right: 14px;
        top: 14px;
        content: '\0041';
        color: color(accent, primary);
        pointer-events: none;
      }
      a {
        display: flex;
        align-items: center;
        padding: 11px 10% 11px 22px;
        text-decoration: none;

        &.active{
          position: relative;

          &::before {
            position: absolute;
            content: '';
            left: 0;
            width: 3px;
            height: 100%;
            background-color: color(accent, primary);
          }
        }
      }
    }
  }
}
.post-login-menu {
  list-style: none;
  text-transform: uppercase;
  color: $gray-4;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  background-color: color(background, post-login-menu);

  .aside-menu__item,
  .settings-menu__item-override {
    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
  .aside-menu__item {
    position: relative;
    margin: 0;

    &::before {
      @include svg-icon(custom);
      content: '\0042';
      position: absolute;
      left: 15px;
      top: 16px;
      color: $gray-4;
      font-size: 18px;
      z-index: 2;
      transition: color $main-transition-time;
    }
    &--menuAsideProfile {
      &::before {
        content: '\0065';
      }
    }
    &--menuAsideCampaigns {
      &::before {
        content: '\0043';
      }
    }
    &--menuAsideLimits {
      &::before {
        content: '\0058';
      }
    }
    &--menuAsideTransactions {
      &::before {
        content: '\0064';
      }
    }
    &--menuAsideSettings {
      &::before {
        content: '\005a';
      }
    }
    &--menuAsideHelpAndInfo {
      &::before {
        content: '\0046';
      }
    }
    .details-root {
      position: relative;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: color(background, post-login-menu-item-root);

      &::after {
        @include svg-icon(custom);
        position: absolute;
        content: '\0041';
        color: $gray-7;
        font-size: 18px;
        right: 12px;
        top: 16px;
        transform: rotate(90deg);
        transition: transform .2s ease-in-out;
        pointer-events: none;
      }
      .detailsSummary-root{
        padding: 0;

        .detailsSummary-content {
          @include link--root;
          @include link--hover(light);
          font-weight: 500;
          margin: 0;
          padding: 13px 0 13px 47px;
        }
      }
    }
    .details-expanded {
      &::after {
        @include svg-icon(custom);
        position: absolute;
        content: '\0041';
        background-color: color(background, post-login-menu-item-root);
        color: $gray-7;
        font-size: 18px;
        right: 12px;
        top: 16px;
        transform: rotate(-90deg);
      }
    }
  }
  /* section promotion for desktop post-login-menu */
  .settings-menu__item-override--promotions, .settings-menu__item-override--loyalty {
    position: relative;
    background-color: color(background, post-login-menu-item-root);
    font-size: 18px;
    a{
      display: block;
      padding: 13px 0 13px 47px;
      text-decoration: none;
      color: $white;
      transition: 0.25s;
      font-weight: 500;
      &:hover{
        opacity: .5;
      }
    }
    &::before {
      position: absolute;
      @include svg-icon(custom);
      top: 16px;
      left: 15px;
    }
    &::after{
      position: absolute;
      @include svg-icon(custom);
      content:'\0041';
      top: 16px;
      right: 12px;
      color: $gray-7;
      pointer-events: none;
    }
  }
  .settings-menu__item-override--loyalty {
    &::before {
      content:'\0052';
    }
  }
  .settings-menu__item-override--promotions {
    &::before {
      content:'\0043';
    }
  }
}
/* Content */
.post-login-content-wrap {
  width: 100%;
  max-width: 920px;
  margin: 0 40px;
  padding: 35px var(--lg-resize) var(--md-resize);

  @include media-lg {
    margin: 0 0 0 40px;
  }

  @include media-md {
    margin: 0;
  }
  .link--back {
    margin-bottom: var(--lg-resize);
  }
  .post-login__title {
    font-size: 32px;
    margin-bottom: var(--md-resize);
    padding: 0;
    text-align: initial;
    @include media-mobile {
      font-size: 22px;
    }
  }
  & > .link {
    margin-top: 27px;
    & + .post-login-content {
      .post-login__title {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
  &--account-validation,
  &--sof-validation {
    display: flex;
  }
  &:not(&--promotions, &--tournaments) {
    background: color(background, post-login-content);
  }
  &--tournaments {
    padding: 0;
  }
  &--promotions {
    padding: 0!important;

    .link--back {
      margin-left: 20px;
      margin-top: 37px;

      @include media-mobile {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    .promo-content {

      &__title {
        font-size: 24px;
        font-weight: 700;

        @include media-mobile {
          font-size: 17px;
        }
      }

      &__list {
        list-style: none;
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        padding: 0;

        &-item {
          position: relative;
          margin-top: 30px;
          padding-left: 20px;

          @include media-mobile {
            margin-top: 20px;
            font-size: 14px;
            &:first-child {
              margin-top: 10px;
            }
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            min-width: 4px;
            height: 4px;
            background: $black-2;
            border-radius: 50%;
          }
        }
      }
    }
    &-opened {
      background: color(background, post-login-content);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      @include media-mobile {
        background: transparent;
      }
    }
    @include media-mobile {
      padding: 30px 10px 0;
    }
  }
  &--change-password {
    .group-root--buttons {
      @include media-md {
        display: flex;
        justify-content: space-around;
      }
      .button-root + .button-root {
        margin-left: 20px;
      }
      a {
        text-decoration: none;
      }
    }
    .button-root {
      min-width: 100px;

      @include media-md {
        max-width: 300px;
        width: 100%;
      }
    }
    .grid-root {
      &--password {
        max-width: 450px;
        margin-top: 60px;

        @include media-md {
          max-width: 100%;
          margin-top: 30px;
        }
        &-old {
          margin-top: 20px;
        }
      }
    }
  }
  &--self-exclusion {
    .field-select--primary {
      margin: 20px 0;
    }
    .field-select--primary + .field-select--primary{
      margin-top: 0px;
      input {
        @include field-font__h4;
        padding: 0 18px;
        font-weight: 700;
        border-color: $gray-2;
      }
    }
  }
  &--bank-funds-security {
    p {
      @include field-font__h4;
      margin: 30px 0 0;
      line-height: 24px;

      @include media-mobile {
        margin: 15px 0 0;
      }
    }
    img {
      margin-top: 50px;

      & + img {
        margin-left: 10px;
      }
    }
  }

  //&--bank-withdrawal,
  //&--bank-deposit {
  //  .bank__wrap {
  //    .bank__section:last-child {
  //      padding-right: 0;
  //
  //      @include media-sm {
  //        padding-left: 0;
  //      }
  //    }
  //  }
  //}
}
.post-login__menu:empty + .post-login-content-wrap {
  margin-left: 0;

  @include media-lg {
    margin: 0;
  }
}
.post-login-content {
  flex-direction: column;

  @include media-md {
    margin-right: auto;
    margin-left: auto;
  }

  .container {
    flex: 1;
    padding: 0;
  }
  .item {
    background-color: color(background, post-login-content);
  }
}
/*close-account page styles for markdown*/
.post-login-box {
  ul, ol, p, a {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1;
    color: $black-1;
  }
  ol {
    list-style-type: none;
  }
  p {
    margin: 4px 0;
  }
  li {
    margin: 4px 0;
  }
  a {
    font-weight: 700;
    color: $orange-1;
    text-decoration: none;
  }
  ol {
    list-style-position: inside;
    margin: 22px 0;
    li {
      margin: 9px 0;
    }
  }
  ul {
    padding-left: 19px;
    margin: 17px 0;
  }
  &_border {
    padding-top: 30px;
    border-top: 1px solid $black-1;
    @include media-xs {
      padding-top: 20px;
    }
  }
  &_margin {
    margin-top: 30px;
    @include media-xs {
      margin-top: 20px;
    }
  }
}
/* close-account page buttons*/
.button-close-account {
  .group-root {
    display: flex;
    @include media-xs {
      flex-direction: column;
      margin: 0;
    }
    .button-root {
      max-width: 300px;
      width: 100%;
      //margin: initial;
      @include media-xs {
        margin: 10px 0;
        max-width: initial;
      }
    }
  }
}
.button-wrapper {
  .button-root {
    margin: 20px 0;
  }
}
/* Styled for right column with */
.explanatory-information {
  &__block {
    margin-bottom: 2px;
  }
  &__title {
    display: flex;
    align-items: center;
    background-color: $gray-7;
    height: 40px;
    margin: 0;
    padding: 13px 0 13px 24px;
    color: #3d4141;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.58px;
  }
  &__description {
    background-color: $gray-3;
    padding: 14px;
  }
  &__text {
    margin: 0;
    color: #5a5a5a;
    font-size: 18px;
    font-weight: 400;
    &_bottom-gap {
      margin-bottom: 20px;

      @media (max-width: 1620px) {
        margin-bottom: 10px;
      }
    }
    &_accent {
      color: color(accent, secondary);
      font-weight: 700;
      text-decoration: none;
      transition: all .2s ease-in-out;
      &:hover {
        color: $green-2;
        text-decoration: underline;
      }
    }
    &_small {
      color: $black;
      font-size: 14px;
      letter-spacing: -0.45px;
    }
  }
  &__link {
    &_icon {
      position: relative;
      padding-left: 30px;
      &::before {
        position: absolute;
        @include svg-icon(custom);
        content: '\0065';
        left: 0;
        top: 2px;
        font-weight: 700;
        @media (max-width: 1620px) {
          top: 1px;
        }
      }
      @media (max-width: 1620px) {
        padding-left: 20px;
      }
    }
  }
}
.info-block {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  row-gap: 10px;

  h2 {
    @include field-font__h4;
    font-weight: 700;
    margin: 0;
    text-transform: initial;
  }
  &__title, &__element {
    display: flex;
    align-items: center;
    min-height: 40px;
  }
  &__title {
    padding: 5px 20px;
    color: $gray-1;
    background: $gray-3;
  }
  &__element {
    padding: 5px 10px;
    margin-top: 2px;
    background: $white
  }
}
.support {
  .fields-container {
    .gridItem-root {
      &--firstName, &--lastName {
        flex-basis: 50%;

        @include media-mobile {
          flex-basis: 100%;
        }
      }
    }
  }
}
.reality-check {
  .notification-info {
    margin-bottom: 20px;
  }
  .gridItem-root--limit {
    flex-basis: 33.3333%;

    @include media-mobile{
      flex-basis: 100%;
    }
  }
}
.password-modal-field {
  margin-top: 20px;
}
