.player-affordability-form {
  &__message {
    h2 {
      text-align: center;
    }
  }

  &__submit {
    margin-top: 20px;
    text-align: center;
  }
}
