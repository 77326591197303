.validation-form--group--account {
  .radioGroup-root {
    flex-direction: column;
  }

  .radioGroup-root label + label span {
    margin-top: 10px;
  }

  .subtitle {
    text-transform: initial;
  }
}
