.protection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  background: url("/cms/img/maintenance-bg.jpg");
  background-size: cover;

  .field-input--primary{
    margin-top: 30px;
  }
  .group-root--buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__logo {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px 10px;

    img {
      display: block;
      width: 100%;
      max-width: 510px;
    }
  }
  &__content {
    min-width: 650px;
    margin: auto 0;
    padding: 0 10px;
    text-align: center;
    @include media-mobile {
      min-width: 300px;
    }
  }
  &__title,
  &__text {
    margin: 0;
    color: $white;
  }
  &__title {
    @include field-font__h1;
    display: inline-block;
    font-weight: $font-weight-alt;
    text-transform: uppercase;
    width: 100%;
    height: 57px;
    font-size: 76px;
    font-style: normal;
    text-align: center;
    letter-spacing: -1.24px;
    line-height: normal;
    margin-bottom: 42px;

    @include media-md {
      font-size: 48px;
    }
    @include media-mobile {
      font-size: 33px;
    }
  }
  &__text {
    @include field-font__h3;
    font-weight: 400;
    margin-bottom: 46px;
    padding: 0 10px;
    p {
      margin-bottom: 20px;
      color: $white;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      line-height: 1.7;
      font-size: 37px;
      letter-spacing: -0.52px;

      @include media-md {
        font-size: 22px;
      }
      @include media-mobile {
        font-size: 18px;
      }
    }
  }
  &__license {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75%;
    margin: 0 auto;
    color: $white;
    font-size: 14px;
    text-align: center;

    @include media-sm {
      max-width: none;
    }

    &--button {
      @include media-sm {
        padding-bottom: 70px;
      }
    }
    .markdown-root {
      p {
        padding: 25px;
        word-break: normal;
      }
    }
  }
  &__button {
    width: 120px;
    height: 120px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    transition: .2s;
    opacity: .2;

    &:hover {
      opacity: .5;
    }
    @include media-md {
      width: 90px;
      height: 90px;
    }
    @include media-mobile {
      width: 55px;
      height: 55px;
    }
  }

  .adornment-password {
    border-radius: 0;
    color: $black-1;
  }
  .protection__subscribe {
    display: flex;
    flex-direction: column;

    button {
      width: 455px;
      height: 41px;
      border: none;
      border-radius: 10px;
      background-color: #f68d1f;
      color: $white;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 38px;
      text-align: center;
      cursor: pointer;

      @include media-mobile {
        width: 100%;
      }
    }
    input {
      width: 456px;
      height: 41px;
      background-color: $white;
      margin-bottom: 21px;
      border: none;

      @include media-mobile {
        width: 100%;
      }
    }
    input:hover {
      border: 1px solid $green-1;
    }
    @include media-mobile {
      padding: 12px;
    }
  }
}

