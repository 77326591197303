.standard-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}
.static-table {
  display: table;

  .static-table__payment-provider {
    .pay-and-play & {
      display: none;
    }
  }
  img.static-table__payment-provider-icons {
    max-width: 80px;
    max-height: 30px;
  }
  &__nowrap {
    white-space: nowrap;
  }
}

/* Tables */

.custom-table {
  display: table;
  width: 100%;

  &-head {
    display: table-header-group;
  }
  &-body {
    display: table-row-group;
  }
  &-row {
    display: table-row;
  }
  &-cell {
    display: table-cell;
    vertical-align: middle;
  }
}
.table-root,
.custom-table {
  margin-bottom: 20px;
  border: 0;
  border-radius: 0;
  border-collapse: collapse;
  overflow-x: auto;

  @include media-sm {
    display: block;
  }
  .table-row--fake {
    display: none !important;
  }
  .table-cell {
    &--rejected-reasons {
      text-align: left;
      padding: 0;
    }
    &--amount {
      font-weight: bold;
    }
    &--status--rejected {
      color: color(error);
    }
    &--status--expired {
      color: color(warning);
    }
    &--status--approved {
      color: color(success);
    }
    &--status--uploaded {
      color: #a09f9f;
    }
  }
  th,
  &-head .custom-table-cell {
    padding: 15px 4px;
    font-size: 18px;
    text-align: center;
    font-weight: $font-weight-base;
    border-bottom: 2px solid $gray-2;

    @include media-xs {
      font-size: 10px;
    }
    &:last-child {
      padding-right: 4px;
    }
  }
  td,
  &-cell {
    padding: 35px 4px;
    font-size: 18px;
    text-align: center;
    border-bottom: 2px solid $gray-2;

    &:last-child {
      padding-right: 4px;
    }
  }
  @media (max-width: 668px) {
    .custom-table {
      display: block;

      &-head {
        display: none;
      }
      &-body {
        display: block;
      }
    }
  }
}
.static-content {
  .table-root {
    width: 100%;
    border-spacing: 0;
  }
}
.tableHead-Lowercase {
  text-transform: lowercase;
  font-size: 12px;
}
.complaint-procedure__table {
  @include media-sm {
    margin: 0 -20px;
  }
}
