#root[data-url^="/support"] {
  .main-decorator__wrapper {
    min-height: auto;
    .content.help-page {
      .help-page__breadcrumbs {
        margin-bottom: var(--md-resize);
      }
    }
  }
}
.content .help-page {
  .list-of-header {
    padding: 0;
    margin: 0;
  }
}
.help-page-container {
  display: flex;
  margin: 0 auto;
  padding: 40px 15px 55px 15px;
}
.list-of-header {
  padding: 0;
  margin: 0;
  &__link {
    position: relative;
    display: grid;
    grid-template-areas: 'icon name'
                         'icon summary';
    grid-template-columns: 50px 1fr;
    grid-column-gap: 20px;
    min-height: 90px;
    padding: 15px 0;
    border-bottom: 1px solid $gray-4;
    color: color(text, dark);
    text-decoration: none;
    transition: color .3s ease-in-out;

    &:hover {
      color: color(accent, primary);
    }
    &::before {
      @include svg-icon(custom);
      content: '\0026';
      grid-area: icon;
      align-self: center;
      justify-self: center;
      max-width: 50px;
      font-size: 42px;
      color: color(accent, primary);
    }
    &:last-of-type {
      border-bottom: none;
    }
    &-announcements::before {
      content:'\0073';
    }
    &-my-player-account::before {
      content:'\0065';
    }
    &-mijn-speelaccount::before {
      content:'\0065';
    }
    &-registration--bsn-number--verification::before {
      content:'\0068';
    }
    &-registratie--bsn--verificatie::before {
      content:'\0068';
    }
    &-bonus-promotions::before {
      content:'\0057';
    }
    &-deposit::before {
      content:'\007a';
    }
    &-geld-storten::before {
      content:'\007a';
    }
    &-withdrawal::before {
      content:'\0030';
    }
    &-geld-opnemen::before {
      content:'\0030';
    }
    &-game-rules::before {
      content:'\0072';
    }
    &-spelregels::before {
      content:'\0072';
    }
    &-responsible-gaming::before {
      content:'\0058';
    }
    &-verantwoord-spelen::before {
      content:'\0058';
    }
    &-betting::before {
      content:'\0070';
    }
    &-racing::before {
      content:'\006d';
    }
    &-casino::before {
      content:'\0056';
    }
    &-bingo::before {
      content:'\004e';
    }
    &-poker::before {
      content:'\006f';
    }
    &-support::before {
      content:'\0062';
    }
    &-aml-policy::before {
      content: '\0031';
    }
  }
  &__name {
    grid-area: name;
    align-self: end;
    font-weight: bold;
    font-size: 20px;
  }
  &__summary {
    grid-area: summary;
    font-size: 18px;
  }
}
/* Styles for help-pages */
.help-page {
  margin-right: 35px;
  padding: 10px 20px;
  flex-grow: 1;
  background-color: $white;

  &__breadcrumbs {
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.45px;
    line-height: 38px;
  }
  &__link {
    color: color(accent, primary);
    text-decoration: none;
    transition: color .2s ease-in-out;
    padding: 0 0 0 15px;
    &:hover {
      color: $black-2;
    }
    &_with-icon {
      position: relative;
      padding-left: 30px;

      &::before {
        @include svg-icon(custom);
        position: absolute;
        content: '\006c';
        left: 0;
        top: -2px;
        font-size: 20px;
      }
    }
    &_with-border {
      border-left: 2px solid color(accent, secondary);
      transition: color .2s ease-in-out, border-left .2s ease-in-out;
    }
    &:first-of-type {
      padding: 0 10px 0 30px;
    }
  }
  &__title {
    @include field-font__h2;
    font-size: 32px;
    letter-spacing: 0.6px;
    margin-bottom: 25px;
    &_white {
      color: color(white);
    }
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 542px;
  }
}
/* Styles for help-pages section answer*/
.section-answer {
  padding: 20px 0;
  font-size: 16px;
  border-top: 1px solid $gray-4;

  ul {
    padding-left: 19px;
  }
  &[open] &__body{
    animation: animateDown 0.3s ease forwards;
  }
  &_border {
    border-top: 1px solid color(white);
    margin: 0;
    padding: 14px 0;
  }
  &__title {
    position: relative;
    padding-right: 28px;
    font-weight: bold;
    list-style-type: none;
    cursor: pointer;
    &_skinny {
      font-weight: normal;
    }
    &::-webkit-details-marker {
      display: none;
    }
    &:focus {
      outline: none;
    }
    &_with-icon::after {
      @include svg-icon(custom);
      content: '\0041';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(90deg);
      transition: rotate ease .3s;
      color: $gray-1;
    }
  }
  &[open] &__title_with-icon::after{
    transform: translateY(-50%) rotate(-90deg);
  }
  &__body {
    padding: 20px 0 0 0;
    ol {
      padding-left: 19px;
    }
  }
  &__help {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    margin: 15px 0;
    color: $orange-1;
  }
  &__button {
    display: block;
    width: 110px;
    text-align: center;
    padding: 7px 0;
    color: #323434;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #898989;
    font-size: 14px;
    background: rgb(84,255,0);
    background: linear-gradient(180deg, rgba(84,255,0,1) 0%, rgba(0,165,0,1) 100%);

    &:hover {
      color: color(button--primary, text);
      opacity: 0.8;
    }
  }
  &__accent {
    &_orange {
      color: color(accent, primary);
    }
  }
  &__list {
    margin: 10px 0;
    @include field-font__h4;
  }
  &__element-of-list {
    margin: 10px 0;
  }
}
.help-page > .section-answer__body {
  padding-top: 0;
}
@keyframes animateDown {
  0% {
    opacity: 0;
    transform: translatey(-15px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
.help-page__info {
  max-width: 300px;
  min-width: 300px;
}
.popular-question {
  display: inline-block;
  background-color: $gray-3;
  padding: 0 14px;
  color: $gray-1;

  &__title {
    margin: 0;
    padding: 13px 0;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.58px;
    color: $gray-1;
  }
}
.general-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 14px 14px 14px;
  color: $black-1;
  font-size: 18px;
  background-color: $white;
  border-top: 7px solid color(accent, primary);
  &__title {
    font-size: 18px;
    margin: 15px 0 0 0;
  }
  &__button {
    width: 112px;
    margin: 15px 0 20px 0;
    padding: 12px 0;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
    transition: background .3s ease-out;
    background: rgb(84,255,0);
    background: linear-gradient(180deg, rgba(84,255,0,1) 0%, rgba(0,165,0,1) 100%);

    &:hover {
      color: color(button--primary, text);
      opacity: 0.8;
    }
  }
  &__link {
    margin: 15px 0;
  }
  &__text {
    margin: 0 0;
  }
  &__accent {
    color: color(accent, secondary);
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 768px) {
  .help-page{
    margin-right: 20px;
    &__breadcrumbs {
      font-size: 14px;

    }
    &__link_with-border {
      color: red;
    }
    &__link {
      padding: 0 0 0 10px;
    }
    &__breadcrumbs {
      line-height: 22px;
    }
  }
  .help-page__info {
    max-width: 250px;
    min-width: 250px;
  }
  .list-of-header__link::before {
    font-size: 36px;
    top: 14px;
  }
  .list-of-header__name,
  .list-of-header__summary,
  .general-info {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px){
  .help-page-container {
    flex-direction: column;
    padding: 35px 15px;
  }
  .help-page {
    margin-right: 0;
    &__info {
      display: flex;
      flex-direction: column-reverse;
      max-width: initial;
    }
    &__link {
      padding: 0 5px;
    }
  }
  .popular-question {
    margin: 18px 0;
    padding: 0 25px;
  }
  .general-info {
    padding: 0 25px 17px 25px;
  }
}
