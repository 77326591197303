.local-time {
  display: flex;
  align-items: center;
  &__title {
    font-weight: $font-weight-base;
    font-size: 10px;
  }
  &__icon {
    margin-right: 10px;
    vertical-align: bottom;
    @include media-sm{
      display: none;
    }
  }
  &--has--icon {
    .local-time__title {
      margin-left: 18px;
    }
  }
}
