.netherlandsLimitsThresholdsModal {
  .modal-paper {
    .modalContentWrapper {
      .modal-titleRoot {
        h2 {
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
        }
      }

      .markdown-root {
        p {
          font-size: 18px;
          font-weight: 400;
        }

        strong {
          display: block;
          margin: 20px 0;
        }
      }

      .group-root--buttons {
        .button-primary {
          font-size: 18px;
          font-weight: 600;
          background: linear-gradient(180deg, rgba(84,255,0,1) 0%, rgba(0,165,0,1) 100%);
          height: 40px;
          border-radius: 10px;
          text-transform: uppercase;
          margin-top: 55px;
        }
      }
    }
  }
}
