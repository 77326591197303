.form {
  padding: 10px 30px;

  &--small {
    @include media(null, $size-xs) {
      max-width: calc(100% / 3 * 2);
      margin: auto;
      box-sizing: unset;
    }
  }
}
