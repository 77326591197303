.congratulation-modal {
  align-items: flex-start;

  img {
    width: 120px;
  }
  .gridItem-root {
    &--row2 {
      padding-top: 0;
    }
    &--row1, &--row3, &--link {
      padding-top: var(--lg-resize);
    }
  }
}
