.netherlands-limits-confirm-modal {
  &__notification {
    border-color: #ffcc00;
  }

  &__notification p {
    font-size: 14px;
  }

  &__notification::before {
    background-color: #ffcc00;
  }

  &__confirm-button {
    padding: 12px 32px;
    color: #fff;
    white-space: break-spaces;
    line-height: 19px;
  }

  &__group-confirm-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
}

.netherlands-limits-deposit-modal {
  :has(.error-code-228 ) &:first-child,
  :has(.error-code-249 ) &:first-child,
  :has(.error-code-250 ) &:first-child {
    background: linear-gradient(
        to bottom,
        #000000,
        #541f5a,
        #82226c,
        #e60881
    ) !important;
  }
}
