.balance {
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &--wagering-requirement {
      align-items: baseline;
    }
  }
  &__title {
    width: 280px;
    font-size: 18px;
    color: color(accent, secondary);
    font-weight: 700;
    text-align: left;

    @media (max-width: 560px) {
      width: 160px;
      padding-right: 10px;
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }
  }
  &__value {
    min-width: 170px;

    @media (max-width: 560px) {
      min-width: 120px;
    }
  }
  &__value,
  &__value a{
    color: $green-1;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-align: left;
  }
  &__user {
    color: $gray-6;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 25px;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;

    @media (max-width: 560px) {
      align-items: center;
    }
  }
  &__logout {
    border-radius: 6px;
    border: 2px solid #464646;
    color: $black-1;
    font-size: 18px;
  }
}
