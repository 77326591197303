.loyalty-points-conversion-form {
  $spacer: (
    lg: 50px,
    sm: 30px
  );

  @mixin amount {
    font: {
      size: 25px;
      weight: bold;
    }
    letter-spacing: -1px;
    white-space: nowrap;

    @include media-sm {
      font-size: 20px;
    }
  }

  // Row
  &__row {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;

    @include media-sm {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 15px;
    }
  }

  &__convert-title {
    font-weight: bold;
    text-transform: capitalize;
    @include media-sm {
      text-align: center;
    }
  }

  // Award points
  &__award-points {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 175px;
    height: 175px;
    padding-left: 45px;
    position: relative;
  }
  &__award-points-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .circularProgress-path {
      stroke: $orange-3;
    }
    .circularProgress-content {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  &__award-points-amount {
    @include amount;
  }
  &__award-points-caption {
    p {
      max-width: 109px;
      font-size: 14px;
    }
  }
  // Convert
  &__convert {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 175px;
    padding: 0 30px;

    @include media-md {
      margin-left: map-get($spacer, sm);
    }

    @include media-sm {
      margin-top: map-get($spacer, sm);
      margin-left: 0;
      align-items: center;
    }
  }
  &__convert-warning {
    &.notification-root {
      flex-grow: 0;
      background-color: transparent;
      border: none;
      &::before {
        margin: 0 -10px 0 0px;
        content: "";
        font-family: $font-primary;
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        background: url('/cms/img/icons/exclamation.svg') no-repeat center /cover;
      }

      p {
        color: #333;
        font-size: 14px;
        max-width: 290px;
        font-weight: normal;
      }
    }
    .notification-message {
      padding: 0 20px;
    }
  }
  &__convert-money {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  &__convert-money-amount {
    @include amount;
  }
  &__convert-action {
    min-width: 200px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  &__tier-expiry {
    p {
      font-size: 14px;
    }
    @include media-sm {
      text-align: center;
    }
  }
}
