.game-summary {
  &__lifetime {
    font-size: 12px;

    &-wrapper {
      padding: 16px 5px 0 5px;
      font-size: 12px;
    }

    &-text {
      padding-bottom: 12px;
      text-align: left;
      color: color(text, main);
    }

    &-values-block {
      display: flex;
      justify-content: space-between;
    }
  }

  .button-secondary, .button-accent {
    margin: 16px 0 6px 0;
  }

  &__button {
    margin: 10px 0;
    display: none;

    @include media-md {
      min-width: 135px;
      padding: 0;
      display: inline-block;
      font-size: 14px;
    }
  }

  &__button-wrapper {
    @include media-md {
      display: flex;
      flex-direction: column;
      padding: 0 5px;
    }

    .button-secondary, .button-accent {
      margin: 16px 0 6px 0;
    }

    .custom-width {
      @include media-md {
        min-width: 135px;
        padding: 0;
        font-size: 14px;
      }
    }
  }
  &__game-transactions {
    display: block;
    margin: 24px 0 14px 0;

    @include media-md {
      margin: 5px 0 5px 0;
    }
  }
}


