.right-content {
  position: fixed;
  left: 100vw;
  top: 0;
  width: 80vw;
  max-width: 80vw;
  min-width: 80vw;
  height: 100%;
  background: #34393a;
  color: $white;
  overflow: hidden;
  transition: all 0.3s linear 0s;

  &__block {
    height: 80px;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid $black-1;
  }
  &__content {
    padding: 20px;
  }
  &__overlay {
    display: none;
  }
  .button-close {
    @include close-button;
    padding: 35px 0;

    &::before {
      color: color(secondary, light);
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100%;
      width: 100vw;
      height: 2px;
      background: $black-2;
    }
  }
  &.forgot {
    label[data-shrink="true"] {
      color: color(text, main);
    }
    .forgot-password {
      &__receive-email {
        h2,
        a {
          color: color(text, main);
        }
      }
    }
  }
  .login-form.custom {
    .field-input {
      max-width: initial;
      width: 100%;
    }
  }
  .field-label {
    color: $white;
  }
}
.main-decorator {
  will-change: margin-left;
  transition: all 0.3s linear 0s;

  &.shifted {
    margin-left: -80vw;

    .right-content {
      transition: all 0.3s linear 0s;
      left: calc(20vw - var(--scrollbarWidth));
      z-index: 101;

      &__overlay {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        width: 100vw;
        height: 100vh;
      }
    }
  }

  @include media-xs {
    margin-top: $header-height-mobile;
  }
}
