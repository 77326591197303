.horizontal-game-group {
  &__game-group-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
    gap: 20px;
    @media (max-width: $size-xs) {
      margin-bottom: 15px;
    }
  }
  &__game-group-actions {
    flex: 0 0 200px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-mobile {
      flex: initial;
    }
  }
  &__game-group-info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  &__game-group-description {
    text-transform: uppercase;
    font-size: 18px;
    @media (max-width: 600px) {
      font-size: 14px;
    }
    @include media-mobile {
      width: auto;
    }
  }
  &__game-group-name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
  }
  &__game-group-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black-5;
    border: none;
    border-radius: 10px;
    color: $white;
    font-size: 16px;
    padding: 0 12px;
    text-decoration: none;
    text-transform: uppercase;
    height: 36px;
    width: 104px;
    white-space: nowrap;
    &--nl {
      width: 140px;
    }
    @media (max-width: $size-xs) {
      font-size: 12px;
    }
  }
  &__game-group {
    margin-top: 60px;
    @media (max-width: $size-xs) {
      margin: 30px 0;
    }
    &:first-child {
      margin-top: 0;
      padding-top: 0;

      .horizontal-game-group__game-group-info {
        @media (max-width: 1650px) {
          width: 340px;
          flex: initial;
        }
      }
    }
    &--loading {
      .horizontal-game-group__games {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 240px;
      }
      .horizontal-game-group__spinner {
        opacity: 1;
        transition: all 0.3s 0.3s; // TODO: add when game filter smooth scrolling bug will be fixed
      }
    }
  }
  &__games {
    position: relative;
  }
  &__game-list {
    transition: all 0.3s;
    position: relative;
  }
  &__spinner {
    opacity: 0;
  }
}
