.close-account {
  &-form {
    .custom-link {
      color: $green-1;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
  &-container {
    width: 100%;
    .content {
      &--title {
        font-weight: bold;
        margin: 30px 0;
      }

      &--textarea {
        border: 1px solid black;
        margin: 15px 0 30px 0;
        padding: 15px;
        overflow-y: scroll;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 35px;
      margin-top: 50px;
    }

    .menu {
      margin-top: 20px;
      .section-details {
        padding: 16px;
        font-size: 16px;
        background-color: #F5F5F5;
        margin-bottom: 2px;

        &[open] &__body{
          animation: animateDown 0.3s ease forwards;
        }

        &__title {
          position: relative;
          font-weight: bold;
          list-style-type: none;
          cursor: pointer;

          &::-webkit-details-marker {
            display: none;
          }
          &:focus {
            outline: none;
          }
          &_with-icon::after {
            @include svg-icon(custom);
            content: '\0041';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(90deg);
            transition: rotate ease .3s;
            color: $gray-7;
          }
        }
        &[open] &__title_with-icon::after{
          transform: translateY(-50%) rotate(-90deg);
        }

        &__body {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .list {
            padding: 23px 30px 20px 30px;

            &__item {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .stepper {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__title {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }


}
