.responsible-gambling-questionnaire {
  flex: 1;
  padding: 0 0 10px 0;
  background-color: color(background, post-login-content);

  &__title {
    margin: 0;

    p {
      position: relative;
      display: inline-block;
      margin: 20px 0 44px;
      letter-spacing: .05em;

      &::before {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: color(primary, main);
      }
    }
  }


  .questionnaire {
    &-slider {
      overflow: hidden;
      padding-bottom: 5px;
      &__stepper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(35px, 1fr));
        gap: 10px;
        margin: 0;
        padding: 0;
        list-style: none;
        @include media-sm {
          grid-template-columns: repeat(6, minmax(35px, 1fr));
        }
      }
      &__step {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        font-size: 18px;
        color: $white;
        font-weight: bolder;
        background-color:$orange-1;

        &.active {
          background-color: $green-1;
        }
        &:last-child {
          grid-column: span 2;
        }
      }
      &-content {
        margin: 30px auto 0 auto;
        padding: 20px;
        width: calc(100% - 10px);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);

        h3 {
          margin-bottom: 10px;
          font-size: 15px;
          text-align: center;
          text-transform: uppercase;
        }
        &__hidden {
          animation: hiddenStep 0.4s linear;
        }
        &__newStep {
          animation: newStep 0.4s linear;
        }
        &__buttons {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          button {
            margin: 0 0 15px 0;
            width: 200px;
            cursor: pointer;
          }
        }
        &__next {
          display: block;
          cursor: pointer;
          border: none;
          margin: 0 auto;
          width: 200px;


          &[disabled] {
            opacity: 0.5;
          }
        }
        &__reset {
          margin-top: 20px;
        }
      }
    }
  }
  p {
    font-size: 18px;
    @include media-mobile {
      font-size: 14px;
    }
  }
  h2 {
    margin-bottom: var(--md-resize);
  }
}
@keyframes hiddenStep {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
@keyframes newStep {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0%);
  }
}
