$heightOfHeader: 174px;
$indent: 20px;
$gameHeader: 51px;

.gd-window {
  position: relative;
  min-width: 600px;
  max-width: calc((100vh - #{$heightOfHeader + $indent*2 + $gameHeader}) * 1.78);
  width: 100%;
  margin: $indent auto;
  transition: .2s;

  @media (max-width: 1620px) {
    $heightOfHeader: 152px;
    max-width: calc((100vh - #{$heightOfHeader + $indent*2 + $gameHeader}) * 1.78);
  }
  @media (max-width: 600px) {
    min-width: 100%;
  }
  &--with-games-statistics {
    $gamesStatistics: 83px;
    max-width: calc((100vh - #{$heightOfHeader + $indent*2 + $gameHeader + $gamesStatistics + $indent}) * 1.78);

    @media (max-width: 1620px) {
      $heightOfHeader: 152px;
      max-width: calc((100vh - #{$heightOfHeader + $indent*2 + $gameHeader + $gamesStatistics + $indent}) * 1.78);
    }
  }

  /*   below style for header title game-swith controls-section   */
  &__header {
    display: grid;
    grid-template-areas: "title game-switch controls-section";
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 19px 22px;
    background: color(background, game-window);
    color: color(text, light);

    @include media-mobile {
      grid-template-areas: "title controls-section"
                           "game-switch game-switch";
      grid-template-columns: 1fr 1fr;
    }
  }
  //left part
  &__title {
    @include field-font__h4;

    grid-area: title;
    margin: 0;
    display: flex;

    h1 {
      @include field-font__h4;
    }
    strong {
      font-weight: normal;
      color: $orange-1;
    }
    @include media-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__spent-time {
    &:before {
      @include svg-icon(custom);
      content: '\0034';
      font-size: 20px;
      padding-right: var(--sm-resize);
    }
  }
  &__local-time {
    margin-left: 27px;
    &:before {
      @include svg-icon(custom);
      content: '\0033';
      font-size: 20px;
      padding-right: var(--sm-resize);
    }
  }
  &__spent-time,
  &__local-time,
  &__title-counterweight {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    &:empty {
      display: none;
    }
  }
  //middle part
  &__game-switch {
    @include field-font__h4;

    grid-area: game-switch;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    &__status {
      position: relative;
      width: 40px;
      height: 20px;
      margin: 0 10px;
      border-radius: 10px;
      background-color: #47504f;
      transition: $main-transition-time;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        left: 0;
        border-radius: 50%;
        background-color: $gray-4;
        transition: $main-transition-time;
      }
      &--checked {
        &::before {
          right: 0;
          left: initial;
          background-color: color(accent, secondary);
        }
      }
    }
  }
  //right part
  &__controls-section {
    grid-area: controls-section;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
  &__control-btn {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 0;
    min-height: 18px;
    margin-left: 10px;
    padding: 0;
    border: 0;
    font-size: 18px;

    .button-label {
      position: absolute;
      top: -42px;
      right: 30%;
      width: auto;
      padding: 5px 10px;
      text-align: center;
      background-color: #606060;
      opacity: 0;
      white-space: nowrap;
      z-index: 5;
      transform: translateX(50%);
      transition: 300ms ease;
      pointer-events: none;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        bottom: 0;
        right: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 13px 0 13px;
        border-color: #606060 transparent transparent transparent;
        transform: translateX(50%);
      }
      @include media-mobile {
        display: none;
      }
    }
    &:hover {
      background: transparent;

      .button-label {
        transform: translate3d(50%, 0, 0);
        opacity: 1;
        pointer-events: auto;
        background: $gray-4;
      }
    }
    &::before {
      @include svg-icon;
      color: #c6c7c7;
    }
    &--close {
      margin-left: 21px;
      &::before {
        @include field-font__h4;
        @include svg-icon(custom);

        content: '\0045';
      }
      @include media-mobile {
        margin-left: 10px;
      }
    }
    &--full-screen {
      margin-left: 28px;
      &::before {
        @include field-font__h4;
        @include svg-icon(custom);

        content: '\0047';
        font-size: 18px;
      }
      @include media-mobile {
        margin-left: 13px;
      }
    }
    &.fullScreenButton-cancel {
      &::before {
        @include svg-icon(custom);
        content: '\0032';
      }
    }
    &--favourite-off {
      &::before {
        content: '\0057';
      }
    }
    &--favourite-on {
      &::before {
        content: '\0058';
      }
    }
  }
  /*   below styles for game content   */
  &__body {
    position: relative;
  }
  &__game {
    transform-origin: left;
    transition: all 0.3s;
  }
  .aspectRatio-root {
    min-width: 600px;

    @media (max-width: 600px) {
      min-width: 100%;
    }
  }
  /*   below styles for volatility, rtp, count maxlines   */
  &__statistics {
    min-height: 65px;
    margin-top: $indent;
    padding: var(--sm-resize) var(--xl-resize);
    background-color: #35393a;

    &__item {
      display: flex;
      align-items: center;
      padding: var(--sm-resize) var(--md-resize);
      font-size: 18px;
      font-weight: 400;
      color: $white;

      @include media-mobile {
        font-size: 18px;
      }

      &:empty {
        display: none;
      }
    }
    .volatility {
      font-size: 18px;
      flex-direction: row-reverse;
      align-items: flex-end;

      @include media-lg {
        font-size: 21px;
      }
      @include media-mobile {
        font-size: 18px;
      }

      .rating {
        margin: 0 0 3px 5px;
      }
    }
  }
  &__rtp {
    display: flex;
    flex-direction: row-reverse;

    .game-rtp__title {
      margin-right: 5px;
    }
  }

  &__header--mobile {
    display: none;
    .gd-window__local-time {
      margin-left: var(--sm-resize);
    }
  }
  &__footer {
    .game-provider-license {
      max-height: 32px;
      margin: 6px 0 6px;
      text-align: center;
      transition: $main-transition-time;

      p {
        margin: 0;
        font-size: 12px;
        color: color(text, light);
        text-align: center;
        transition: $main-transition-time;

        img {
          max-width: 100px;
          vertical-align: middle;
        }
      }
      &--hidden {
        max-height: 0;
        margin: 0;

        p {
          opacity: 0;
        }
      }
    }
  }
  &__main-section {
    width: 100%;

    .aspectRatio-root {
      z-index: 5;
      transition: 300ms ease;

      .aspectRatio-content {
        iframe.game__content {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
    .prefill { // ?????
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-width: 60vw;
      min-height: 60vh;
      padding: 40px;
      align-items: center;
      z-index: 50;

      .prefill__description {
        color: color(text, light)
      }
      .button-root {
        margin-top: 10px;
      }
    }
  }
  &__info-section {
    width: 100%;
    color: color(text, light);
    background: color(background, footer-menu);

    .container {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    h2 {
      margin-bottom: 25px;
      text-transform: uppercase;
      font-size: 18px;
      color: color(text, light);
    }
    p {
      font-size: 18px;
    }
    p + p {
      margin-top: 25px;
    }
  }
  &__info-section-content {
    max-height: 100%;
  }
}
