.top-registration-form {
  .title {
    margin: 0 0 30px;
  }
  &__logo {
    text-align: center;
    margin: 0 0 var(--lg-resize);
    img {
      height: 40px;
    }
  }
  &__stepper {
    margin: 0 0 var(--md-resize);

    @include media-mobile {
      margin-top: var(--md-resize);
    }
  }
  &__step-title {
    @include field-font__h3;

    margin: 0 0 30px;
    font-weight: 700;
  }
  &__message-footer {
    @include field-font__h6;
    color: $gray-2;
  }
  .button-container {
    padding-top: var(--lg-resize);
  }


  &__login-loader {
    height: 750px;
  }

  .buttons-container, div.limit-form .buttons-container {
    @include flexGap();

    padding-top: var(--lg-resize) !important;
  }
  .limit-amount {
    max-width: initial;
    height: initial;
    margin: 0;

    label {
      font-weight: 700;
    }

    .field-inputValues {
      .input-values {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 20px 5px;
        margin: 0;
      }

      .input-root {
        display: none;
      }
    }
    &__amount-input {
      .input-formControl {
        display: none;
      }
      .field-helperEmpty {
        background: transparent;
      }
    }
  }
}
