* > .pending-withdrawal:first-of-type {
  border-top: none;
}
.pending-withdrawal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray-2;
  border-bottom: 1px solid $gray-2;
  margin-bottom: var(--sm-resize);
  padding: 8px 0;
  color: $gray-2;

  &__img {
    height: 19px;

    @include media-xs {
      height: 12px;
    }
  }
  &__info {
    @include field-font__h6;
    margin: 0 16px 0 9px;
  }
  &__description {
    padding-bottom: var(--lg-resize);
    text-align: center;
    font-size: 22px;

    @include media-sm {
      padding-bottom: var(--md-resize);
      font-size: 14px;
    }
  }
  &__btn {
    font-size: 12px;
    color: $green-1;
    border: 1px solid $green-1;
    border-radius: 10px;
    padding: 11px 22px;
    min-width: initial;
    min-height: initial;

    @media(max-width: 770px){
      padding: 10px 10px;
    }
    @include media-mobile {
      padding: 5px 10px;
    }
    &:hover {
      background-color: initial;
      color: $green-2;
      border-color: $green-2;
    }
    span.button-label {
      margin: initial;
    }
  }
  &__not-found {
    padding-top: 10px;
    color: $gray-2;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
