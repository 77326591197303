.verification-form {
  background: color(background, modal);

  &__send {
    margin-top: 31px;
  }
  &__type {
    font-weight: 700;
  }
  &__link {
    color: color(accent, secondary);

    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  &__text {
    &--attempts {
      margin-bottom: 20px;
    }
  }
  &__static-field {
    margin: 20px auto;
  }
  &__field-title {
    margin-top: 25px;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__field-value {
    margin-top: 10px;
    padding: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    border: 1px dashed color(field--primary, border--normal);
    font-size: 16px;
    text-align: center;
  }
  &__attempts {
    margin: 10px;
  }
  &__edit-field {
    width: 100%;
    margin-bottom: 10px;
  }
  &__send-code {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & .field-input {
      width: 100%;

      & label {
        top: 0;
        width: 100%;
        font-size: 16px;
        text-align: left;
      }
    }

    .button-root {
      width: 100%;
      max-width: 211px;
    }
  }
  &__description {
    p {
      margin-bottom: 0;
    }
  }
  .gridItem-root {
    &--attempts {
      display: flex;
      justify-content: flex-end;
    }
  }
  .buttons-container--form {
    justify-content: flex-end;
  }
}
.field-input--custom-form {
  label {
    font-size: 16px!important;
    margin-bottom: 20px!important;
  }
}
