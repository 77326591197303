.h-captcha-modal {
  &.modal-root {
    .modal-paper {
      width: initial;
      height: initial;
    }

    @include media-sm {
      .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child {
        padding-bottom: 5px;
      }

      .modal-contentRoot {
        padding-top: 50px;
      }
    }
  }
}
