.validation-modal {
  &--shuftipro {
    &.modal-root {
      .modal-paper {
        width: 870px;

        @include media-md {
          width: 720px;
        }
      }
    }
  }
}
