.profile-form {
  .fields-container {
    .gridItem-root {
      max-width: 50%;
      flex-basis: 50%;

      &__receiveEmail,
      &__receiveSms,
      &__campaignsEnabled,
      &__publicPerson {
        max-width: 100%;
        flex-basis: 100%;
      }

      &__campaignsEnabled {
        display: none;
      }

      &__zipCode,
      &__houseNumber {
        flex: 1;
      }

      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  .field-primary.field-checkbox .checkbox-default + span,
  .field-primary.field-checkbox .checkbox-checked + span {
    @include field-font__h6;
  }
  &__verify {
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: color(error);

    & > .button-label:before {
      @include svg-icon;
      content: '\00b1';
    }
  }
}
.profile-page {
  &__btn-block {
    padding: 10px 0;
  }

  &__block {
    padding: 62px 0 0 0;
  }

  &__title {
    font-size: 18px;
  }
}
/* styles for checkbox fields */
.profile-checkbox-fields {
  display: grid;
  grid-gap: var(--md-resize);
  grid-template-columns: 1fr 1fr;
  padding-top: var(--lg-resize);

  @include media-mobile {
    grid-template-columns: 1fr;
  }

  &__row {
    display: flex;
    flex-direction: column;

    > div {
      flex-basis: 100%;
      max-width: 100%;
      padding-bottom: var(--md-resize);
    }
  }

  &__align-right {
    align-content: flex-start;
    justify-content: flex-end;
  }

  @include media-xs {
    flex-direction: column;
  }
}
