.providers-slider {
  background-color: $black-6;
  padding: 15px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    @include field-font__h3;
    text-transform: uppercase;
    color: $white;
    @media (max-width: $size-sm) {
      padding: 0;
    }
  }
  &__viewAll {
    background-color: $violet;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: $white;
    display: flex;
    font-size: 18px;
    height: 36px;
    padding: 0 12px;
    text-decoration: none;
    text-transform: uppercase;
    @media (max-width: $size-xs) {
      font-size: 14px;
    }
  }
  &__swiper-container {
    padding: 0 40px;
    margin: 20px 0px;
    position: relative;
    @media (min-width: $size-lg) {
      padding: 0;
    }
    @media (max-width: $size-sm) {
      padding: 0;
    }
  }
  &__swiper-wrapper {
    position: relative;
    display: flex;
  }
  &__swiper {
    display: flex;
    overflow: hidden;
  }
  &__item {
    background-color: $violet;
    border-radius: 15px;
    position: relative;
    max-height: 183px;
    img {
      max-width: 100%;
    }
  }
  &__item-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__nav-next,
  &__nav-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 42px;
    cursor: pointer;
    @media (max-width: $size-ml) {
      width: 20px;
      height: 30px;
    }
  }
  &__nav-next {
    right: 0px;
    background: url('/cms/img/slider/next.svg') no-repeat center center /contain;
    @media (min-width: $size-lg) {
      right: -50px;
    }
  }
  &__nav-prev {
    left: 0px;
    background: url('/cms/img/slider/prev.svg') no-repeat center center /contain;
    @media (min-width: $size-lg) {
      left: -50px;
    }
  }
}
