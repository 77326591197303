.withdrawal-modal {
  &--shuftipro {
    .modal-paper {
      width: 100%;
      max-width: 870px;
    }
  }
  .modal-paper {
    .modalContentWrapper {
      padding: 0;

      .modal-contentRoot {
        padding: 0;
      }
    }
  }
}
