.postLoginRightMenu {
  overflow: auto;
  padding: 10px 0 45px 0;
  background: $violet;

  @mixin icon {
    @include svg-icon(custom);
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  a,
  div {
    min-height: initial;
    margin: 0;
    padding: 0;
    font: {
      size: 18px;
      weight: 500;
    };
    text-decoration: none;
    box-shadow: none;
    background: initial;
  }
  a {
    @include media-sm {
      text-decoration: none;
      color: inherit;
    }
  }
  .right-content__content {
    $postLoginRightMenuWrapper: 20px;
    padding: 20px $postLoginRightMenuWrapper 0 $postLoginRightMenuWrapper;
    margin-bottom: $home-menu-mobile-height;

    & > ol {
      &.settings-menu {
        margin-top: 0;
        margin-right: -$postLoginRightMenuWrapper;
        margin-bottom: 0;
        margin-left: -$postLoginRightMenuWrapper;
      }
    }
  }
  .balance {
    &__user,
    &__logout {
      display: none;
    }
    &__row {
      justify-content: space-between;
      margin-bottom: 15px;

      &--real,
      &--promo,
      &--balance{
        .balance__value {
          a,
          span {
            color: color(accent, secondary);
          }
        }
      }
      &--wagering-requirement {
        .balance__value {
          color: color(text, light)
        }
      }

      @include media-mobile {
        white-space: nowrap;
      }
    }
    &__title {
      width: 100%;
      color: $white;
    }
    &__title p {
      font-size: 18px;
    }
    &__value {
      width: 100%;
      min-width: initial;
      text-align: right;

      a,
      span {
        color: $green-1;
      }
    }
  }
  .settings-menu {
    margin: 0;

    &__item-override {
      padding: 17px 20px;
      border-top: 1px solid color(primary, main);
      text-transform: uppercase;

      a {
        color: color(accent, secondary);
      }

      &--promotions, &--loyalty {
        padding: 0;
        background: $black-1;
        position: relative;
        a {
          display: inline-flex;
          width: 100%;
          padding: 17px 20px 17px 50px;
          color: $white;
        }
        &::before {
          position: absolute;
          @include icon;
          top: 20px;
          left: 17px;
          color: color(secondary, light);
          transition: $main-transition-time;
        }
        &::after{
          position: absolute;
          @include icon;
          content:'\0041';
          top: 20px;
          right: 17px;
          color: color(secondary, light);
          transition: $main-transition-time;
        }
      }
      &--promotions {
        &::before {
          content:'\0043';
        }
      }
      &--loyalty {
        &::before {
          content:'\0052';
        }
      }
    }
    .detailsSummary-content {
      padding: 0 20px 0 50px;
      text-transform: uppercase;
    }
    .details-expanded {
      .settings-menu {
        width: 100%;
        color: color(primary, main);
        background: color(secondary, dark);

        .sub-menu {
          border-top: 1px solid color(secondary, light);

          .active {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 5px;
              background: color(accent, primary);
            }
          }
          a {
            position: relative;
            display: inline-flex;
            width: 100%;
            padding: 12px 35px 12px 20px;
            &::after {
              position: absolute;
              @include icon;
              content:'\0041';
              top: 15px;
              right: 17px;
              color: color(accent, primary);
              transition: $main-transition-time;
            }
          }
        }
      }
    }
  }
  .aside-menu {
    &__item {
      padding: 0;
      background: color(primary, main);

      .details-root > .detailsSummary-root {
        position: relative;
        padding-top: 17px;
        padding-bottom: 17px;
        align-items: baseline;

        &::before {
          @include icon;
          left: 17px;
          font-size: 20px;
        }
        &::after {
          @include icon;
          content:'\0041';
          transform: rotate(90deg);
          right: 17px;
          color: color(secondary, light);
          transition: $main-transition-time;
        }
      }
      .details-root.details-expanded > .detailsSummary-root {
        &::after {
          transform: rotate(-90deg);
        }
      }
      &--menuAsideTransactions {
        .details-root > .detailsSummary-root::before {
          content:'\0064';
        }
      }
      &--menuAsideCampaigns {
        .details-root > .detailsSummary-root::before {
          content:'\0043';
        }
      }
      &--menuAsideLimits {
        .details-root > .detailsSummary-root::before {
          content:'\0058';
        }
      }
      &--menuAsideProfile {
        .details-root > .detailsSummary-root::before {
          content:'\0065';
        }
      }
      &--menuAsideBank {
        .details-root > .detailsSummary-root::before {
          content:'\0042';
        }
      }
      &--menuAsideSettings {
        .details-root > .detailsSummary-root::before {
          content:'\005a';
        }
      }
      &--menuAsideHelpAndInfo {
        .details-root > .detailsSummary-root::before {
          content:'\0046';
        }
      }
    }
  }
  & &__info {
    padding-top: 25px;

    & > div {
      padding: 16px 0;
      & > div {
        width: 100%;
      }
    }
  }
  &__timer {
    display: flex;
    span {
      margin-right: 5px;
    }
  }
  &__logout {
    width: 100%;
    display: flex;
    justify-content: center;

    &--btn {
      position: relative;
      width: calc(100% - 90px);
      margin: 15px;
      text-transform: uppercase;
      border-radius: 4px;
      padding: 10px 0;

      .button-label {
        width: auto;
        padding-right: 12px;
        font-size: 18px;
        font-weight: 600;

        &::after {
          @include icon;
          position: relative;
          content: '\0051';
          right: -10px;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}
