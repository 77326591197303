.header {
  opacity: 1;
  background: color(background, header);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1001;

  &__menu {
    display: none;

    @include media-md {
      display: block;
      padding: 5px;
      border-radius: 10px;
      min-height: initial;
      font-size: 0;
      line-height: initial;
      background-color: $violet;

      &::after {
        display: none;
      }
      &::before {
        @include svg-icon(custom);
        content:'\0053';
        font-size: 24px;
        color: $gray-5;
      }
    }
  }
  &-submenu {
    background: #606060;

    .settings-menu {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 10px;

      a {
        margin-left: 50px;
        font-size: 18px;
        color: $white;
        text-decoration: none;
      }
    }
  }
  &__right-block {
    display: flex;
    align-items: center;

    @include media-mobile {
      width: 100%;
    }
  }

  &__group {
    &-top {
      display: flex;
      justify-content: flex-end;
      margin-right: 25px;

      @include media-mobile {
        display: none;
      }
      @include media-md {
        display: none;
      }

      .settings-menu {
        display: flex;
        justify-content: flex-end;
        margin-top: 0;
      }
      a,
      .select-locale {
        margin-left: 45px;
        color: $white;
        font-size: 16px;
        letter-spacing: 0.45px;
        text-decoration: none;
        transition: $main-transition-time;

        &:hover {
          opacity: .7;
        }
      }
      .select-locale {
        position: relative;
        padding-right: 25px;
      }
      .select-select {
        &::after {
          @include svg-icon(custom);
          content: '\0041';
          position: absolute;
          right: -22px;
          top: 6px;
          font-size: 13px;
          color: $white;
          transform: rotate(90deg);
          z-index: 100;
        }
      }
    }
    &-main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      width: 100%;

      @media (max-width: 1024px) {
        margin-top: 0;
        padding-bottom: 0;
      }
      @include media-mobile {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    &-bottom {
      width: 100%;

      @include media-mobile {
        display: none;
      }
    }
  }
  &__account {
    &-info {
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      max-height: 0;
      overflow: hidden;
      background: $white;

      .account-info {
        position: relative;
        padding: 20px 15px;
        opacity: 0;
        transition: all 0.5s ease-in-out;

        &::before {
          content: '';
          position: absolute;
          top: -14px;
          right: 90px;
          border: 8px solid transparent;
          border-bottom: 8px solid $white;
        }
      }
      &.open {
        position: relative;
        max-height: 700px;
        overflow: initial;

        .account-info {
          opacity: 1;
          padding: 20px 15px;
        }
      }
      .container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__container {
    display: flex;
    height: auto;
    margin: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @include media-md {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__logo {
    display: block;
    width: 512px;
    @media (max-width: 1620px) {
      width: 350px;
    }
    @include media-md {
      margin-left: 25px;
      margin-right: auto;
      width: 200px;
    }
    @include media-mobile {
      display: none;
    }
    img {
      display: block;
      width: 100%;
      height: 70px;
      @media (max-width: 1620px) {
        height: 47.84px;
      }
      @include media-md {
        height: 27.34px;
      }
    }
    &--mobile {
      display: none;
      margin-left: 10px;
      margin-right: auto;

      img {
        width: 36px;
        padding-top: 5px;
      }
      @include media-mobile {
        display: block;
      }
    }
    &--custom {
      display: none;
      margin-left: 10px;
      margin-right: auto;

      img {
        width: 110px;
        padding-top: 5px;
      }
      @include media-mobile {
        display: block;
      }
    }
  }
  &__deposit,
  &__balance {
    margin-left: 9px;
    text-transform: uppercase;
    letter-spacing: -0.58px;
  }
  &__deposit {
    &::before {
      @include svg-icon(custom);
      content: '\006a';
      font-size: 23px;

      @include media-lg {
        font-size: 20px;
      }
      @include media-md {
        font-size: 30px;
        padding: 0 5px;
      }
    }
    @include media-md {
      width: initial;
      min-width: initial;
      padding: 0;
      .button-label {
        display: none;
      }
    }
  }
  &__balance {
    &::before {
      @include svg-icon(custom);
      content: '\0065';
      font-size: 23px;

      @include media-lg {
        font-size: 20px;
      }
    }
    &::after {
      @include svg-icon(custom);
      content: '\0041';
      transform: rotate(90deg);
      font-size: 16px;
    }
    @include media-md {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      font-weight: 600;
      color: $white;
      background: none;

      &:hover {
        background: none;
      }
      &::after,
      &::before {
        display: none;
      }
    }
  }
  &__verification {
    position: relative;
    padding: 10px;
    background: color(accent, primary);
    text-align: center;

    @media (max-width: 400px) {
      padding: 0;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        @include svg-icon;
        content: '\0041';
        font-size: 24px;
        color: color(background, post-login-content);
      }
    }
    &-close {
      @include close-button;
      border-radius: 6px;
      background-color: transparent;

      &::before {
        color: $white;
        font-weight: bold;
      }
      &:hover {
        background-color: transparent;
      }
    }
    &-btn {
      color: $white;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.06px;
      text-transform: initial;
      transition: 0.25s;

      @media (max-width: 400px) {
        margin: 0 10px;
        font-size: 14px;
      }
      &:hover {
        opacity: .7;
        background-color: transparent;
      }
    }
  }
  &__group-main__loggedIn {
    display: flex;

    @media (max-width: 1024px) and (min-width: 561px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    button,
    .header__balance {
      margin-left: 10px;

      @include media-md {
        margin-left: 10px;
        font-size: 16px;
        border-radius: 5px;
      }
    }
  }
  &__postLoginMenu {
    width: initial;
    min-width: initial;
    padding: 0;

    & > .button-label {
      display: none;
    }
    &::before {
      @include svg-icon(custom);
      content:'\0065';
      font-size: 33px;
      color: $white;
    }
    @include media-md {
      &:hover {
        background: none;
      }
    }
    @include media-md {
      &::before {
        font-size: 26px;
        padding: 0 5px;
      }
    }
  }
  &__login,
  &__registration,
  .login__button,
  .registration__button {
    text-transform: uppercase;

    @include media-sm {
      min-width: initial;
      font-size: 14px;
    }
  }
  .group-root--buttons {
    display: flex;
    align-items: center;

    @include media-mobile {
      margin: 0;
    }
    button + a {
      margin-left: 9px;
    }
  }
  .buttons-container {
    width: initial;

  }

  @include media-xs {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2001;
  }
  &--modal-open {
    z-index: 1;
  }
}
