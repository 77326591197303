.guide {
  &--button {
    margin: 20px 0;
    a {
      color: white;
    }
  }
  .static-page__content {
    max-width: 100%;
    margin: 0 0 0 var(--lg-resize);
    @media (max-width: 1283px) {
      margin: 0;
    }
  }
  .guide--banner {
    img {
      max-width: 100% ;
    }
  }
}
