.validation-form {
  &--step2 {
    .content {
      p {
        margin: 0;
      }
    }
  }
  &.fields-container {
    > .buttons-container {
      padding-top: var(--lg-resize);
    }
    .validation-form__approved {
      padding-top: 0;
    }
  }
  .fields-container {
  }
  .gridItem-root {
    &--upload {
      flex: 1 1 50% !important;
      min-width: calc(300px + var(--md-resize) * 2);
      max-width: calc(440px + var(--md-resize) * 2);
      padding-top: var(--lg-resize) !important;

      @include media-mobile {
        min-width: 100%;
      }
      .buttons-container {
        @include flexGap($columns: 2);
        @include buttons-container;
      }
    }
  }
  &__images + .gridItem-root, .gridItem-root--image + .gridItem-root {
    padding-top: var(--lg-resize) !important;
  }
  &__notification {
    margin-top: 20px;
    margin-bottom: -10px;
  }
  &__image-field {
    &--secondary {
      .snapshotField-button {
        background: color(secondary, main);
        &:hover {
          background-color: darken(color(secondary, main), 8%);
        }
      }
    }
    > .imageField-helper {
      text-align: center;
    }
  }
  &__file-content {
    @include media-sm {
      max-width: 310px;
    }
  }
  &__button {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  &__document-reset {
    .button-default {
      font-weight: $font-weight-base;
    }
  }
  &__confirmation-text {
    text-align: left;
  }
  &__images {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;

    .validation-form__ratio-container {
      border: 1px dashed $black-1;
    }
    .validation-form__ratio-content {
      top: var(--sm-resize);
      left: var(--sm-resize);
      bottom: var(--sm-resize);
      right: var(--sm-resize);
    }
  }
  &__image-stacker {
    flex: 1;
    height: 100%;
    perspective: 900px;
  }
  &__image-stacker-item {
    z-index: 1;
    background-color: #eee;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s;
    transform-origin: bottom;

    &:hover {
      & ~ .validation-form__image-stacker-item {
        transform: rotateX(-60deg);
      }
    }
  }
  &__image-item {
    &:not(.galleryItem-clone) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none !important;
    }
    &.galleryItem-clone {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__max-file-count {
    color: #c5c5c5;
  }
  .fileField {
    &-root {
      min-width: initial;
    }
    &-button {
      @include button-root;
      @include button-secondary;

      p {
        line-height: 1;
        margin: 0;
      }
    }
  }
  .imageField {
    &-root {
      width: 100%;
      align-items: center;
    }
    &-selected {
      .imageField-imageWrapper {
        position: relative;

        &::before {
          @include svg-icon;
          content: '\0043';
          position: absolute;
          top: 20px;
          left: 20px;
          font-size: 20px;
          color: $white;
        }
      }
    }
    &-image {
      width: 100%;
    }
    &-buttons {
      @include flexGap($columns: 2);
      @include buttons-container;
    }
  }
  .galleryItem-delete {
    background: #fffc;
    color: $black;
    z-index: 1;
    right: 8px;
    top: 8px;
    position: absolute;
    animation: none;

    span:empty {
      display: none;
    }
    &:hover {
      background: #000c;
      color: $white;
    }
  }
  .snapshotField-root {
    width: initial;
    flex: 1;
  }
  .snapshotField-button {
    @include button-root;
    @include button-secondary;

    > span::before {
      display: none;
    }
  }
  .content {
    ul ul {
      list-style: none;
    }
  }
  &__ratio-container {
    position: relative;
    width: 100%;
    padding-top: 65%;
  }
  &__ratio-content {
    position:  absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .link--row + .link--row {
    margin-top: 1px;
  }
}
