.register-via-third-party {
  .user-identification {
    text-align: center;
    &__form-fields {
      text-align: left;
    }
  }
  &__ask-to-login,
  &__success {
    text-align: center;
  }
  &__logging-in {
    text-align: center;
    &-text {
      margin-top: 10px;
    }
  }
}
