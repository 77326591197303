.front-page-content {
  &--bottom-indent {
    margin-bottom: 60px;
    @media (max-width: $size-xs) {
      margin-bottom: 30px;
    }
  }
  &--top-indent {
    margin-top: 60px;
    @media (max-width: $size-xs) {
      margin-top: 30px;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    @media (max-width: $size-sm) {
      margin-top: 17px;
    }

    @include media-xs {
      .home__games-filter__name {
        max-width: 90%;
      }
    }

    @media (min-width: $size-md) {
      height: 61px;
      margin-bottom: 0;
    }
    .home__games-filter__name {
      margin-top: 114px;

      @include media-ml {
        margin-top: 0;
      }
      @include media-md {
        margin-top: 17px;
      }
    }
    @include media-ml {
      margin-top: 0;
    }
  }
  &__providers {
    background-color: $black-6;
    margin-top: 60px;
    @media (max-width: $size-xs) {
      margin-top: 30px;
    }
  }
}

.exclusive-banner {
  background-color: $black-1;
  min-height: 190px;
  margin: 60px 0 0 0;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  display: flex;

  @media (max-width: 749px){
    margin: 30px -15px 0px -15px;
    border-radius: 0px;
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    z-index: 1;
  }
  &__content {
    position: absolute;
    top: 70px;
    left: 60px;
    bottom: 40px;
    @media (max-width: $size-lg) {
      top: 50px;
      left: 50px;
    }
    @media (max-width: 1140px) {
      top: 40px;
      left: 40px;
    }
    @media (max-width: 749px) {
      top: 20px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      text-align: center;
    }
  }
  &__title {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 65px;
    font-weight: 800;
    max-width: 60%;
    line-height: 65px;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.2);

    @media (max-width: 1640px) {
      font-size: 50px;
      line-height: 1.05;
    }

    @media (max-width: $size-lg) {
      font-size: 40px;
    }

    @media (max-width: $size-ml) {
      max-width: 80%;
      font-size: 35px;
    }
    @media (max-width: 1140px) {
      font-size: 30px;
    }
    @media (max-width: 749px) {
      font-size: 50px;
      max-width: 100%;
    }
    @media (max-width: 580px) {
      font-size: 35px;
    }
    @media (max-width: 430px) {
      font-size: 32px;
    }
  }
  &__link {
    text-transform: uppercase;
    left: 0;
    bottom: 0;
    min-width: 240px;
    height: 54px;
    @media (max-width: 1640px) {
      min-width: 200px;
      height: 40px;
    }
    @media (max-width: 1140px) {
      height: 30px;
    }
    @media (max-width: 749px) {
      position: static;
      color: color(button--secondary, text);
      background: rgb(84,255,0);
      background: linear-gradient(180deg, rgba(84,255,0,1) 0%, rgba(0,165,0,1) 100%);
    }

    @include media-mobile {
      min-width: 160px;
      border-radius: 5px;
    }
  }
}

.footer-acquisition-banner {
  margin-top: 60px;
  @media (max-width: $size-xs) {
    margin-top: 30px;
  }
  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    z-index: 1;
    margin-bottom: -5vw;

    @include media-mobile {
      margin-bottom: -11vw;
      padding-top: 40px;
    }
  }
  &__content {

    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/cms/img/banners/footer-acquisition-banner-bg-desktop.jpg') no-repeat bottom;
    z-index: 10;
    @media (max-width: 920px) {
      width: 100%;
    }
    @media (max-width: 749px) {
      flex-direction: row-reverse;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: none;
    }
    @include media-mobile {
      position: relative;
    }
  }
  &__content-body {
    @include media-mobile {
      position: absolute;
      right: 15px;
      bottom: 35px;
      z-index: 2;
      width: 160px;

      a {
        min-width: 150px;
        position: relative;
      }
    }
  }
  &__title {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 65px;
    font-weight: 800;
    max-width: 400px;
    text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.2);

    @media (max-width: $size-lg) {
      font-size: 50px;
    }
    @media (max-width: $size-ml) {
      font-size: 35px;
    }
    @media (max-width: $size-md) {
      font-size: 30px;
    }
    @media (max-width: 920px) {
      font-size: 24px;
    }
    @media (max-width: 749px) {
      font-size: 50px;
    }
    @media (max-width: 575px) {
      font-size: 40px;
    }
    @media (max-width: $size-xs) {
      font-size: 30px;
    }
  }
  &__link {
    text-transform: uppercase;
  }
  &__tiger {
    width: 30vw;
    //margin-bottom: -10vh;

    @media (max-width: 749px) {
      transform: scale(-1, 1);
      width: 72vw;
      margin-bottom: -85px;
      margin-left: -140px;
    }
  }
}


.home-page-promotions {
  @media (max-width: 767px) {
    background-color: $black-5;
    margin-top: -5px;
    padding-top: 30px;
  }

  &--mobile {
    .home-page-promotions__desktop-title {
      @media (max-width: $size-md) and (orientation:landscape) {
        display: none;
      }
    }
    .home-page-promotions__items {
      @media (max-width: $size-md) and (orientation:landscape) {
        margin-top: 60px;
      }
    }
  }

  &__desktop-title {
    display: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      display: block;
    }
  }
  &__items {
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__item {
    flex: 1 1 auto;
    position: relative;
    min-height: 15vw;
    @media (min-width: $size-xl) {
      min-height: 280px;
    }
    img {
      max-width: 100%;
      border-radius: 15px;
      display: block;
    }
    @media (max-width: 767px) {
      &:last-child{
        margin-bottom: 20px;
      }
    }
  }
  &__img-text {
    position: absolute;
    top: 20px;
    left: 10px;
    width: 50%;
    @media (min-width: 768px) {
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      max-width: 380px;
    }
    @media (max-width: 450px) {
      top: 10px;
    }
  }
  &__mobile-title {
    display: none;
    @media (max-width: 767px) {
      display: block;
      color: $white;
      font-size: 18px;
      text-transform: uppercase;
    }
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }
  &__bonus-text {
    font-weight: 800;
    font-size: 4vw;

    @media (min-width: 1640px) {
      line-height: 75px;
      font-size: 80px;
    }
    @media (max-width: 767px) {
      font-size: 8vw;
    }
    &--blue {
      color: transparent;
      background: linear-gradient(to right, #28FFBE, #0085FF);
      background-clip: text;
      -webkit-background-clip: text;
    }
    &--yellow {
      color: transparent;
      background: linear-gradient(to right, #FFFFFF, #FFB528);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
}
