@mixin buttons-container () {
  @include media-mobile{
    & > div.notification-root, & > button, & > a[role="button"] {
      flex: 1 0 auto;
      max-width: initial;
    }
  }
}
.fields-container {
  @include flexGap;

  &--lg {
    @include flexGap($row: var(--lg-resize));
  }
  &--xl {
    @include flexGap($row: var(--xl-resize));
  }

  &:empty, .grid-root:empty, .gridItem-root:empty {
    display: none !important;
  }
  > .grid-root, > .gridItem-root  {
    &--buttons {
      padding-top: var(--lg-resize) !important;
    }
    &--buttons:not(.buttons-container--full) {
      @include flexGap($columns: auto);
      @include buttons-container;
    }
      &--title + .gridItem-root {
      padding-top: var(--xl-resize);
    }
  }
  &--form {
    > .grid-root, > .gridItem-root  {
      &--buttons {
        padding-top: var(--xl-resize) !important;
      }
      &--buttons:not(.buttons-container--full) {
        @include flexGap($columns: 2);
      }
    }
  }
}

.buttons-container {
  @include flexGap($columns: auto);
  @include buttons-container;

  &--form {
    @include flexGap($columns: 2);
  }
  &--full {
    > div, > button, > a[role="button"] {
      flex: 1 0 auto;
    }
  }
}

.limit-form__item .limit-amount {
  max-width: initial;
}

.field-input,
:not(.select-locale) > .field-select,
.select-root,
.field-string,
.field-phoneNumberField {
  width: 100%;
  max-width: 100%;

  label,
  legend {
    &[data-shrink="false"] {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;

      & + div {
        > .input-input--type--date,
        > .dateInput-root,
        .dateInput-input {
          color: transparent;

          &::-webkit-input-placeholder {
            color: transparent;
          }
          &::-moz-placeholder {
            color: transparent;
          }
        }
      }
    }
  }
  &.field-disabled {
    label,
    legend {
      color: color(label, disabled);

      & + div,
      & + .phone-number-wrapper > div {
        &::before {
          background: color(field--primary, background--disabled) !important;
        }
        .select-root .select-icon {
          fill: color(field--primary, text--disabled) !important;
        }
      }

    }
    .input-disabled,
    .input-disabled:hover {
      &::before {
        background: color(field--primary, background--disabled);
      }
    }
  }
  .input-multiline {
    padding: 0;
  }
}
.field-root {
  .field-helper {
    margin-top: 0;

    &Hint {
      color: color(field--primary, hint__text);
    }
    &Error {
      color: color(error);
    }
  }
}
.input-focused:not(.input-error) + .field-helperHint {
  color: color(field--primary, hint__text);
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"]:not(.dateInput-input),
.input-input--type--date,
.dateInput-root {
  @include isMozillaFirefox {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.input-before {
  color: color(field--primary, text--disabled);
}
.dateInput-root { // Safari
  -webkit-appearance: initial;
}
textarea.input-inputMultiline {
  height: 100%;
}
.field-input,
.field-selectWithPlaceholder,
:not(.select-locale) > .field-root.field-select {
  .input-underline,
  label + div,
  .phone-number-wrapper > div,
  > div {
    &::before,
    &::after {
      height: 0;
      background: color(field--primary, background--disabled);
    }
    &:hover {
      &::before {
        height: 0;
        background: color(field--primary, background--disabled);
      }
    }
  }
  .input-focused,
  .select-focused,
  label + div:focus {
    &::after {
      height: 0;
      background: color(primary, main);
      transform: scaleX(1);
    }
  }
}
:not(.select-locale) > .field-error {
  .input-error,
  &.field-select label + div,
  &.field-select .select-focused,
  .phone-number-wrapper > div {
    &::after {
      height: 0;
      background: color(error);
      transform: scaleX(1);
    }
  }
}
input {
  outline: none;
}

/* Remove background from autofilled inputs in Chrome */
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 100px color(field--primary, background--normal) !important;
  -webkit-text-fill-color: color(field--primary, text--normal);
  filter: none;
}
/** */

input,
.dateInput-input {
  &::-moz-placeholder {
    color: color(field--primary, text--normal);
  }
  &::-webkit-input-placeholder {
    color: color(field--primary, text--normal);
  }
}
input:not(.dateInput-input) {
  &::-moz-placeholder {
    font-size: 16px;
    line-height: 40px;
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 40px;
    opacity: 1;
  }
}

/* Select */

.select-root {
  .select-selectMenu {
    padding-right: 32px;
  }
  .select-icon {
    top: 6px;
    right: 0;
    fill: color(field--primary, text--normal);
  }
}
.select-select:focus {
  background: color(field--primary, background--normal);
}

/* Checkbox */

.field-checkbox {
  margin-top: 15px;

  label {
    margin-right: 0;
    margin-bottom: 5px;
    margin-left: 0;
    align-items: flex-start;
  }
  .checkbox-default,
  .checkbox-checked {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 0;
    border: 2px solid color(field--primary, border--normal);
    background: transparent;

    &::before {
      @include svg-icon;
      content: '\0075';
      margin-left: 2px;
      color: transparent;
      font-size: 13px;
      font-weight: $font-weight-alt;
    }
    svg {
      display: none;
    }
    + span {
      color: $black-1;
    }
  }
}

/* Phone number */

.field-phoneNumberField {
  .phone-number-wrapper {
    display: flex;

    .input-root {
      width: 100%;
      margin-top: 0;
      margin-left: 5px;
      margin-bottom: 0;
    }
    .select-select {
      display: flex;
      align-items: center;

      .group-root {
        display: flex;
        align-items: center;

        img {
          width: 26px;
          margin-right: 5px;
        }
      }
    }
  }
}
#menu-phoneNumber_code {
  .selectItem-root {
    .phone-number__code {
      padding-right: 5px;
      color: color(primary, dark);
    }
    img {
      width: 26px;
      margin-right: 10px;
    }
  }
}

/* Button group */

.field-inputValues {
  label {
    top: auto;

    &[data-shrink='false'] {
      bottom: 57px;
    }
  }
  .input-valuesLabel {
    font-size: 16px;
  }
  .input-values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-gap: 10px;
    margin: 0;
    transition: all 0.3s;

    & + .input-root {
      margin: var(--sm) 0 0 0;
    }

    .input-value {
      flex: initial;
      white-space: nowrap;

      button {
        width: 100%;
        min-width: initial;
        padding: 0 5px;
        border-radius: 0;
        border: 1px solid color(field--primary, border--normal);
        background: color(field--primary, background--normal);

        &:hover {
          border: 2px solid $green-1;
          background: color(field--primary, background--normal);
        }
        & > span + span {
          height: 0;
        }
      }
      &.input-selectedValue {
        z-index: 5;
        border-color: color(primary, main);

        button {
          border: 2px solid $green-1;
          background: color(field--primary, background--normal);
        }
      }
    }
  }
}

/* Radio group */

.field-radioGroup {
  display: flex;

  legend {
    color: color(label, filled);
  }
}
.radioGroup-root {
  flex-direction: initial;
  padding: 1px 0 0 1px;

  label {
    flex: 1;
    min-width: 33.33333%;
    margin: -1px 0 0 -1px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 5px;
    font-size: 12px;
    line-height: 1;
  }
  .formControlLabel-label {
    border: 1px solid color(field--primary, border--normal);
    border-radius: 0;
    text-align: center;
    color: color(field--primary, text--normal);
    background: transparent;
  }
  .radio-checked,
  .radio-default {
    display: none;
  }
}
.radio-checked + .formControlLabel-label {
  border-color: color(primary, main);
  z-index: 2;
}

/* Radio list */

.radio-list {
  display: block;

  span {
    width: auto;
    padding: 0;
  }
  .formControlLabel-root {
    display: block;
    min-width: 0;
    float: left;
    clear: both;
    text-align: left;
  }
  .radio-default {
    display: inline-flex;
    vertical-align: top;

    svg {
      width: 20px;
      height: 20px;
      fill: color(primary, main);
    }
  }
  .formControlLabel-label {
    display: inline-flex;
    max-width: calc(100% - 20px);
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    border: 0;
  }
  label + label {
    margin-left: 0;
  }
}

@mixin field__label {
  .field-label, legend {
    @include field-font__h4;
    position: initial;
    display: grid;
    grid-gap: 10px;
    margin-bottom: var(--sm);
    color: color(primary, main);
    transition: initial;
    transform: initial;
    letter-spacing: 0.45px;
    text-align: start;

    &:empty {
      display: none;
    }

    .MuiTabs-flexContainer {
      min-height: 40px;
      padding: 3px;
      border-radius: 12px;
      background: $gray-3;

      & > div {
        height: 100%;
      }

      & > div > div > div {
        height: 100%;
        justify-content: space-around;

        & + div {
          height: 100%;
          border-radius: 10px;
          background-color: $white;
        }
      }

      button {
        flex: 1;
        min-width: initial;
        height: initial;
        opacity: 1;
        text-transform: initial;
        pointer-events: painted;
        transition: $main-transition-time;
        z-index: 10;

        span {
          @include field-font__h4;
          font-weight: 700;
        }
      }

      &--deposit {
        background: none;
        padding: 0;
        border-radius: 0;

        & > div > div > div {
          gap: 8px;
        }

        button {
          border: 1px solid $gray-2;
          text-transform: uppercase;
          min-width: 120px;

          @include media-sm {
            min-width: 100px;
          }

          &[aria-selected='true'] {
            color: #fff;
            background: $orange-1;
            border: 1px solid $orange-1;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@keyframes show {
  from {
    height: 0;
    min-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin field__hint {
  .field-helper {
    @include field-font__h4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
    padding: 5px 12px;
    font-style: italic;
    color: color(field--primary, hint__text);
    background: color(field--primary, hint__background);
    transition: height, min-height $main-transition-time;

    &:not(:empty) {
      animation: show $main-transition-time;
    }
    &:empty {
      height: 0;
      min-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  & p.field-helperError {
    min-height: initial;
    padding: 5px 0;
    color: color(field--primary, hint__text--error);
    background: color(field--primary, hint__background--error);
    font-style: initial;
  }
}

@mixin field-input-style-generator ($fieldType: 'primary', $stateType: 'normal') {
  .input-root {
    border-color: color(field--#{$fieldType}, border--#{$stateType});
    background: color(field--#{$fieldType}, background--#{$stateType});
  }
  .input-input  {
    color: color(field--#{$fieldType}, text--#{$stateType});

    &::placeholder {
      font-family: $font-primary;
      font-style: italic;
    }
  }
  .input-before, .input-after {
    color: color(field--#{$fieldType}, text--#{$stateType});
  }
  .input-after {
    padding: 0 10px 0 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:first-line {
    @include field-font__h4;

    font-weight: 700;
    -webkit-box-shadow: inset 0 0 0 100px color(field--#{$fieldType}, background--#{$stateType}) !important;
    -webkit-text-fill-color: color(field--#{$fieldType}, text--#{$stateType});
    -webkit-background-clip: text !important;
  }
}
@mixin input--field-style ($type: 'primary') {
  @include field__hint;
  @include field__label;

  width: 100%;
  height: max-content;


  .input-root {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 0 18px;
    border: 1px solid;

    > button {
      margin-right: 5px;
    }
    > button:last-child, &::after {
      margin-right: 10px;
    }

    > button,
    button.button-primary,
    button.button-secondary,
    button.button-default,
    {
      @include field-font__h5;

      min-height: initial;
      padding: 0;
      font-weight: 700;
      background: transparent;

      @if $type == 'primary' {
        color: color(link--secondary, text);
        &:hover {
          color: color(link--secondary, text--hover);
        }
      } @else {
        color: color(link--#{$type}, text);
        &:hover {
          color: color(link--#{$type}, text--hover);
        }
      }
      &.button-disabled {
        color: color(field--#{$type}, text--disabled);

        &:hover {
          color: color(field--#{$type}, text--disabled);
        }
      }
      transition: $main-transition-time;
    }
  }
  .input-input, .input-before, .input-after {
    @include field-font__h4;
    font-weight: 700;
    height: initial;
  }
  .input-input, .input-before, .input-after {
    border: initial;
    background: initial;

    &::placeholder {
      @include field-font__h4;
      opacity: 1;
    }
  }
  .input-input {
    min-height: 38px;
    line-height: 38px;
    padding: 0 10px 0 0;
    max-width: 100%;
    box-shadow: none;
    text-overflow: ellipsis;

    @include media-lg {
      min-height: 35px;
      line-height: 35px;
    }
    &::-webkit-date-and-time-value {
      text-align: left;
    }
  }
  textarea.input-input {
    padding-top: 8px;
    padding-bottom: 8px;
  }
 .input-value {
   button span {
     @include field-font__h4;
     font-weight: bold;
   }
 }
  //normal
  &.field-empty {
    @include field-input-style-generator($type, 'normal');
    .input-before, .input-after {
      display: none;
    }
    input {
      font-weight: 400;
      font-style: italic;
      font-family: $font-primary;
    }
  }
  // filled
  @include field-input-style-generator($type, 'filled');
  // error
  &.field-error {
    @include field-input-style-generator($type, 'error');
  }
  // disabled
  &.field-disabled {
    @include field-input-style-generator($type, 'disabled');

    .adornment-password {
      pointer-events: none;

      svg {
        fill: $gray-2;
      }
    }
    input[type='password'] + .adornment-password,
    input[type='text'] + .adornment-password {
      color: color(field--primary, text--disabled);
    }
  }
  // success
  &.adornment-success.field-filled:not(.field-required), &.adornment-success.field-success  {
    &:not(.field-disabled) {
      @include field-input-style-generator($type, 'success');
    }

    .input-root:not(.input-multiline) {
      &:after {
        @include svg-icon(custom);
        content: '\0044';
        position: initial;
        height: auto;
        transform: scaleX(1);
        font-size: $field-font__h4;
        color: color(field--#{$type}, border--success);
        background-color: initial;
      }
    }
  }

  // hide arrows input type number
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  // adornment-password
  .adornment-password {
    @include field-font__h5;

    position: initial;
    width: initial;
    height: initial;
    font-weight: 700;
    transition: $main-transition-time;

    svg {
      fill: $green-1;
    }
    & > span {
      & + span {
        width: 0;
      }
      & > span {
        width: initial;
        height: initial;
      }
    }
  }
  input[type='password'] + .adornment-password {
    color: color(link--secondary, text);

    &:hover {
      color: color(link--secondary, text--hover);
    }
  }
  input[type='text'] + .adornment-password {
    color: color(link--primary, text);

    &:hover {
      color: color(link--primary, text--hover);
    }
  }
}



@mixin field-select-style-generator ($fieldType: 'primary', $stateType: 'normal') {
  div.select-select, select {
    border-color: color(field--#{$fieldType}, border--#{$stateType});
    color: color(field--#{$fieldType}, text--#{$stateType});
    background: color(field--#{$fieldType}, background--#{$stateType});
  }
}
@mixin select--field-style {
  @include field__hint;
  @include field__label;

  width: 100%;

  :not(.select-locale) > &.field-select label + div, div.phone-number-wrapper {
    margin: 0;
  }
  // root and filled
  .select-selectMenu {
    height: 40px;
  }
  div.select-select, select {
    @include field-font__h4;
    padding: 0 50px 0 18px;
    line-height: 38px;
    font-weight: 700;
    box-shadow: none;

    border: 1px solid color(field--primary, border--filled);
    border-radius: 0;
    color: color(field--primary, text--filled);
    background: color(field--primary, background--filled);
  }
  option {
    background: color(field--primary, background--normal);
  }

  // customize arrow select icon
  .select-select, .select-wrapper {
    &:after {
      pointer-events: none;
      @include svg-icon(custom);
      content: '\0041';
      box-sizing: border-box;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 2px;
      bottom: 0;
      width: 38px;
      height: 40px;
      font-size: $field-font__h4;
      color: color(field--primary, border--normal);
      border-bottom: 1px solid color(field--primary, border--normal);
      transform: rotate(90deg);
    }
  }
  .select-icon {
    display: none;
  }


  //normal
  &.field-empty {
    @include field-select-style-generator('primary', 'normal');

    div.select-select, select {
      font-weight: 400;
      font-style: italic;
    }
  }
  // filled
  @include field-select-style-generator('primary', 'filled');
  // error
  &.field-error {
    @include field-select-style-generator('primary', 'error');
  }
  // disabled
  &.field-disabled {
    @include field-select-style-generator('primary', 'disabled');
  }
  // success
  &.adornment-success.field-filled:not(.field-error) {
    &:not(.field-disabled) {
      @include field-select-style-generator('primary', 'success');
    }
  }
}

@mixin checkbox--field-style {
  @include field__label;
  @include field__hint;

  display: grid;
  margin: 0;

  & > label {
    align-items: baseline;
    margin: 0;
  }
  .checkbox-default, .checkbox-checked {
    margin-right: 10px;
    border: 1px solid #707070;
    border-radius: 3px;
    transition: $main-transition-time;

    &:after {
      @include svg-icon(custom);
      content: '\0044';
      position: absolute;
      font-size: 13px;
      color: color(background, post-login-content);
      opacity: 0;
      transition: $main-transition-time;
    }
  }
  .checkbox-default + span, .checkbox-checked + span {
    @include field-font__h4;
  }
  .checkbox-checked {
    background: color(accent, secondary);

    &:after {
      opacity: 1;
    }
  }
  // disabled
  &.field-disabled {
    .checkbox-default {
      background: $gray-3;
    }
    .checkbox-checked {
      background: $gray-2;
    }
  }
  .fpLink {
    font-weight: 700;
    text-decoration: none;
    color: color(accent, secondary);

    &:hover {
      text-decoration: underline;
    }
  }
}

.field-primary {
  &.field-input {
    @include input--field-style;
  }
  &.field-select, &.field-selectWithPlaceholder, &.field-inputDateSelect, &.field-phoneNumberField {
    @include select--field-style;
  }
  &.field-checkbox {
    @include checkbox--field-style;
  }
}

.field {
  &-input {
    &--primary {
      @include input--field-style;
    }
    &--dark {
      @include input--field-style('dark');
    }
  }
  &-select {
    &--primary {
      @include select--field-style;
    }
  }
  &-checkbox {
    &--primary {
      @include checkbox--field-style;
    }
  }
  &-radio {
    &--primary {
      @include field__label;
      @include field__hint;

      .radioGroup-root {
        @include flexGap(var(--sm), $columns: auto);

        min-height: 40px;
        padding: 6px 0;

        &.radio-list {
          .formControlLabel {
            &-root {
              pointer-events: initial;
              display: flex;
              flex: initial;
              margin: 0;


              &::before, .radio-checked::before {
                content: '';
                box-sizing: border-box;
                position: absolute;
                border-radius: 100%;

              }
              &::before {
                width: 20px;
                height: 20px;
                border: 1px solid $black-1;
              }
              .radio-checked {
                &::before {
                  left: 4px;
                  width: 12px;
                  height: 12px;
                  background: $green-1;
                }

                &.radio-disabled::before {
                  background: $gray-2;
                }
              }
              svg {
                opacity: 0;
              }
            }
            &-label {
              @include field-font__h4;

              padding-left: 7px;
            }
          }
          span {
            height: initial;
          }
        }
      }
      .button-list {
        display: flex;
        justify-content: space-between;
        width: calc(100% + 10px);
        margin: -10px 0 0 -10px;

        & > :nth-child(n) {
          flex: 1;
          margin: 10px 0 0 10px;

          &::after {
            height: 0;
          }
        }
        .button-root {
          width: 100%;
          min-width: initial;
          max-width: 130px;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;

          &.button-secondary{

          }
          &:not(.button-secondary){
            border: 1px solid #c2c2c2;
            color: color(text, dark);
            background: $gray-8;

            &:hover {
              background: #c7c7c7;
            }
          }
        }
      }
    }
  }
}
.styled-field-input {
  &--primary &__label,
  &--secondary  &__label {
    @include field-font__h4;
    margin-bottom: 10px;
  }
  &--primary &__root,
  &--secondary &__root {
    @include field-font__h4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 5px 0 18px;
    font-weight: 700;
  }
  &--primary &__root {
    color: color(field--primary, text--filled);
    border: 1px solid color(field--primary, border--filled);
    background: color(field--primary, background--filled);
  }
  &--secondary &__root {
    color: color(field--primary, text--filled);
  }
}

.select-menuPaper, .select-searchWrapper {
  padding: 10px 0;
  border-radius: 0;
  color: color(field--primary, text--filled);
  background: color(field--primary, background--filled);

  & > ul {
    padding: 0;
  }
  .select-searchWrapper {
    padding: 0 18px;
  }

}
.selectItem-root {
  @include field-font__h4;
  padding: 5px;
  min-height: 24px;
  height: initial;

  & > span:last-child {
    display: none;
  }
}
.select-search {
  input {
    @include field-font__h4;
    border-color: color(field--primary, border--normal);
    color: color(field--primary, text--normal);
    background: color(field--primary, background--normal);
    border-radius: 0;
  }
}
.select-searchInput {
  &:before, &:after {
    display: none;
  }
  .select-searchIcon {
    position: absolute;
  }
  input {
    padding-left: 50px;
  }
}

// Date
.field-input--date {
  @include input--field-style;
  display: inline-flex;

  .input-root {
    padding: 0;
  }
  .input-input--type--date {
    -webkit-appearance: initial;
    position: relative;
    padding: 0 20px;
    border-color: $gray-3;
    color: $gray-1;
    background: $gray-4;
    display: flex;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: none;
      cursor: pointer;
    }
  }
  .input-input--type--date::-webkit-datetime-edit {
    height: 38px;
    padding: 0;
  }
  .date-placeholder {
    padding: 0;

    .input-input--type--date {
      &::after {
        pointer-events: none;
        @include svg-icon(custom);
        content: '\0041';
        box-sizing: border-box;
        position: absolute;
        transform: rotate(90deg);
        top: 0;
        right: 2px;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 40px;
        font-size: $field-font__h4;
        border-bottom: 1px solid color(field--primary, border--normal);
        color: $gray-7;

      }
      &::-webkit-datetime-edit {
        opacity: 0;
        pointer-events: none;
      }
      &:before {
        content: attr(placeholder);
        width: 100%;
        font-style: normal;
        font-weight: 700;
        color: $gray-1;
      }
    }
  }
}
input[type='date'] {
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 10px;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-position: right;
  }
}


