.popup-decorator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  &__close-button {
    @include close-button;
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 15px;
    font-size: 35px;
    color: $gray-4;
    background-color: transparent;
    border-radius: 50%;

    &:hover {
      background-color: transparent;
    }
    &::before {
      font-size: 18px;
    }
    .button-label {
      font-size: 0;
    }
  }
}
