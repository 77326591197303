/* Layout */

.static-page {
  &__container {
    display: flex;
    justify-content: center;
    flex: 1;
    padding-top: 40px;
    padding-bottom: 55px;

    @include media-mobile {
      padding: 0;
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__menu {
    min-width: 300px;
    max-width: 300px;

    .settings-menu {
      &__item-override {
        position: relative;
        background: color(background, static-menu-item);
        transition: $main-transition-time;

        a {
          display: inline-flex;
          width: 100%;
          padding: 13px 35px 13px 17px;
          color: $gray-4;
          text-decoration: none;
          text-transform: uppercase;
          transition: $main-transition-time;

          &:hover{
            opacity: .5;
          }
          &::after{
            position: absolute;
            @include svg-icon(custom);
            content:'\0041';
            top: 50%;
            right: 12px;
            color: color(secondary, main);
            transition: $main-transition-time;
            cursor: pointer;
            transform: translateY(-10px);
          }
        }
      }
    }
    &:empty {
      display: none;
    }
  }
  &__info {
    min-width: 300px;
    max-width: 300px;
    @include media-md {
      max-width: 600px;
      width: 100%;
      margin-bottom: 15px;
    }
    &:empty {
      display: none;
    }
  }
  &__content {
    width: 100%;
    max-width: 920px;
    margin: 0 var(--lg-resize);
    padding: 35px var(--lg-resize) var(--md-resize);
    background: color(background, static-content);

    @include media-lg {
      margin: 0 var(--md-resize);
    }
    @include media-md {
      margin: 0;
      order: 1;
    }
    .title {
      @include field-font__h3;
      margin-bottom: 35px;
      padding: 0;
      text-align: initial;

      @include media-mobile {
        margin-bottom: 20px;
      }
    }
  }
  p {
    margin-bottom: var(--sm-resize);
    line-height: 1.4;
  }

  &__tiger {
    width: 320px;
    margin-bottom: -50px;
    margin-top: -31px;

    @include media-sm {
      width: 270px;
    }

    @include media-mobile {
      display: none;
    }
  }
}

/* Menu */

.static-page-menu {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: color(background, static-menu);

  &__item {
    position: sticky;
    position: -webkit-sticky;
    top: 20px;

    .settings-menu__item-override {
      .details-root {
        border: 0;
        background: none;
        box-shadow: none;

        .detailsSummary-root {
          min-height: 0;
          padding: 0;
        }
        .detailsSummary-content {
          margin: 0;
          padding: 12px 30px;
          color: color(primary, dark);

          &::after {
            content: '';
            position: absolute;
            top: 45%;
            right: 20px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #333;
          }
          &:hover {
            font-weight: $font-weight-alt;
          }
        }
        &.details-expanded {
          .detailsSummary-content {
            font-weight: $font-weight-alt;

            &::after {
              transform: rotateX(180deg)
            }
          }
        }
      }
      .detailsSummary-content {
        font-size: 16px;
      }
      > a {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 42px;
        padding: 12px 30px;
        font-size: 16px;
        color: color(primary, dark);
        text-decoration: none;
        transition: color $main-transition-time;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 0;
          height: 100%;
          background-color: color(primary, main);
          transition: width $main-transition-time;
        }
        &:hover,
        &.active {
          color: color(primary, main);

          &::before {
            width: 10px;
          }
        }
      }
      .settings-menu__item-override {
        background: color(static-menu, background);
        border-top: 1px solid color(static-menu, border);

        &:last-of-type {
          border-bottom: 1px solid color(static-menu, border);
        }
      }
    }
  }
}

/* Promotions-static */

.promotions-static {
  text-align: center;
}

/* Other */

#static-page__anchor {
  position: relative;
  top: -75px;
}
