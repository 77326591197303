.unexpected-error {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  background: color(background,body);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 670px;
    margin: 0 auto;
    padding: 15px;
    color: color(primary,main);

    img {
      max-width: 389px;
      margin-bottom: 60px;

      @include media-xs {
        max-width: 220px;
      }
    }
  }
  &__title {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
  &__text {
    margin-bottom: 90px;
    text-align: center;
    font-size: 18px;

    p {
        margin: 0;
    }
  }
}
