.login-via-third-party {
  text-align: center;

  &__loading-text {
    margin-top: 10px;
  }

  .user-identification__form-fields {
    text-align: left;
  }
}
