/* Generated by Glyphter (http://www.glyphter.com) on  Thu Nov 28 2024*/
@font-face {
    font-family: 'Glyphter-custom';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter-custom';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-arrow:before{content:'\0041';}
.icon-balance:before{content:'\0042';}
.icon-bonus:before{content:'\0043';}
.icon-check:before{content:'\0044';}
.icon-close:before{content:'\0045';}
.icon-forgot:before{content:'\0046';}
.icon-fullscreen:before{content:'\0047';}
.icon-fun:before{content:'\0048';}
.icon-goldrun:before{content:'\0049';}
.icon-goldrun-icon:before{content:'\004a';}
.icon-history:before{content:'\004b';}
.icon-info:before{content:'\004c';}
.icon-Jackpot:before{content:'\004d';}
.icon-keno:before{content:'\004e';}
.icon-limits:before{content:'\004f';}
.icon-live-casino:before{content:'\0050';}
.icon-login:before{content:'\0051';}
.icon-loyality:before{content:'\0052';}
.icon-menu:before{content:'\0053';}
.icon-menu-open:before{content:'\0054';}
.icon-messages:before{content:'\0055';}
.icon-new-games:before{content:'\0056';}
.icon-promo:before{content:'\0057';}
.icon-responsible-gaming:before{content:'\0058';}
.icon-search:before{content:'\0059';}
.icon-settings:before{content:'\005a';}
.icon-slots:before{content:'\0061';}
.icon-support:before{content:'\0062';}
.icon-table-game:before{content:'\0063';}
.icon-transactions:before{content:'\0064';}
.icon-user:before{content:'\0065';}
.icon-user-break:before{content:'\0066';}
.icon-user-loggedin:before{content:'\0067';}
.icon-user-signin:before{content:'\0068';}
.icon-user-verify:before{content:'\0069';}
.icon-deposit:before{content:'\006a';}
.icon-desktop:before{content:'\006b';}
.icon-home:before{content:'\006c';}
.icon-horse-racing:before{content:'\006d';}
.icon-play:before{content:'\006e';}
.icon-poker:before{content:'\006f';}
.icon-sport:before{content:'\0070';}
.icon-18plus:before{content:'\0071';}
.icon-game-rules:before{content:'\0072';}
.icon-news:before{content:'\0073';}
.icon-withdraw:before{content:'\0074';}
.icon-withdraw2:before{content:'\0075';}
.icon-classic-slots:before{content:'\0076';}
.icon-megaways:before{content:'\0077';}
.icon-bingo:before{content:'\0078';}
.icon-home:before{content:'\0079';}
.icon-deposit-plus:before{content:'\007a';}
.icon-withdraw-paper:before{content:'\0030';}
.icon-aml-policy:before{content:'\0031';}
.icon-fullscreen-exit:before{content:'\0032';}
.icon-clock:before{content:'\0033';}
.icon-stopwatch:before{content:'\0034';}
.icon-table-games:before{content:'\0035';}
.icon-casino-guides:before{content:'\0036';}
.icon-contact:before{content:'\0037';}
.icon-providers:before{content:'\0038';}
.icon-spintowin:before{content:'\0039';}
.icon-jackpot-games:before{content:'\0021';}
.icon-dropsandwins-games:before{content:'\0022';}
.icon-christmas:before{content:'\0023';}
.icon-halloween:before{content:'\0024';}
.icon-1:before{content:'\0025';}
.icon-new-help-icon:before{content:'\0026';}
.icon-checked:before{content:'\0027';}
