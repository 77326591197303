@import 'styles/vars.scss';
@import 'styles/fonts.scss';
@import 'styles/common.scss';
@import 'styles/typography.scss';
@import 'styles/forms/buttons.scss';
@import 'styles/notifications.scss';
@import 'styles/forms/inputs.scss';
@import 'styles/tables.scss';
@import 'styles/post-login-common.scss';
@import 'styles/ui.scss';

@import 'decorators/Header/Header';
@import 'decorators/Footer/Footer';
@import 'decorators/Footer/FooterMenu/FooterMenu';
@import 'decorators/GameDecorator/styles/GameDecorator';
@import 'decorators/PopupDecorator/PopupDecorator';
@import 'decorators/Help/Help';


@import 'modals/Modals';
@import 'modals/LoginModal/LoginModal';
@import 'modals/BonusForfeitModal/BonusForfeitModal';
@import 'modals/CumulativeDepositLimitModal/CumulativeDepositLimitModal';
@import 'modals/DepositModal/DepositModal';
@import 'modals/WithdrawalModal/WithdrawalModal';
@import 'modals/LowBalanceModal/LowBalanceModal';
@import 'modals/LimitsConfirmationModal/LimitsConfirmationModal';
@import 'modals/VerificationModal/VerificationModal';
@import 'modals/AccountValidationModal/AccountValidationModal';
@import 'modals/CongratulationModal/CongratulationModal';
@import 'modals/NetherlandsLimitsModal/NetherlandsLimitsModal';
@import 'modals/PendingWithdrawalModal/PendingWithdrawalModal';
@import "modals/CookiePolicyModal/CookiePolicyModal";
@import 'modals/RealityCheckModal/RealityCheckModal';
@import 'modals/PushNotificationModal/PushNotificationModal';
@import 'modals/HCaptchaModal/HCaptchaModal';
@import 'modals/NetherlandsLimitsConfirmModal/NetherlandsLimitsConfirmModal';
@import 'modals/NetherlandsLimitsThresholdsModal/NetherlandsLimitsThresholdsModal';
@import 'modals/DocumentsRenewalModal/DocumentsRenewalModal';

@import 'modals/HelpModal/HelpModal';

@import 'components/Title/Title';
@import 'components/Subtitle/Subtitle';
@import 'components/Separator/Separator';
@import 'components/Items/Items';
@import 'components/Form/Form';
@import 'components/Description/Description';
@import 'components/Buttons/Buttons';
@import 'components/Panel/Panel';

@import 'components/AsideMenu/AsideMenu';
@import 'components/AvailableLimitAmount/AvailableLimitAmount';
@import 'components/Balance/Balance';
@import 'components/DepositForm/DepositForm';
@import 'components/Authentication/Authentication';
@import 'components/LoginForm/LoginForm';
@import 'components/GameThumbnail/GameThumbnails';
@import 'components/HomeBannerPreLogin/HomeBannerPreLogin';
@import 'components/RegistrationForm/RegistrationForm';
@import 'components/Registration/Registration';
@import 'components/RegisterViaThirdParty/RegisterViaThirdParty';
@import 'components/ProfileForm/ProfileForm';
@import 'components/ProfilePushNotifications/ProfilePushNotifications';
@import 'components/Documents/Documents';
@import 'components/DocumentProofForm/DocumentProofForm';
@import 'components/DocumentProofStatus/DocumentProofStatus';
@import 'components/SofValidation/SofValidation';
@import 'components/SofValidationForm/SofValidationForm';
@import 'components/ValidationForm/ValidationForm';
@import 'components/AccountValidationForm/AccountValidationForm';
@import 'components/DeleteAccountForm/DeleteAccountForm';
@import 'components/DocumentProof/DocumentProof';
@import 'components/OtherDocumentsForm/OtherDocumentsForm';
@import 'components/DocumentsList/DocumentsList';
@import 'components/ProfileAccountForm/ProfileAccountForm';
@import 'components/CloseAccountForm/CloseAccountForm';
@import 'components/VerificationForm/VerificationForm';
@import 'components/StatusForm/StatusForm';
@import 'components/PromotionsForm/PromotionsForm';
@import 'components/LoyaltyProgram/LoyaltyProgram';
@import 'components/LoyaltyPointsConversionForm/LoyaltyPointsConversionForm';
@import 'components/PaymentProviderIcons/PaymentProviderIcons';
@import 'components/HomeMenu/HomeMenu';
@import 'components/GameSearch/GameSearch';
@import 'components/GameList/GameList';
@import 'components/GamesFilter/GamesFilter';
@import 'components/GameSlider/GameSlider';
@import 'components/HCaptcha/HCaptcha';
@import 'components/SelectLocale/SelectLocale';
@import 'components/PromotionCard/PromotionCard';
@import 'components/Promotions/promotions';
@import 'components/WithdrawalForm/WithdrawalForm';
@import 'components/BetLimitForm/BetLimitForm';
@import 'components/CoolOffLimitForm/CoolOffLimitForm';
@import 'components/SessionTime/SessionTime';
@import 'components/LocalTime/LocalTime';
@import 'components/ProtectionLoginForm/ProtectionLoginForm';
@import 'components/HomeSlider/HomeSlider';
@import 'components/ActivityForm/ActivityForm';
@import 'components/JackpotBannerEgt/JackpotBannerEgt';
@import 'components/LimitAmount/LimitAmount';
@import 'components/UnexpectedError/UnexpectedError';
@import 'components/HomeContent/HomeContent';
@import 'components/FeaturedGamesSlider/FeaturedGamesSlider';
@import 'components/ShuftiproVerificationForm/ShuftiproVerificationForm';
@import 'components/IncodeVerificationForm/IncodeVerificationForm';
@import 'components/SettingsMenu/SettingsMenu';
@import 'components/GameNameFilter/GameNameFilter';
@import 'components/LoginViaThirdParty/LoginViaThirdParty';
@import 'components/ContentRight/ContentRight';
@import 'components/ContentRightPostLogin/ContentRightPostLogin';
@import 'components/AccountInfo/AccountInfo';
@import 'components/UserInformation/UserInformation';
@import 'components/UserIdentification/UserIdentification';
@import 'components/PlayerAffordabilityForm/PlayerAffordabilityForm';
@import 'components/SessionSummary/SessionSummary';
@import 'components/GameSummary/GameSummary';
@import 'components/ResetPasswordForm/ResetPasswordForm';
@import 'components/ScrollToTop/ScrollToTop';
@import 'components/Volatility/Volatility';
@import 'components/InputDateSelect/InputDateSelect';
@import 'components/GameLimits/GameLimits';
@import 'components/InputCode/InputCode';
@import 'components/IbanCheckForm/IbanCheckForm';
@import 'components/Bank/Bank';
@import 'components/PendingWithdrawalTransactions/PendingWithdrawalTransactions';
@import 'components/TermsAcceptance/TermsAcceptance';
@import 'components/AttachmentButton/AttachmentButton';
@import 'components/ProvidersContent/ProvidersContent';
@import 'components/CddnRegistrationForm/CddnRegistrationForm';
@import 'components/CddnUserIdentification/CddnUserIdentification';
@import 'components/TopRegistrationForm/TopRegistrationForm';
@import 'components/FrontPageContent/FrontPageContent';
@import 'components/ProvidersSlider/ProvidersSlider';
@import 'components/HorizontalGameList/HorizontalGameList';
@import 'components/HorizontalGameGroup/HorizontalGameGroup';
@import 'components/LivePageContent/LivePageContent';
@import 'components/GamesSearchResult/GamesSearchResult';

@import 'pages/Home/Home';
@import 'pages/Activation/Activation';
@import 'pages/AuthorizationRequired/AuthorizationRequired';
@import 'pages/ForgotPassword/ForgotPassword';
@import 'pages/ResetPassword/ResetPassword';
@import 'pages/SetPassword/SetPassword';
@import 'pages/Limits/Limits';
@import 'pages/Transactions/Transactions';
@import 'pages/Support/Support';
@import 'pages/SupportCustom/SupportCustom';
@import 'pages/StaticPage/StaticPage';
@import 'pages/NotFound/NotFound';
@import 'pages/GameInfoPage/GameInfoPage';
@import 'pages/Blocked/Blocked';
@import 'pages/ProtectionCustom/ProtectionCustom';
@import 'pages/ResponsibleGamblingQuestionnaire/ResponsibleGamblingQuestionnaire';
@import 'pages/PendingRounds/PendingRounds';
@import 'pages/AuthorizationRequired/AuthorizationRequired';
@import 'pages/Guides/Guides';
@import 'pages/Guide/Guide';
@import 'pages/Providers/Providers';
@import 'pages/Tournaments/Tournaments';
@import 'pages/Tournament/Tournament';
@import 'pages/RegistrationPage/RegistrationPage';

/* Forms */
@import 'components/GameTransactionsForm/GameTransactionsForm';


@import '../cms/public/fonts/glyphter-custom/Glyphter.css';
