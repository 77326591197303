.help-modal-buttons {
  display: flex;
}

.help-modal-buttons .help-modal-buttons--btn {
   width: 100%;
 }

.help-modal-input {
  margin-top: 20px;
}

.session-time-increasing-confirmation-modal {
  .confirmation-modal__buttons{
    justify-content: space-around;
    width: 100%;
  }

  button {
    display: block;
    width: 100%;
  }

  .gridItem-root {
    width: 48%;
    margin-right: 10px;
    @include media-mobile {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

