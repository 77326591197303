.live-page-content {
  &--bottom-indent {
    margin-bottom: 60px;
    @media (max-width: $size-xs) {
      margin-bottom: 30px;
    }
  }
  &--top-indent {
    margin-top: 60px;
    @media (max-width: $size-xs) {
      margin-top: 30px;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    @media (max-width: $size-sm) {
      margin-top: 17px;
    }

    @include media-xs {
      .home__games-filter__name {
        max-width: 90%;
      }
    }

    @media (min-width: $size-md) {
      height: 61px;
      margin-bottom: 0;
    }
    .home__games-filter__name {
      margin-top: 114px;

      @include media-ml {
        margin-top: 0;
      }
      @include media-md {
        margin-top: 17px;
      }
    }
    @include media-ml {
      margin-top: 0;
    }
  }
}
