.drawer-paperAnchorLeft {
  height: 100%;

  @include media-xs {
    top: $header-height-mobile;
  }
}
.drawer-paperAnchorTop {
  width: 100%;
  background: rgba(0, 0, 0, 0);
}
.aside-menu {
  display: grid;
  grid-template-areas: "logo close"
                        "locale locale"
                        "menu menu"
                        "footer footer";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: 90vw;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: color(background, aside-menu);
  &::-webkit-scrollbar {
    display: none;
  }
  &__logo {
    grid-area: logo;
    padding: 15px 0 15px 20px;
    img {
      width: 95%;
      height: 100%;
    }
  }
  &__link-back-wrap {
    .link {
      display: flex;
      align-items: center;
      height: 51px;
      margin-top: 2px;
      padding: 0 0 0 32px;

      color: color(secondary, light);

      &:hover {
        color: color(secondary, light);
      }
    }
  }
  &__locale {
    display: block;
    grid-area: locale;
    font-weight: 700;
    color: color(secondary, light);

    .select-locale {
      height: initial;

      &::before {
        display: block;
        @include svg-icon(custom);
        position: absolute;
        left: 20px;
        top: 16px;
        color: $gray-4;
        font-size: 18px;
        z-index: 1;
      }
      &__item {
        height: 51px;
        margin-top: 2px;
        padding: 0 0 0 60px;
        background-color: #2d3030;
      }
      .select-selectMenu{
        width: 40px;
        background-color: $gray-9;
        border-radius: 5px;
      }
      .select-icon {
        right: 0;
      }
    }
    .mobile-selector {
      color: $white;
    }
  }
  &__footer {
    grid-area: footer;
    display: flex;
    background-color: color(background, aside-menu-footer);
    width: 100%;
    flex-direction: column;
    padding: 15px 20px;
    > img {
      width: 50%;
      height: 100%;
      padding: 0;
    }
    p {
      position: relative;
      color: $white;
      font-size: 16px;
      margin: 15px 0;
      padding-left: 25px;
    }
    @include media-xs {
      margin-bottom: $home-menu-mobile-height;
    }
  }
  & &__links {
    &--closeBtn {
      &::after {
        position: absolute;
        @include svg-icon(custom);
        content:'\0045';
        top: 50%;
        right: 12px;
        color: $white;
        transition: $main-transition-time;
        font-size: 22px;
        cursor: pointer;
        transform: translateY(-10px);
      }
    }
    &-wrapper {
      grid-area: menu;
      flex-direction: column;
      grid-column: span 2;
      width: 100%;
      margin-top: -10px;
      padding-top: 18px;
      border-top: 3px solid #36393a;

      @include media-xs {
        border-top: none;
      }

      .settings-menu {
        margin: 0;
        width: 100%;

        .sub-menu {
          a {
            display: block;
            color: #848d8d;
            padding: 10px 0 10px 20px;
            font-size: 14px;
          }
        }
      }

      .aside-menu__item {
        position: relative;

        &::before {
          @include svg-icon(custom);
          position: absolute;
          left: 20px;
          top: 16px;
          color: $gray-4;
          font-size: 18px;
          z-index: 1;
        }

        &--menuAsideGameGroups::before {
          content: '\004a';
        }

        &--menuAsideProfile::before {
          content: '\0056';
        }

        &--menuAsideCampaigns::before {
          content: '\0061';
        }

        &--menuAsideLimits::before {
          content: '\0049';
        }

        &--menuAsideTransactions::before {
          content: '\004d';
        }
      }

      .details-root {
        background-color: #222525;
        margin-bottom: 2px;

        .detailsSummary-root {
          padding: 0;
          min-height: 0;

          .detailsSummary-content {
            display: block;
            margin: 0;
            color: $gray-9;
            font-size: 18px;
            font-weight: 700;
            background-color: #2d3030;
            padding: 14px 0 14px 50px;
          }
        }
      }
    }
  }
  &__close {
    position: relative;
    grid-area: close;
    width: 34px;
    height: 34px;
    margin: 15px 16px;
    cursor: pointer;

    &::before {
      @include svg-icon(custom);
      position: absolute;
      content: '\0045';
      top: 8px;
      left: 8px;
      font-size: 18px;
      color: $gray-9;
    }
  }
  a {
    text-decoration: none;
    color: $gray-4;
  }
  /* items static menu */
  .settings-menu__item-override {
    a, span {
      position: relative;
      display: flex;
      align-items: center;
      height: 51px;
      margin: 0;
      padding: 0 0 0 60px;
      font-weight: 700;
      font-size: 19px;
      cursor: pointer;
      color: color(secondary, light);
      background-color: color(background, aside-menu-item);

      @include media-xs {
        font-size: 16px;
        margin-left: 4px;
        text-transform: uppercase;
        font-weight: 500;
      }

      &::before {
        @include svg-icon(custom);
        content: '\004a';
        position: absolute;
        top: 0;
        left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 51px;
        color: color(secondary, light);

        @include media-xs {
          height: 44px;
          font-size: 26px;
          left: 22px;
        }
      }
      &::after {
        display: none;
      }
    }
    &--home {
      a::before {
        content: '\006c';
      }
    }
    &--topRated {
      a::before {
        content: '\0043';
      }
    }
    &--new {
      a::before {
        content: '\0056';
      }
    }
    &--exclusives {
      a::before {
        content: '\0049';
      }
    }
    &--slots {
      a::before {
        content: '\0061';
      }
    }
    &--classicSlots {
      a::before {
        content: '\0076';
      }
    }
    &--megaWays {
      a::before {
        content: '\0077';
      }
    }
    &--goldrun {
      a::before {
        content: '\0049';
      }
    }
    &--jackpot {
      a::before {
        content: '\0021';
      }
    }
    &--bingo {
      a::before {
        content: '\0078';
      }
    }
    &--keno {
      a::before {
        content: '\004e';
      }
    }
    &--livecasino {
      a::before {
        content: '\0050';
      }
    }
    &--table {
      a::before {
        content: '\0035';
      }
    }
    &--promotions {
      a::before {
        content: '\0057';
      }
    }
    &--support {
      a::before {
        content: '\0062';
      }
    }
    &--loyalty {
      a::before {
        content: '\0052';
      }
    }
    &--contact-us {
      a::before {
        content: '\0055';
      }
    }
    &--favourite {
      a::before {
        content: '\0043';
      }
    }
    &--locale {
      span::before {
        content: '\0041';
      }
    }
    &--guides {
      a::before {
        content: '\0036';
      }
    }
    &--contact {
      a::before {
        content: '\0037';
      }
    }
    &--providers {
      a::before {
        content: '\0038';
      }
    }
    &--spintowin {
      a::before {
        content: '\0039';
      }
    }
    &--dropsandwins {
      a::before {
        content: '\0022';
      }
    }
    &--halloween {
      a::before {
        content: '\0024';
      }
    }
    &--christmas {
      a::before {
        content: '\0023';
      }
    }
  }

  &__static {
    background-color: $violet;
    padding: 5px 0;

    a {
      position: relative;
      display: flex;
      align-items: center;
      height: initial;
      padding: 8px 0 8px 28px;
      font-size: 16px;
      font-weight: normal;
      color: $gray-5;
      background-color: initial;
      cursor: pointer;

      &::before {
        display: none;
      }
    }
  }
}
