.transactions {
  .empty {
    @media (min-width: 561px) {
      &::before {
        content: '—';
      }
    }
  }
  .transactions-result-message {
    @include field-font__h4;
    margin-top: 30px;

    p {
      margin: 0;
    }
  }
  .custom-table--transactions {
    margin-top: 35px;
    margin-bottom: 40px;

    @include media-md {
      display: table;
    }
    .custom-table-head .custom-table-cell {
      @include field-font__h4;
      padding-bottom: 15px;
      font-weight: 700;
      text-transform: none;
    }
    .custom-table {
      &-cell {
        @include field-font__h4;
        padding: 35px 4px;

        > span {
          &::before {
            @include svg-icon;
            padding-right: 5px;
            font-size: 16px;
          }
        }
        &--game .fpLink {
          color: color(text, dark);
        }
        .button-default {
          min-height: 0;
          padding: 0;
          text-transform: none;

          &::after {
            width: 100%;
            bottom: 0;
          }
          p {
            margin: 0;
          }
        }
        &--created-time {
          .button-default {
            .button-label {
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
            &::after {
              background: none;
            }
          }
          .button-label {
            &::before {
              @include svg-icon;
              padding-right: 5px;
              font-size: 16px;
            }
          }
        }
        &--status {
          &.rejected,
          &.cancelled,
          &.error,
          &.voided {
            > span {
              font-weight: bold;
            }
          }
        }
        &--reference {
          .button-default {
            .button-label {
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
            &::after {
              background: none;
            }
          }
          @media (min-width: 561px) {
            .markdown-root {
              strong {
                font-weight: inherit;
              }
            }
          }
        }
        &--method {
          font-weight: 700;
        }
        &--bonus {
          .markdown-root {
            p {
              margin: 0;

              + p {
                margin-top: 5px;
              }
            }
          }
        }
        &--name {
          font-weight: 700;
        }
        &--name,
        &--type {
          > span {
            word-break: break-word;
          }
        }
      }
      &-head {
        display: table-header-group;
      }
      &-body {
        display: table-row-group;
      }
      @include media-mobile {
        &-head {
          display: none;
        }
        &-cell {
          display: block;
          padding: 0;
          border: 0;
          text-align: left;
        }
      }
    }
    @include media-mobile {
      .mobile-title {
        font-weight: $font-weight-alt;
      }
      .custom-table-row {
        display: flex;
        flex-direction: row;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 2px solid $gray-4;

        .custom-table-cell--other {
          display: flex;
          flex-direction: column;
        }
        .custom-table-cell--other {
          flex: 1;

          .group-root:not(:first-child) {
            margin-top: 10px;
          }
        }
        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }
      .custom-table-cell {
        &--created-time {
          font-weight: bold;
          color: $green-1;

          .button-default {
            width: 100%;
            min-height: initial;
            padding: 0;

            .button-label {
              justify-content: space-between;
              margin: 0;
              width: 100%;
              padding-right: 40px;
              font-weight: bold;
              text-decoration: none;
              text-align: left;
              color: color(accent, secondary);

              &:after {
                @include svg-icon;
                content: '\0041';
                position: absolute;
                top: 0;
                right: 0;
                font-size: 30px;
                border-radius: 50%;
                color: $white;
                background: color(accent, primary);
              }
            }
          }
        }
        &--main {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 140px;
          width: 100%;
          margin-right: 40px;
          border: 1px solid $gray-4;
        }
        &--method {
          &-icon {
            max-width: 90px;
            max-height: 32px;
          }
        }
        &--game {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 140px;
          width: 100%;
          margin-right: 40px;

          &-icon img {
            max-width: 130px;
            width: 100%;
            max-height: 100px;
            height: 100%;
          }
        }
        &--bonus {
          float: right;
          clear: both;
          min-height: 10px;

          p::before {
            @include svg-icon;
            content: '\0056';
            margin-bottom: 2px;
            padding-right: 5px;
            padding-bottom: 1px;
            font-size: 18px;
            vertical-align: bottom;
            color: color(secondary, main);
          }
          &.empty {
            display: none;
          }
        }
        &--status {
          &::before {
            @include svg-icon;
          }
        }
        &--reference {
          .button-label {
            margin: 0;
          }
        }
        &--amount {
          font-weight: bold;
        }
        &--promo-icon {
          &::before {
            @include svg-icon(custom);
            content: '\0057';
            font-size: 40px;
            color: color(accent, primary);
          }
        }
      }
    }
    &-promo {
      .custom-table-row {
        @media (max-width: 667px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

.transactions-form {
  &__grid {
    justify-content: left;
    width: auto;
    margin: -20px;
  }
  &__grid-item {
    padding: 20px;
  }
  &-session {
    .session__line {
      display: flex;
      justify-content: space-between;
      padding: 35px 0;
      border-bottom: 1px solid $gray-2;

      &:first-child {
        padding-top: 0;
      }
      & > span {
        font-size: 18px;

        &:last-child {
          font-weight: 700;
        }
      }
    }
  }
  .notification-wrap {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.transaction-details {
  &__modal {
    .button-root {
      p {
        margin: 0;
      }
      strong {
        font-weight: $font-weight-base;
      }
    }
  }
  &__table {
    display: table;
  }
  &__buttons {
    padding: 36px 0 30px;
  }
}

.transactions-paginator {
  margin-bottom: 40px;

  @include media-mobile {
    margin-bottom: 0;
  }
}

.transactions-bets {
  border-radius: 16px;

  &__title {
    @include field-font__h4;
    margin-bottom: 15px;
    margin-left: 35px;
    line-height: inherit;
  }

  &__stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 15px -10px;

    @include media-md {
      margin: 20px -10px -10px;
    }
  }

  &__stat {
    @include field-font__h4;
    position: relative;
    margin: 0 15px 10px;
    padding-left: 24px;

    @include media-md {
      margin: 0 10px 10px;
    }
    &::before {
      @include size(16px);
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      border-radius: 50%;
    }
    &--bet {
      &::before {
        background: $gray-3;
      }
    }
    &--win {
      &::before {
        background: color(accent, primary);
      }
    }
    &--notice {
      flex: 1;
      min-width: 150px;
      text-align: right;
      color: #a09f9f;

      @include media-md {
        text-align: center;

        &--amount {
          font-weight: bold;
        }
        &--promo-icon {
          &::before {
            @include svg-icon(custom);
            content: '\0057';
            font-size: 40px;
            color: color(accent, primary);
          }
        }
      }
    }

    &-title {
      font-weight: $font-weight-alt;
    }
  }

  .linearProgress-root {
    .linearProgress-progressLine {
      height: 27px;

      .linearProgress-line {
        border-radius: 0;
      }
      .linearProgress-progress {
        animation: none;
        transition: none;
      }
    }
  }
}


