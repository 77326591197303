.panel {
  width: 100%;

  @include media-sm {
    margin: auto;

    &:not(&--full) {
      padding: 15px 30px;
    }
  }
}
