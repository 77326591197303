/* for leveling gaming category */
.home-menu.settings-menu {
  margin-left: -10px;

  @include media-lg {
    margin-left: -20px;
  }
  @include media-ml {
    margin-left: -18px;
  }
  @include media-md {
    margin-left: 0;
  }
}
.home-menu {
  //scrollbar for Firefox
  scrollbar-color: #fd8f00 #1B1829;

  //scrollbar for Chrome and Safari
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fd8f00;
    border-top: 9px solid #1B1829;
    border-bottom: none;
  }

  &::-webkit-scrollbar-track {
    background: #1B1829;
  }

  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border: none;
  overflow-x: auto;
  text-align: center;

  &.swiper-root {
    position: relative;
    overflow-x: hidden;
    padding: 0;

    .swiper-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      min-width: 100%;
    }

    .home-menu__nav-prev,
    .home-menu__nav-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 100%;
      cursor: pointer;
      z-index: 20;

      &.is-disabled {
        display: none;
      }
    }
    .home-menu__nav-prev{
      left: 0;
      background-image: url('/cms/img/slider/prev.svg'), linear-gradient(to right, color(primary, main) 30%, rgba(255, 255, 255, 0) 100%);
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position: center;
      background-size: 15px, contain, contain;
    }
    .home-menu__nav-next {
      right: 0;
      background-image: url('/cms/img/slider/next.svg'), linear-gradient(to left, color(primary, main) 30%, rgba(255, 255, 255, 0) 100%);
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position: center;
      background-size: 15px, contain, contain;
    }
  }
  .settings-menu__item-override {
    transform: scale(1);

    .game-search-button {
      position: relative;
      width: 100%;
      max-width: 268px;
      height: 41px;
      padding: 13px 55px 12px 17px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 6px;
      text-align: left;
      font-size: 14px;
      color: #b4b4b4;
      font-weight: 500;

      @media (min-width: 1025px) {
        &::before {
          position: absolute;
          top: 0;
          right: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 22px;
          color: $white;
        }
      }
      @include media-md {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: initial;
        width: auto;
        padding: 20px 15px;
        flex: initial;
        border: none;
        font-size: 0;

        &::before {
          font-weight: 900;
          color: color(accent, primary);
        }
      }
    }
    .game-provider-filter {
      position: relative;
      width: 100%;
      max-width: 268px;
      height: 41px;

      &::after,
      &::before {
        display: none;
      }
      .games-filter__button {
        @include svg-icon(custom);
        content: '\0059';
        font-size: 22px;
        vertical-align: middle;
      }
      input {
        min-width: 200px;
        height: 100%;
        padding-left: 17px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 6px;
        color: $white;
        background: transparent;

        &::placeholder {
          font-size: 14px;
          color: #b4b4b4;
        }
        @include media-md {
          min-width: 120px;
        }
      }
      .input-before {
        position: absolute;
        top: 10px;
        right: 25px;
        color: $white;
        font-size: 16px;
      }
    }
    &--search-modal {
      margin-left: auto;

      .mobile & {
        margin-left: 0;
      }

      .button-root {
        min-width: 0;
        padding: 0;

        &:hover {
          background: none;

          .button-label {
            &::before {
              color: color(primary, main);
            }
          }
        }
        .button-label {
          &::before {
            @include svg-icon;
            content: '\0059';
            font-size: 20px;
            font-weight: $font-weight-alt;
            transition: $main-transition-time;
          }
        }
      }
    }
    &--search {
      flex: 1;
      text-align: right;
      transform-origin: right;

      @include media-md {
        order: -1;
        flex: initial;
      }
    }
    &--home {
      span::before {
        content: '\006c';
      }
    }
    &--topRated {
      span::before {
        content: '\0043';
      }
    }
    &--new {
      span::before {
        content: '\0056';
      }
    }
    &--exclusives {
      span::before {
        content: '\0049';
      }
    }
    &--slots {
      span::before {
        content: '\0061';
      }
    }
    &--classicSlots {
      span::before {
        content: '\0076';
      }
    }
    &--megaWays {
      span::before {
        content: '\0077';
      }
    }
    &--jackpot {
      span::before {
        content: '\004d';
      }
    }
    &--jackpot {
      span::before {
        content: '\0021';
      }
    }
    &--bingo {
      span::before {
        content: '\0078';
      }
    }
    &--keno {
      span::before {
        content: '\004e';
      }
    }
    &--table {
      span::before {
        content: '\0035';
      }
    }
    &--livecasino {
      span::before {
        content: '\0050';
      }
    }
    &--providers {
      span::before {
        content: '\0038';
      }
    }
    &--Dutch {
      span::before {
        content: '\0037';
      }
    }
    &--spintowin {
      span::before {
        content: '\0039';
      }
    }
    &--dropsandwins {
      span::before {
        content: '\0022';
      }
    }
    &--halloween {
      span::before {
        content: '\0024';
      }
    }
    &--christmas {
      span::before {
        content: '\0023';
      }
    }
  }

  &__wrapper {
    position: relative;
    z-index: 20;
    border: none;
    background-color: transparent;

    @include media(null, $size-md) {
      &--scrolled {
        transform: scaleY(0.8);

        .settings-menu__item-override {
          transform: scaleX(0.8);
        }
      }
    }
    @include media-md {
      background: color(primary, main);
      height: $home-menu-mobile-height;
      &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2000;

        &.home-menu--modal-open {
          z-index: auto;
        }
      }
    }
    .settings-menu {
      @include media-md {
        margin: 0;
        padding: 0 10px;
      }
    }
  }

  &__inner {
    position: relative;
    padding: 0;
    z-index: 2;
    @include media-md {
      padding: 10px 0;
    }
  }
  a {
    position: relative;
    opacity: 0;

    & + span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    &, & + span {
      @include link--root;
      @include link--color(light);
      display: flex;
      align-items: center;
      margin: 0;
      padding: 15px 12px;
      text-decoration: none;
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;

      &::before {
        display: none;
      }
      @include media-lg {
        padding: 15px;
        font-size: 14px;
      }
      @include media-ml {
        padding: 15px 10px;
      }
      @include media-md {
        display: flex;
        flex-direction: column;
        width: 80px;
        height: 57px;
        padding: 8px 4px 5px;
        font-size: 10px;
        font-weight: 700;
        border-radius: 5px;
        background-color: $violet;
        white-space: normal;

        &.active + span {
          background-color: $violet-accent;
        }

        &::before {
          @include svg-icon(custom);
          content: '\004a';
          display: block;
          padding-right: 10px;
          font-size: 21px;
          color: color(secondary, light);
          transition: color $main-transition-time;
          opacity: 1;

          @include media-lg {
            font-size: 18px;
          }
          @include media-ml {
            font-size: 22px;
          }
          @include media-md {
            margin: 0 0 auto 0;
            padding-right: 0;
          }
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        height: 5px;
        width: 100%;
        background-color: color(accent, primary);
        opacity: 0;

        @include media-md {
          display: none;
        }
      }
      &.active + span {
        font-weight: 700;
      }
      body.desktop &:hover + span,
      &.active + span {
        &::after {
          opacity: 1;
        }
      }
      &.game-search-button {
        flex: 1;

        &::before {
          @include svg-icon(custom);
          content: '\0059';
          vertical-align: middle;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  .games-filter__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    @include media-md {
      padding-right: 10px;
    }
    &::before {
      @include svg-icon(custom);
      content: '\0059';
      font-size: 30px;
      vertical-align: middle;
      color: $white;
      cursor: pointer;
    }
    &.active {
      &::before {
        color: color(accent, primary);
      }
    }
  }
}
