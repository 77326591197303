.pending-rounds {
  margin: 32px auto;
}

.pending-rounds-device {
  padding: 10px 0;
  font-size: 18px;
  text-align: center;

  &__icon {
    margin: 20px 0;
    vertical-align: top;
  }
}
