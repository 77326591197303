.activity-form {
  .custom-table {
    margin-top: 30px;
  }
  .custom-table-cell--status--login {
    color: color(success)
  }
  .custom-table-cell--status--logout {
    color: color(error)
  }
  @include media-mobile {
    &__cell {
      &-title {
        display: flex;
      }
      &-content {
        &--time {
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
          text-align: left;
          color: $green-1;
        }
        &--ip {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .custom-table-cell {
      margin: 0;
      padding: 0;
      border: initial;

      &:not(:first-child) {
        margin-top: 10px;
      }
      &--main {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 140px;
        width: 100%;
        margin-right: 40px;
        background-color: $gray-4;
      }
      &--other {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
    .custom-table-row {
      display: flex;
      flex-direction: row;
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 2px solid $gray-4;
    }
  }
}
