/* fixed the problem with the height of methods in the modal deposit */
.deposit-modal {
  &--chrome {
    .modal-paper {
      @include media-mobile {
        @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
          align-self: flex-start;
          max-height: calc(100% - 50px);
        }
      }
    }
  }
  &--frame {
    .modalContentWrapper {
      max-height: 90vh;

      @include media-sm {
        height: 100vh;
        max-height: none;
      }
    }
    .modal-contentRoot {
      height: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &--shuftipro {
    .modal-paper {
      width: 100%;
      max-width: 600px;
    }
  }
  .modal-paper {
    width: 600px;

    .modalContentWrapper {
      padding: 0;

      .modal-contentRoot {
        padding: 0;
      }
    }
  }
}
@media (max-width: 480px) {
  .modal-root.deposit-modal {
    height: calc(100vh - 63px - 78px);
  }
  #menu-bank_issuer_id {
    z-index: 3000;
  }
}
