.horizontal-game-list {
  cursor: pointer;
  &.show {
    .horizontal-game-list__slider {
      display: block;
    }
  }
  &__slider {
    display: none;
    overflow: hidden;
    &--nl {
      .horizontal-game-list__prev {
        right: calc(140px + 36px + 12px);
      }
      .horizontal-game-list__next {
        right: 140px;
      }
    }
  }
  &__slider-wrapper {
    flex-wrap: wrap;
    display: flex;
    max-height: 380px;
    overflow: hidden;

    &_recentGames {
      display: inline-flex;
    }

    @media (max-width: 1640px) {
      max-height: 336px;
    }
    @media (max-width: 1440px) {
      max-height: 297px;
    }
    @media (max-width: 1024px) {
      max-height: 357px;
    }
    @media (max-width: 900px) {
      max-height: 381px;
    }
    @media (max-width: 480px) {
      max-height: 285px;
    }
  }
  &__slider-wrapper--featured-slider {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    row-gap: 15px;
    .horizontal-game-list__slider-slide {
      order: 1!important;
      margin-top: 0px!important;
      min-width: 255px;
      @media (max-width: 1640px) {
        min-width: 222px;
      }
      @media (max-width: 1440px) {
        min-width: auto;
      }

      @media (min-width: 1025px) {
        &:nth-child(8) {
          order: 4!important;
        }
        &:nth-child(9) {
          order: 7!important;
        }
        &:nth-child(10) {
          order: 10!important;
        }
        &:nth-child(11) {
          order: 5!important;
        }
        &:nth-child(12) {
          order: 8!important;
        }
        &:nth-child(13) {
          order: 11!important;
        }
        &:nth-child(14) {
          order: 1!important;
        }
        &:nth-child(15) {
          order: 2!important;
        }
        &:nth-child(16) {
          order: 3!important;
        }
        &:nth-child(17) {
          order: 6!important;
        }
        &:nth-child(18) {
          order: 9!important;
        }
        &:nth-child(19) {
          order: 12!important;
        }
        &:nth-child(n+20) {
          order: 13!important;
        }
      }
    }
  }
  &__slider-slide {
    max-height: 183px;
    @media (max-width: $size-xl) {
      min-height: 9vw;
    }
  }
  &__featured-games-slider {
    grid-column: span 3;
    grid-row: span 2;
    margin-top: 0px!important;
    margin-right: 15px;
    order: 0!important;
  }
  &__empty-cell {
    @media (max-width: 479px) {
      display: none;
    }
  }
  &__prev, &__next {
    border: none;
    outline: none;
    width: 36px;
    height: 36px;
    background: $black-5;
    border-radius: 10px;
    margin-right: 12px;
    cursor: pointer;
    position: absolute;
    top: -66px;
    @media (max-width: 600px) {
      display: none;
    }
  }
  &__prev {
    right: calc(104px + 36px + 12px);
    background: url("/cms/img/icons/white-arrow-prev.svg") no-repeat center center, #1B1829;
    &.swiper-button-disabled {
      background: url("/cms/img/icons/white-arrow-prev.svg") no-repeat center center, #9692B6;
      cursor: auto;
    }
  }
  &__next {
    right: calc(104px);
    background: url("/cms/img/icons/white-arrow-next.svg") no-repeat center center, #1B1829;
    &.swiper-button-disabled {
      background: url("/cms/img/icons/white-arrow-next.svg") no-repeat center center, #9692B6;
      cursor: auto;
    }
  }
}
