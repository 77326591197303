.attachment-button {
  text-align: center;

  &__hidden-button {
    display: none;
  }

  &__avatar {
    border-radius: 0;
    width: auto;
    max-width: 145px;
    border: 1px solid color(text, dark);
    height: 105px;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    padding: 0 16px 16px 0;
    margin: 0;

    &::before {
      display: none !important;
    }
  }

  &__info {
    display: none !important;
  }

  &__upload {
    //align-self: flex-start;

    &_hidden {
      display: none;
    }
  }
}
