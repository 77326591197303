.iban-check-form {
  .gridItem-root {
    &--initials,
    &--initials {
      flex-basis: 50%;

      @include media-mobile {
        flex-basis: 100%;
      }
    }
  }
}
