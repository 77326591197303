.login-modal {
  .modal-paper {
    width: 600px;
    min-height: 654px;
    @media (min-width: $size-mobile) {
      .modalContentWrapper {
        padding-top: 94px;
        .modal-titleRoot {
          padding-right: 100px;
          padding-left: 100px;
        }
        .modal-contentRoot {
          padding-right: 100px;
          padding-left: 100px;
        }
      }
    }
  }
}
.login-modal-afterInactivity {

  .modal-paper {
    @media (min-width: $size-mobile) {
      height: max-content;
    }
  }
  &__text {
    margin-top: 95px;
    text-align: center;
    p {
      @include field-font__h4;
      margin-top: 12px;
      margin-bottom: 30px;
    }
  }
}

.resume-form.group-root {
  width: -webkit-fill-available;
}
