.promotion-card-old {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;

  @include media-sm {
    width: 100%;
  }
  &__image {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 200px;
    padding: 10px 50% 10px 14px;
    background-position: 75% center;
    background-size: cover;

    @include media-lg {
      padding: 0 50% 20px 20px;
    }
    @include media-md {
      padding: 0 30% 15px 15px;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(62deg, rgba(0,0,0,0.95) 0%, rgba(24,23,23,0.92) 25%, rgba(251,251,251,0) 50%, rgba(0,0,0,0.82) 100%);
    }

    @include media-sm {
      padding: 0 30% 15px 15px;
    }

    span {
      line-height: 0.9;
      font-size: 12px;
      color: $gray-8;
      z-index: 1;
    }
  }
  &__body {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: var(--md);
    color: $orange-3;
    background: $black-2;

    @include media-mobile {
      flex-direction: column;
      align-items: initial;
    }
  }
  &__title {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 700;
    color: $white;
  }
  &__content {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;

    @include field-font__h6
  }
  &__claim-btn {
    min-width: 140px;
    p {
      font-size: 18px;
      @include media-lg {
        font-size: 16px;
      }
    }
    @include media-mobile {
      width: 100%;
    }
  }
  &__more-info {
    @include media-mobile {
      width: 100%;
    }
  }
  &__terms-link {
    display: block;
    margin-bottom: -5px;
    padding: 7px 10px 6px;
    font-size: 12px;
    text-align: center;
    color: color(promotions-card, link);
  }
  &__condition {
    &::before {
      @include svg-icon;
      position: relative;
      top: 4px;
      left: -1px;
      font-size: 20px;
      margin-right: 4px;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    @include media-mobile {
      margin-top: var(--md-resize);
    }

    .button-root + .button-root {
      margin-left: var(--sm);
      @include media-mobile {
        font-size: 14px;
      }
    }
  }
  p {
    margin: 0;
    @include media-mobile {
      font-size: 14px;
      white-space: nowrap;
    }
  }
}
