.account__title {
  margin: 0;
  padding: 0;
  @include field-font__h2;
  font-weight: $font-weight-alt;
  color: $black-1;
  padding-bottom: var(--md-resize);
  letter-spacing: 0.6px;
  @include media-xs {
    padding-bottom: 15px;
  }
}
