.loyalty-program {
  $spacer: (
    lg: 50px,
    sm: 30px
  );
  $color-brand: #368b14;
  $color-muted: #a09f9f;
  $color-minor: $gray-3;

  @mixin spacing {
    &:not(:first-child) {
      margin-top: map-get($spacer, lg);

      @include media-sm {
        margin-top: map-get($spacer, sm);
      }
    }
    &:not(:last-child) {
      margin-bottom: map-get($spacer, lg);

      @include media-sm {
        margin-bottom: map-get($spacer, sm);
      }
    }
  }

  padding: 10px 0px map-get($spacer, lg);

  @include media-sm {
    padding: 10px 0 map-get($spacer, sm);
  }

  // Reset
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
  }

  // Description
  &__description {
    @include spacing;

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  // Steps
  &__steps {
    $icon-steps-size: 80px;

    margin-top: 30px;
    border-top: 1px solid $color-minor;
    border-bottom: 1px solid $color-minor;

    @include media-sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }

    &-list {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: auto;
      padding: 20px 0;

      @include media-sm {
        display: inline-block;
        margin: 0;
        padding: 10px 0;
      }
    }
    &-line-through {
      position: absolute;
      z-index: 1;
      top: 60px;
      left: 50%;
      transform: translate(-50%, -50%);
      // `width` is calculated based on the number of steps
      height: 5px;
      background: $color-minor;

      @include media-sm {
        top: calc($icon-steps-size / 2);
        bottom: calc($icon-steps-size / 2);
        left: calc($icon-steps-size / 2);
        transform: translate(-50%, 0);
        width: 16px !important;
        height: auto;
      }
    }
    &-item {
      position: relative;
      z-index: 2;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-sm {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: map-get($spacer, sm);
        }
      }

      &-icon {
        @include size($icon-steps-size);

        position: relative;
        margin: 0 auto;
        background: $color-brand;
        border-radius: 50%;
        border: 5px solid $color-minor;

        @include media-sm {
          flex-shrink: 0;
          margin: 0;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 33px;
          color: $white;
          font-family: 'Montserrat', sans-serif;
        }

        &--step-1 {
          &::before {
            content: '1';
          }
        }
        &--step-2 {
          &::before {
            content: '2';
          }
        }
        &--step-3 {
          &::before {
            content: '3';
          }
        }
      }

      &-description {
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
        max-width: 123px;

        @include media-sm {
          margin-top: 0;
          margin-left: 20px;
          text-align: left;
          max-width: 100%;
        }
      }
    }
  }

  // Form
  &__form {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-minor;

    @include media-sm {
      margin-top: 15px;
      padding-bottom: 15px
    }
  }

  // Progress
  &__progress {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-minor;

    @include media-sm {
      margin-top: 15px;
      padding-bottom: 15px
    }

    &-title {
      margin-bottom: calc(map-get($spacer, lg) / 2);
      font: {
        weight: bold;
      }

      @include media-sm {
        margin-bottom: 15px;
        text-align: center;
      }
    }
    &-content {
      display: flex;
      @include media-sm {
        flex-direction: column;
      }
    }
    // Info
    &-info {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 175px;
      height: 175px;
      padding-left: 45px;
      position: relative;
      margin: 20px 0px;

      @include media-sm {
        margin: 0;
        align-self: center;
      }

      &-circle {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .circularProgress-path {
          stroke: $orange-3;
        }
        .circularProgress-content {
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }
      &-amount {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: -1px;
        white-space: nowrap;

        @include media-sm {
          font-size: 20px;
        }
      }
      &-caption {
        max-width: 109px;
        font-size: 14px;
        text-transform: lowercase;
      }
    }

    // Tiers
    &-tiers {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-sm {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    &-tiers-list {
      gap: 10px;
      flex: 1 1 auto;
      position: relative;
      padding-left: 20px;
      height: 100%;


      @include media-md {
        margin-left: map-get($spacer, sm);
      }

      @include media-sm {
        margin-top: map-get($spacer, sm);
        margin-left: 0;
        padding-left: 0;
      }

      @media (max-width: 470px) {
        flex-direction: column;
      }
    }
    &-tiers-item {
      padding: 30px 10px 20px 10px;
      text-align: center;
      border-width: 1px;
      border-style: solid;
      border-radius: 10px;
      flex: 1;
      position: relative;

      @media (max-width: 1640px) {
        flex-basis: 40%;
      }
      @include media-lg {
        flex-basis: 20%;
      }
      @media (max-width: 1270px) {
        flex-basis: 40%;
      }
      @include media-md {
        flex-basis: 20%;
      }
      @media (max-width: 960px) {
        flex-basis: 40%;
      }

      &--bronze {
        border-color: #279DFE;
        .loyalty-program__progress-tiers-item-icon {
          svg {
            fill: #279DFE;
          }
        }
      }
      &--silver {
        border-color: #AAAAAA;
        .loyalty-program__progress-tiers-item-icon {
          svg {
            fill: #AAAAAA;
          }
        }
      }
      &--gold {
        border-color: #B18A45;
        .loyalty-program__progress-tiers-item-icon {
          svg {
            fill: #B18A45;
          }
        }
      }
      &--platinum {
        border-color: #526163;
        .loyalty-program__progress-tiers-item-icon {
          svg {
            fill: #526163;
          }
        }
      }

      &-icon {
        @include size(70px);
        position: relative;
        margin: 0 auto 15px;
        background-color: $white;
        border-radius: 50%;
        img {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          position: absolute;
          z-index: 2;
          top: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2px 4px;
          background-color: $color-minor;
          color: $color-muted;
          content: attr(data-amount);
          white-space: nowrap;

          .is-current & {

          }
        }
      }
      &-title {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        text-transform: capitalize;
        border-bottom: 1px solid $color-minor;
        padding-bottom: 7px;
        margin-bottom: 7px;
      }
      &-description {
        font-size: 12px;
      }
      &-current {
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        left: 0;
        right: 0;
        top: 10px;
      }
      &.is-current {
        transform: scale(1.15);
        margin: 10px;
        border-width: 4px;

        @media (max-width: 1640px) {
          margin: 15px;
        }
        @include media-lg {
          margin: 10px;
        }
        @media (max-width: 1270px) {
          margin: 15px;
        }
        @include media-md {
          margin: 10px;
        }
        @media (max-width: 960px) {
          margin: 15px;
        }

        ~ div {
          .loyalty-program__progress-tiers-item-icon {

            img {
            }
            span {
            }
          }
          .loyalty-program__progress-tiers-item-title,
          .loyalty-program__progress-tiers-item-description {
          }
        }
      }
    }
  }

  // Circular progress
  &__circular-progress {
    &-content {
      padding: 40px;
      text-align: center;
    }
    &-amount {
      font: {
        size: 48px;
        weight: bold;
      }
    }
  }

  // Help
  &__help {
    margin-top: 30px;
    @include media-sm {
      margin-top: 15px;
    }
  }
}
