.cddn-registration-form {
  .title {
    margin: 0 0 30px;
  }
  &__logo {
    text-align: center;
    margin: 0 0 var(--lg-resize);
    img {
      height: 40px;
    }
  }
  &__stepper {
    margin: 0 0 var(--md-resize);

    @include media-mobile {
      margin-top: var(--md-resize);
    }
  }
  &__step-title {
    @include field-font__h3;

    margin: 0 0 30px;
    font-weight: 700;
  }
  &__message-footer {
    @include field-font__h6;
    color: $gray-2;
  }
  .button-container {
    padding-top: var(--lg-resize);
  }

  &__registration-type-selector {
    display: grid;

    img {
      max-width: 81px;
      max-height: 70px;
    }
    &-name {
      max-width: 81px;
    }
    .radio-checked + .formControlLabel-label {
      border-color: $green-1;
    }
    .radioGroup-root {
      @include flexGap($columns: 2);

      padding-top: 30px;

      @include media-mobile {
        & > div, > button, > a[role="button"] {
          flex: 1 0 auto;
        }
        button {
          max-width: initial;
        }
      }

      span {
        @include field-font__h4;
        color: $black-1;
        font-weight: 700;
        height: 180px;
        background: $gray-4;
      }
    }

    .registration-form__select-bank {
      margin-top: 30px;
    }
    .user-identification,
    .registration-form__manual-button,
    .registration-form__message-footer {
      margin-top: var(--lg-resize);
    }
  }

  &__card-field {
    padding: 16px;
    background: $gray-4;

    h4 {
      margin-bottom: 16px;
      font-weight: 700;
    }

    &--birthDate {
      p {
        display: flex;
        align-items: center;

        &:after {
          @include svg-icon(custom);
          content: '\0044';
          position: initial;
          height: auto;
          margin-left: var(--sm);
          transform: scaleX(1);
          font-size: $field-font__h6;
          color: color(field--primary, border--success);
          background-color: initial;
        }
        img {
          height: 23px;
          width: 23px;
          margin-left: var(--sm);
        }
      }
    }
  }


  .fields-container {
    .gridItem-root {
      &--initials,
      &--lastName,
      &--houseNumber,
      &--zipCode,
      &--country,
      &--city,
      &--birthPlace,
      &--birthCountry {
        flex-basis: 50%;

        @include media-mobile {
          flex-basis: 100%;
        }
      }

      &--phoneNumber {
        padding-top: var(--md-resize);
      }
      &--ageAndPolicyChecked {
        & > .group-root {
          padding-top: var(--md-resize);
          border-top: 1px solid $black-1;
          a {
            text-decoration: underline;
          }
        }
      }
      &--campaignsEnabled {
        display: none;
      }
      &--receiveEmail {
        & > .group-root {
          padding-bottom: var(--md-resize);
          border-bottom: 1px solid $black-1;
        }
      }
    }
  }
  .buttons-container, div.limit-form .buttons-container {
    @include flexGap();

    padding-top: var(--lg-resize) !important;
  }
  .limit-amount {
    max-width: initial;
    height: initial;
    margin: 0;

    label {
      font-weight: 700;
    }

    .field-inputValues {
      .input-values {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 20px 5px;
        margin: 0;
      }

      .input-root {
        display: none;
      }
    }
  }
}
