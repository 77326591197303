.cookie-policy-form {
  .gridItem-root {
    &--initials,
    &--initials {
      flex-basis: 50%;

      @include media-mobile {
        flex-basis: 100%;
      }
    }
  }
}

.buttons-container {
  margin-bottom: 0;

  .cookie-policy-form__button {
    display: flex;
    margin: 0 auto;
    width: 200px;
  }
}

.modal-root .cookie-policy-modal{
  @include media-mobile {
    height: auto;
    position: absolute;
    top: 35%;
  }

}
.cookie-policy-modal {
  .modal-rootButtons {
    padding: 10px;

    @include media-mobile {
      padding: 0;
    }
  }

  .modal-paper .modalContentWrapper {
    padding: 25px 0;

    .modal-titleRoot {
      text-align: center;
      @include field-font__h4;
      padding-top: 15px;
      padding-bottom: 30px;
    }

    p {
      margin-bottom: 60px;
    }
  }

  &__title {
    display: block;
    @include field-font__h3;
    text-transform: none;
    color: color(modal, title);
    text-align: center;
    padding-bottom: 30px;
    padding-top: 15px;
  }

  @include media-mobile {
    .modal-paper {
      width: 90%;
      height: initial;
      border-radius: 6px;

      .modalContentWrapper {
        padding: 25px 0;
        height: max-content;

        .modal-titleRoot {
          padding-top: 5px;
          padding-bottom: 15px;
        }

        p {
          text-align: center;
          font-size: 14px;
          padding: 0 25px;
          margin-bottom: 0;
        }

        .buttons-container {
          max-width: 200px;
          margin: 0 auto;

          .cookie-policy-form__button {
            display: flex;
            margin: 0 auto;
            width: 10px;
          }
        }
      }
    }
  }
}
