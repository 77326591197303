.featured-games {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  &__button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.featured-games-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__slide {
    flex-shrink: 0;
    width: 100%;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__pagination {
    position: absolute;
    z-index: 2;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    .swiper-pagination-bullet {
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      &:not(.swiper-pagination-bullet-active) {
        cursor: pointer;
      }

      &::before {
        background-color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        border: 2px solid $white;
        border-radius: 50%;
        content: "";
        transition: background 200ms;
      }

      &:hover,
      &-active {
        &::before {
          background-color: color(accent, primary);
        }
      }
    }
    @media screen and (max-width: 1620px){
      bottom: 10px;
    }
  }
}
