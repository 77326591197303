.documents-list {
  .tableCell-head {
    border-bottom: 2px solid $gray-2;
    font-weight: bold;
  }
  th,
  td {
    border-bottom: none;
    color: color(text, main);
  }
  .table-cell--status--rejected > span {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  .tableRow-root {
    & + .tableRow-root {
      border-top: 2px solid $gray-2;
    }
    &.table-row--addition {
      width: 100%;
      border-top: none;

      .table-cell {
        &--rejected-reasons {
          ul {
            padding-left: 20px;

            li::marker {
              content: none;
            }
          }
        }
      }
    }
  }
  .table-cell--name {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include media-sm {
    &__button-reasons.hidden--desktop {
      margin-top: 10px;
      font-size: 14px;
      width: 100%;
    }
    &__button-reason {
      cursor: pointer;
      text-decoration: underline;
      color: color(error);

      &:hover {
        text-decoration: none;
      }
    }
    &__upload {
      padding: 3px 10px;
    }
    tbody {
      border: 2px solid $gray-2;
    }
    tr {
      td:first-child {
        font-weight: bold;
      }
    }
    .tableHead-root {
      display: none;
    }
    .tableBody-root {
      display: flex;
      flex-wrap: wrap;
    }
    .tableRow-root:not(.table-row--addition) {
      width: 100%;
      height: 100%;
      padding: 20px;

      .tableCell-root:empty {
        display: none;
      }

      .tableCell-root,
      .table-cell {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 2px;

        .hidden--desktop {
          color: color(text, main);
        }
        &--status {
          &--rejected {
            & > span {
              text-decoration: none;
            }
          }
        }
        &--reasons {
          color: color(error);
        }

        &--reasons,
        &--update,
        &--date,
        &--name {
          justify-content: flex-start;
          text-align: left;
        }
      }
    }
  }
}
