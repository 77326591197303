.game-info-page {
  width: 100%;
  height: 100%;
  background-color: $gray-4;

  picture {
    width: 100%;
  }

  &__main-container {
    margin: 40px auto 55px auto;
    display: flex;
  }
  &__main-content {
    flex: 1 1 auto;
    margin-right: 40px;
    background-color: $white;
    height: max-content;
  }
  &__banner {
    position: relative;
    display: flex;
  }
  &__banner-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  &__statistic-wrapper {
    flex: 0 0 300px;
  }
  &__statistic {
    flex: 0 0 300px;
    height: max-content;
  }
  &__statistic-content {
    background-color: $white;
    border-top: 10px solid $gray-1;
    padding: 0 20px 40px 20px;
    display: flex;
    flex-direction: column;
    ul {
      list-style: none;
      padding: 0;
    }
  }
  &__statistic-tiger {
    max-width: 100%;
    margin-bottom: -50px;
    margin-top: -35px;
  }
  &__statistic-description {
    margin-top: 20px;
    @include field-font__h4;
    &:empty {
      display: none;
    }
  }
  &__statistic-btn {
    width: 100%;
  }
  &__demo-btn {
    margin-top: 15px;
  }
  &__icon {
    width: 100%;
    img {
      width: 100%;
    }
  }
  &__content-body {
    .game-info-page__description:empty + .game-info-page__button{
        display: none;
    }
    .game-info-page__button {
      margin: 0 0 40px 40px;
    }
  }
  &__description {
    padding: 40px;
    @include field-font__h4;
    h1,h2,h3,h4,h5 {
      font-weight: bold;
      margin-bottom: 15px;
    }
    p {
      @include field-font__h4;
    }
    &:empty {
      display: none;
    }
  }
  &__provider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  &__provider-logo {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  &__game-name {
    align-self: flex-start;
    font-size: 18px;
  }
  &__slider-container {
    background-color: $white;
    padding: 40px 0;
    @media (max-width: 1440px) {
      padding: 20px 0;
    }
    @media (max-width: 767px) {
      padding: 25px;
    }
  }
  &__slider-wrapper {
    position: relative;
    max-width: 1630px;
    margin: 0 auto;
    @media (max-width: 1810px) {
      max-width: 1520px;
    }
    @media (max-width: 1630px) {
      max-width: 1275px;
    }
    @media (max-width: 1400px) {
      max-width: 1020px;
    }
    @media (max-width: 1150px) {
      max-width: 850px;
    }
    @media (max-width: 940px) {
      max-width: 690px;
    }
  }
  &__slider {
    .game-slider--info-page {
      position: static;
    }
    .game-slider__nav-prev,.game-slider__nav-next {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.6;
      }
      &:before {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 42px;
      }
      @media (max-width: 1150px) {
        &:before {
          width: 17px;
          height: 30px;
        }
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .game-slider__nav-prev {
      left: -60px;
      &:before {
        content: '';
        background: url('/cms/img/slider/next-gray.svg') no-repeat center center /contain;
      }
      @media (max-width: 1150px) {
        left: -45px
      }
    }
    .game-slider__nav-next {
      right: -60px;

      &:before {
        content: '';
        background: url('/cms/img/slider/next-gray.svg') no-repeat center center /contain;
      }
      @media (max-width: 1150px) {
        right: -45px
      }
    }
  }
  &__group-name {
    @include field-font__h4;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
  }
  &--tablet, &--mobile {
    color: $white;
    .game-info-page__inner {
      background-color: $black-2;
    }
    .game-info-page__main-container {
      background-color: $black-2;
      width: 100%;
      max-width: 100%;
    }
    .game-info-page__main-container {
      margin: 0 auto;
    }
    .game-info-page__main-content {
      background-color: $black-2;
      margin-right: 0;
    }
    .game-info-page__banner-wrapper {
      display: flex;
      margin-bottom: 25px;
    }
    .game-info-page__banner {
      flex: 1 1 50%;
      padding: 0 15px 0px 0;
    }
    .game-info-page__icon img {
      height: auto;
    }
    .game-info-page__statistic {
      height: auto;
      flex: 1 1 50%;
    }
    .game-info-page__statistic-content {
      background-color: $black-2;
      border-top: none;
      padding: 0 0px 0px 15px
    }
    .game-info-page__slider-container {
      background-color: $gray-1;
      border-bottom: 40px solid $black-2;
      padding: 20px 0 30px 0;
      .game-thumbnail {
        background-color: transparent;
      }
    }
    .game-info-page__content-body {
      padding: 0;
    }
  }
  &--tablet {
    .game-info-page__statistic-description {
      margin: 0 0 20px 0;
      flex: 1 1 auto;
      ul {
        column-count: 2;
      }
      &:empty {
        display: block;
      }
    }
    .game-info-page__content-body {
      .game-info-page__button {
        width: calc(50% - 15px);
        margin: 0 0 0px 40px;
      }
    }
    .game-info-page__provider {
      flex-flow: column-reverse;
      align-items: flex-start;
    }
    .game-info-page__statistic-btn {
      order: 3
    }
    .game-info-page__main-container {
      margin: 0 auto;
      padding: 25px 15px 40px 15px;
    }
  }
  &--mobile {
    .game-info-page__main-content {
      min-height: 100vh;
    }
    .game-info-page__main-container {
      margin: 0 auto;
      padding: 25px 0 40px 0;
    }
    .game-info-page__banner-wrapper {
      flex-wrap: wrap;
    }
    .game-info-page__banner {
      flex: 1 1 100%;
      padding: 0;
    }
    .game-info-page__statistic {
      flex: 1 1 100%;
    }
    .game-info-page__statistic-content {
      padding: 0 15px;
    }
    .game-info-page__content-body {
      padding: 0 15px;
      .game-info-page__button {
        margin: 40px 0px 0px 0px
      }
    }
    .game-info-page__button {
      width: 100%;
    }
    .game-info-page__subtitle__wrapper {
      flex-wrap: wrap;
    }
    .game-info-page__game-name {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-weight: 400;
    }
    .game-info-page__slider-container {
      padding: 20px 15px 30px 15px;
    }
    .game-info-page__description {
      padding: 0;
    }
  }
  &__subtitle {
    font-size: 18px;
    text-align: center;
    color: color(text, light);
    @include media-mobile {
      font-size: 14px;
    }
    &--accent {
      margin-left: 5px;
      color: $orange-1;
      text-decoration: underline;
    }
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
  &__close-button {
    @include close-button;
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 15px;
    font-size: 35px;
    color: $gray-4;
    background-color: transparent;
    border-radius: 50%;

    &:hover {
      background-color: transparent;
    }
    &::before {
      font-size: 18px;
    }
    .button-label {
      font-size: 0;
    }
  }
}
