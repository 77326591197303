.payment-provider-icons {
  display: flex;
  flex-wrap: wrap;
  width: initial;
  max-width: initial;

  @include media-mobile {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .payment-provider-icon {
    overflow: hidden;
    padding: 5px 12px 5px 12px;
  }
  .seedPaymentProviderIcon {
    &__image {
      max-height: 36px;
      width: 100%;
      @include media-mobile {
        max-height: 30px;
      }
    }
    &--450004 {
      img {
        width: 40.81px;
        height: 35.98px;
        @include media-mobile {
          width: 34.02px;
          height: 29.98px;
        }
      }
    }
  }
  .payment-provider-icon {
    &--210054 .seedPaymentProviderIcon__image,
    &--210055 .seedPaymentProviderIcon__image {
      filter: none;
    }
  }
}
