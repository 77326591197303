.game-search {
  position: relative;

  &::after {
    display: none;
  }
  &::before {
    @include svg-icon;
    content: '\0059';
    position: absolute;
    top: 8px;
    left: 14px;
    right: auto;
    bottom: auto;
    display: block;
    width: auto;
    height: auto;
    font-size: 19px;
    background: none;
  }
  .home-menu &:hover {
    &::before {
      background: none;
    }
  }
  input {
    width: 200px;
    margin-top: -3px;
    padding: 6px 10px 6px 40px;
    border: 1px solid #9a9a9a;
    background-color: transparent;
    box-shadow: none;

    & + div[role="progressbar"] {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-content: center;
      width: 16px !important;
      height: 100% !important;
      padding-right: 4px;
      box-sizing: content-box;

      svg {
        min-width: 100%;
      }
    }
  }
}
