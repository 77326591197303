.login-form {
  &.form {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0;

    .grid-item {
      padding-top: 0;
      flex-basis: 100%;
      max-width: 100%;

      &--buttons {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        width: calc(100% + 10px);
        margin: -10px 0 0 -10px;

        & > :nth-child(n) {
          flex: 1;
          margin: 10px 0 0 24px;
        }
      }
    }

    > button {
      margin-right: 10px;

      @include media-md{
        margin-bottom: 20px;
      }
    }

    &--header {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding-left: var(--sm-resize);

      .grid-item {
        flex-basis: initial;
        max-width: initial;
      }
    }
    &--header {
      .button-primary, .button-secondary {
        min-width: 130px;
      }
      .field-root {
        max-width: 220px;
      }
      .input-root {
        border-radius: 6px;
      }
      .field-root .field-helper {
        position: absolute;
        top: 40px;
        padding-top: 0;
        padding-bottom: 0;
        min-height: initial;
        background: initial;
        font-size: $field-font__h5;
      }
      .field-error {
        .field-helper {
          color: color(accent, primary);
        }
      }
    }
  }

  .gridItem-root {
    &--container {
      text-align: center;
      padding-top: 0 !important;
    }
    &--field-email {
      padding-top: 20px !important;
    }

    &--forgotModal-title{
      padding: 20px 0 70px 0 !important;
    }
    &--forgotModal-content {
      margin-bottom: 45px;
      p {
        @include field-font__h4;
      }
    }
    &--forgotModal-footer {
      p {
        @include field-font__h4;
      }
    }
    @include media-mobile {
      &--forgotModal-title{
        min-width: inherit;
        margin-top: 10px;
        padding: 10px !important;
      }
      &--forgotModal-content {
        margin-bottom: 0;
        max-width: 395px;
        p {
          font-size: 16px;
        }
      }
      &--forgotModal-footer {
        p {
          font-size: 16px;
        }
      }
    }
  }

  .forgot-password-form {
    .gridItem-root {
      &--title-forgot h2 {
        @include field-font__h3;
      }
      &--message {
        text-align: center;
      }
    }

    @include media-lg {
      min-height: 100%;
      button {
        height: 36px;
      }
    }

    @include media-mobile{
      min-height: 100%;
      button {
        height: 36px;
      }
    }
  }

  &__button {
    &--login, &--forgot {
      padding-top: var(--lg-resize) !important;
    }
    &--stretch {
      display: flex;
      flex-direction: column;
      .button-root {
        margin-top: var(--sm-resize) !important;
      }
    }
  }

  .gridItem-root--title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__logo {
    width: 399px;
    img {
        width: 100%
    }
    @include media-mobile {
      width: 100%;

    }
  }

  &__link--back {
    position: absolute;
    top: var(--md-resize);
    left: var(--lg-resize);
  }
}
