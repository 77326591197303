.session-time {
  &__title {
    font-weight: $font-weight-base;
    font-size: 10px;
  }
  &__icon {
    margin-right: 5px;
    vertical-align: bottom;
  }
  &--has--icon {
    .session-time__title {
      margin-left: 18px;
    }
  }
  &-limit-modal__button--logout {
    margin-top: 20px;
  }
}
