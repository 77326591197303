.select-locale {
  height: 21px;

  .field-helperEmpty {
    display: none;
  }
  .select-icon {
    display: none;
  }
  &__item {
    display: flex;
    align-items: center;

    &--active::after {
      position: absolute;
      right: 48px;
      @include svg-icon(custom);
      content: '\0044';
      display: flex;
      align-items: center;
      margin-left: 7px;
      font-size: 18px;
    }
    &:active {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
    &:hover {
     cursor: pointer;
     background-color: rgba(0, 0, 0, 0.12);
    }
  }
  &__flag {
    height: 20px;
    width: 26.66px;
    margin-right: 10px;
  }
  &__lang-before {
    position: absolute;
    left: 30px;
  }
  &__lang-before,
  &__lang-name {
    font-family: $font-primary;
    margin-top: 2px;
    font-size: 16px;
    text-transform: uppercase;
    flex-wrap: wrap;
  }
  &__lang-name {
    display: flex;
    align-items: center;
    color: $white;
  }
  .select-selectMenu {
    height: initial;
    margin: 0;
    padding: 0;
    line-height: initial;
    border: none;
    background: none;
  }
  .field-select {
    & > div {
      &::before {
        display: none;
      }
      &:hover {
        &::before {
          display: none;
        }
      }
    }
  }
  .select-focused {
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }
  ul {
    padding-bottom: 5px;
  }
  .select-select {
    &[aria-pressed="true"] {
      .select-locale__lang-name {
        &::before {
          content: '';
          position: absolute;
          bottom: -12px;
          border: 10px solid transparent;
          border-bottom: 10px solid $white;
        }
      }
    }
  }
  &__selector {
    .select-menuPaper {
      margin-left: -5px;
      max-width: 170px;
      min-width: auto !important;
      margin-top: 25px;
      background: $white;

      li {
        padding: 5px 20px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
      .select-locale {
        &__lang-name {
          text-transform: initial;
          color: color(primary, main);

          &::after {
            display: none;
          }
        }
      }
    }
  }
}
