.notification-root {
  flex-wrap: nowrap;
  flex: 0 0 auto;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  padding: 0;
  align-items: center;
  border: 1px solid color(primary, main);
  border-radius: 6px;
  background: color(background, body);
  box-shadow: none;
  text-align: left;
  animation: notificationShow 0.3s both;

  & + & {
    margin-top: var(--md-resize);
  }
  &::before {
    @include svg-icon;
    content: '\0041';
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 15px;
    font-size: 24px;
    color: color(background, body);
    background: color(primary, main);
  }

  ol {
    padding-left: 13px;
  }

  .notification-message {
    @include field-font__h4;
    padding: 10px 20px;
    font-weight: 700;
    color: color(text, dark);
  }

  .notification-action {
    margin-right: 0;
    padding-left: 0;
    color: color(text, dark);
    align-self: baseline;

    button {
      @include close-button;
      @include size(20px);
      min-width: 20px;
      min-height: 20px;
      background-color: transparent;

      &::before {
        font-size: 12px;
        color: #596668;
      }
    }
  }
}
.notification-error {
  border-color: color(error);

  &::before {
    content: '\0042';
    color: color(background, body);
    background: color(error);
  }
}
.notification-success {
  border-color: color(success);

  &::before {
    content: '\0043';
    color: color(background, body);
    background: color(success);
  }
}
.notification-warning {
  border-color: color(warning);

  &::before {
    content: '\0044';
    color: color(background, body);
    background: color(warning);
  }
}
.notifications {
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  pointer-events: none;

  @include media-md {
    width: 60%;
  }
  @include media-sm {
    left: 15px;
    width: auto;
  }
  .notification {
    display: flex;
    align-items: flex-end;
    min-width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;
    overflow: hidden;
    pointer-events: all;

    &:last-child {
      padding-bottom: 0;
    }
    &-root {
      margin-bottom: 0;
    }
    ul {
      padding-left: 10px;
    }
    .notification-action {
      .material-icons {
        font-size: inherit;
      }
    }
  }
}

@keyframes notificationShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
