.push-notification-modal {
  &_hidden {
    &.modal-root {
      .modal-paper {
        display: none;
      }
    }
  }
  .modal-paper .modalContentWrapper {
    padding: 25px 0;
  }
  .modal-rootButtons {
    padding: 10px;
  }
  .modal-paper .modalContentWrapper .modal-titleRoot {
    padding: 15px 50px 30px;
    text-align: center;
    @include media-mobile {
      padding: 5px 10px 15px;
    }
  }
  .modal-paper .modalContentWrapper .button-root {
    font-weight: 700;
  }
  .push-notifications-modal {
    &__description{
      margin-bottom: 40px;
      @include media-mobile {
        margin-bottom: 25px;
      }
      p {
        @include field-font__h4;
      }
    }
  }
}

