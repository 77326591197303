.documents-renewal-modal {
  &__iframe {
    width: 100%;
    height: 800px;
  }
  &__button-holder {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  &__description {
    margin-bottom: 30px;
    text-align: center;

    @include media-sm {
      margin-bottom: 20px;
    }
  }
}
