.withdrawal-form {
  &__notification {
    display: block;
    background: color(text, light);
    text-align: justify;
    padding: 20px;

    @include media-sm {
      margin-top: 20px;
    }
  }
  .section--1 {
    .styled-field-input__root {
      cursor: pointer;
    }
  }
  .section--3 {
    .password-button {
      width: 100%;
    }
  }
  &__title {
    display: inline-block;
    margin: 25px 0 10px;
    padding-bottom: 2px;
    @include field-font__h3;
    font-weight: $font-weight-alt;
    border-bottom: 6px solid color(secondary, main);

    p {
      margin: 0;
    }
    &--method {
      margin-top: 0;
    }
  }
  &__limits {
    margin-top: 30px;

    .gridItem-root {
      text-align: center;
      font-weight: 400;
    }
  }
  &__confirm-button {
    margin: 33px 0 0 0;
    .button-root {
      width: 100%;
    }
  }
  .field-inputValues {
    label[data-shrink='true'] {
      display: block;
    }
    .input-values {
      display: none;
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(secondary, main);

    strong {
      font-size: 20px;
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }
  &__fields {
    .gridItem-root[class*='withdrawal-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;

      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
  &__field {
    &--amount {
      .input-before {
        padding-right: 2px;
      }
      &.field-empty {
        .input-before {
          display: inline;
        }
      }
      .input-root {
        padding-left: 15px;
      }
      &.field-success {
        .input-root {
          border-color: $green-1;
        }
      }
    }
  }
  &__stored-fields {
    .field-label {
      margin-bottom: var(--md-resize);
      font-size: 24px;
      font-weight: 700;
      color: $black-1;
    }
  }
  .notification-root + .iban-check-form__wrapper {
    margin-top: 20px;
  }
}
.withdrawal-summary {
  margin-bottom: 20px;

  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__deduct,
  &__receive {
    margin-top: 100px;

    @include media-xs {
      max-width: 100%;
    }
    .group-root {

      p {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__total {
    margin-top: 10px;
    font-weight: 700;
  }
}

/** Bank account */
.withdrawal-form--1001 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** IBAN */
.withdrawal-form--1011 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--ibanAccountOwnerName,
      &--address,
      &--ibanAccount,
      &--ibanBankName,
      &--bankLocation,
      &--bankCity,
      &--beneficiaryCity,
      &--beneficiaryCountry,
      &--beneficiaryState,
      &--beneficiaryZipCode,
      &--beneficiaryStreet,
      &--beneficiaryHouseNumber,
      &--beneficiaryAddress,
      &--ibanBSB,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

.withdrawal-form__frame {
  width: 100%;
  height: 666px;
  border: none;

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }
  & + .withdrawal-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
  &.loading + .withdrawal-form__circular-progress {
    display: block;
  }
}
.modal-root {
  .modal-paper {
    .modalContentWrapper {
      .modal-contentRoot {
        .withdrawal-form__back {
          position: absolute;
          top: 40px;
          left: 80px;
          display: flex;
          min-width: 0;
          min-height: 0;
          padding: 0;
          font-size: 14px;
          border: 0;
          background: none;
          overflow: hidden;
          transition: all 0.2s;

          .button-label {
            transform: translateY(-100%);
            transition: transform .2s;
          }
          &::before {
            @include svg-icon;
            content: '\0062';
            margin-right: 5px;
            font-size: 15px;
            transform: rotate(180deg);
          }
          &:hover {
            color: color(secondary, main);

            .button-label {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}

.revoke-confirmation-modal {
  .modal-paper {
    width: 550px;
  }
}
