.session-summary {
  &__last-login-time {
    font-size: 12px;

    &-text {
      display: block;
      padding-top: 16px;
      color: color(text, main);
    }
  }

  &__last-login-time:before {
    content: "";
    display: block;
    margin: 0 5px;
    height: 1px;
    background: color(limit-amount, switch-default);
  }

  &__opening-balance {
    display: flex;
    justify-content: space-between;

    &-text {
      display: flex;
      align-items: center;
      padding: 10px 5px;
    }

    &-text::before {
      @include svg-icon;
      content: '\2260';
      padding-right: 8px;
      font-size: 20px;
    }

    &-wrapper {
      padding: 10px 5px;
      text-align: end;
    }

    &-value {
      font-weight: 700;
    }

    &-cash-plus-promo {
      font-size: 10px;
      font-weight: 700;
    }
  }

  &__bet-statistic {
    position: relative;
    padding: 0 5px;

    &-text {
      text-align: start;
      font-size: 12px;
      font-weight: 400;
    }

    &-text:first-of-type {
      color: color(text, main);
    }

    .progress {
      height: 1.15em;
      border-radius: 10px;
      background-color: color(turnover-progress, progress);

      .progress-bar {
        background-color: color(limit-amount, progress-path);
      }
    }

    &-wrapper {
      display: flex;
      padding: 10px 0;
      font-size: 12px;
    }

    &-win {
      &-circle:after {
        content: '';
        display: inline-block;
        margin-left: 6px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: color(limit-amount, progress-path);
      }
    }

    &-bet {
      margin-left: 32px;

      &-circle:after {
        content: '';
        display: inline-block;
        margin-left: 6px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: color(turnover-progress, progress);
      }
    }

    &-total {
      margin-left: 32px;
    }

    &-text {
      padding-bottom: 6px;
      text-align: left;
    }
  }

  &__bet-statistic:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 5px;
    right: 5px;
    height: 1px;
    background-color: color(limit-amount, switch-default);
  }
}

