.home-content {
  &__provider-title {
    @include field-font__h3;
    margin-top: 10px;
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__provider-games-header {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
  }
  &__provider-name {
    @include field-font__h3;
    margin-right: 15px;
    line-height: 1;
  }

  &__provider-games-count {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    span {
      @include field-font__h3;
      margin-right: 15px;
      line-height: 1;
    }
  }
  &__provider-search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0;
    .gameGroups-filter {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      max-width: 541px;
      width: 100%;
      flex-wrap: wrap;
      &__actions {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        max-width: 100%;
        @media (max-width: 580px) {
          flex-wrap: wrap;
          gap: 0px;
        }
      }
      &__btn {
        height: 40px;
        flex-basis: 225px;
        box-shadow: 0 0 6px 0 #00000040;
        background-color: $white;
        border: none;
        font-size: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        z-index: 2;
        position: relative;
        @media (max-width: 580px) {
          flex: 1 1 auto;
        }

        &-border {
          height: 38px;
          position: absolute;
          right: 41px;
          border: none;
          box-shadow: 0px 0px 0px 1px $black-1;
          opacity: 0.4;

          @include media-mobile {
            display: none;
          }
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            content: '';
            width: 20px;
            height: 15px;
            background: url("/cms/img/icons/filter.svg") no-repeat center / cover;
            margin-right: 9px;
          }
          &::after {
            @include svg-icon(custom);
            content: '\0041';
            position: absolute;
            right: 9px;
            top: 14px;
            font-size: 16px;
            opacity: 0.4;
            color: $black-1;
            transform: rotate(90deg);
            z-index: 100;
            cursor: pointer;
          }
        }
      }
      &__select {
        position: absolute;
        min-width: 224px;
        top: 56px;
        right: 0;
        background-color: $white;
        border-radius: 5px;
        padding: 12px;
        z-index: 1001;
        @media (max-width: 580px) {
          top: 106px;
        }
      }
      &__checkbox.field-primary.field-checkbox {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__selected-items {
        display: flex;
        flex: 1 1 100%;
        height: 100%;
        flex-wrap: wrap;
      }
      &__selected-item {
        display: flex;
        align-items: center;
        color: $gray-2;
        height: 36px;
        box-shadow: 0px 0px 6px 0px #00000040;
        background-color: $white;
        border-radius: 5px;
        border: none;
        font-size: 18px;
        padding: 0 12px;
        margin-right: 10px;
        margin-bottom: 10px;
        span {
          flex: 1 1 auto;
          margin-right: 20px;
          line-height: 1.1;
        }
      }
      &__remove-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        background-color: transparent;
        padding: 0;
        &:before {
          content: "E";
          font-family: "Glyphter-custom";
          font-size: 12px;
          font-weight: 400;
          display: inline-block;
          color: $gray-2;
        }
      }

      @include media-mobile {
        display: flex;
        width: 100%;
      }
    }
    .search-input {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        height: 40px;
        box-shadow: 0px 0px 6px 0px #00000040;
        background-color: $white;
        border-radius: 5px;
        border: none;
        @media (max-width: $size-sm) {
          padding: 10px 40px;
        }
      }

      .input-before {
        top: 50%;
        left: 30px;
        @media (max-width: $size-sm) {
          left: 10px;
        }

        .game-name-filter__icon {
          &::before {
            @include svg-icon(custom);
            content: '\0059';
            font-size: 25px;
            vertical-align: middle;
            color: #8F9090;
          }
        }
      }
      .game-name-filter__clear {
        @media (max-width: $size-sm) {
          right: 10px;
        }
      }
      @include media-mobile {
        margin-bottom: 10px;
      }
    }
  }
}

.game-providers {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  @media (max-width: $size-md) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $size-xs) {
    grid-template-columns: repeat(2, 1fr);
  }
  &__provider {
    background-color: #2c0b3f;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__provider-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__provider-count {
    position: absolute;
    color: $white;
    text-align: center;
    bottom: 15px;
    @media (max-width: $size-lg) {
      bottom: 10px;
      p {
        font-size: 14px;
      }
    }
    @media (max-width: $size-ml) {
      bottom: 5px;
    }
    @media (max-width: $size-md) {
      bottom: 10px;
    }
    @media (max-width: $size-sm) {
      bottom: 5px;
      font-size: 12px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &--desktop {
      .game-providers__info-body {
        display: flex;
      }
    }
    &--mobile {
      flex-direction: column;
      margin: 0 0 20px 0;
      .game-providers__info-body {
        margin-right: 0;
      }
      .game-providers__statistic {
        flex-basis: auto;
      }
      .game-providers__statistic-provider {
        display: none;
      }
      .game-providers__description-about {
        padding: 40px 20px 0px 20px;
      }
      .game-providers__description-text {
        padding: 20px;
      }
      .game-providers__statistic-list {
        width: 80%;
      }
      .game-providers__banner {
        img {
          width: 100%;
        }
      }
    }
    &--tablet {
      .game-providers__statistic {
        height: max-content;
      }
      .game-providers__banner {
        img {
          width: 100%;
        }
      }
    }
  }
  &__info-body {
    flex: 1 1 auto;
    min-height: 326px;
    gap: 30px;
  }
  &__banner {
    img {
      max-width: 100%;
      min-height: 209px;
    }
    @include media-mobile {
      flex-basis: 0;
    }
    @media (max-width: $size-ml) {
      img {
       min-height: initial;
      }
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    background-color: $white;

    .game-providers__description-text:empty ~ .game-providers__description-about {
      display: none;
    }
  }
  &__description-text {
    padding: 20px 40px 40px 40px;
    flex: 1 1 auto;
    @media (max-width: $size-sm) {
      padding: 20px;
      p {
        font-size: 14px;
      }
    }
    &:empty {
      display: none;
    }
  }
  &__description-about {
    font-weight: bold;
    padding: 40px 40px 0px 40px;
    @include field-font__h3;
    @media (max-width: $size-sm) {
      padding: 20px 20px 0 20px;
    }
  }
  &__statistic {
    flex: 0 0 300px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    @media (max-width: $size-sm) {
      flex-basis: 250px;
    }
    @include media-mobile {
      margin-bottom: 10px;
    }
  }
  &__statistic-about {
    font-weight: bold;
    margin-bottom: 20px;
    @media (max-width: $size-sm) {
      font-size: 14px;
    }
  }
  &__statistic-provider {
    padding: 15px 20px;
    color: $white;
    background-color: $orange-1;
    font-weight: bold;
  }
  &__statistic-list {
    padding: 15px 20px;
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;
      @media (max-width: $size-sm) {
        font-size: 14px;
      }
    }
  }
  &__statistic-total {
    font-weight: bold;
  }
}

