.reset-password {
  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
    background: var(--content-background);
  }
  .button-primary {
    margin-top: 20px;
  }
  &__success {
    padding: 50px 0;
  }
}
