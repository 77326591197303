.scroll-to-top {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100vw;
  animation: fadeIn .7s ease-in-out 1s both;
  cursor: pointer;
  z-index: 1001;

  .container {
    display: flex;
    justify-content: flex-end;
    height: 0;
  }

  button {
    position: absolute;
    bottom: 20px;
    min-width: initial;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    flex-direction: column;
    padding: 10px 15px;
    border-radius: 25px;
    opacity: .5;
    transition: $main-transition-time;

    &:before {
      @include svg-icon(custom);
      content: '\0041';
      transform: rotate(-90deg);
    }

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
