.typography {
  font-size: 16px;
  color: color(text, dark);
  letter-spacing: 0;

  h1 {
    margin-bottom: var(--md-resize);
    font-size: 32px;
    letter-spacing: 0.61px;
    @include media-mobile {
      font-size: 22px;
    }
  }
  h2 {
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    font-size: 16px;
    font-weight: 700;
    @include media-xs {
      margin: 20px 0;
    }
  }
  h3 {
    margin: var(--md-resize) 0;
    @include field-font__h4;
    font-weight: $font-weight-alt;
    text-transform: uppercase;
  }
  p {
    margin-top: var(--md-resize);
  }
  a {
    color: $green-1;
    font-weight: $font-weight-alt;
    text-decoration: none;
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
  a.anchor {
    display: inline-block;
    margin-top: var(--md-resize);
    margin-bottom: var(--lg-resize);
  }
  table {
    border-collapse: collapse;
    tr {
      text-align: left;
      td {
        padding: var(--md-resize) var(--lg-resize);
        border: 1px solid #353233;
      }
      td:first-child {
        width: 50%;
      }
    }
    tr + tr {
      border-top: 2px solid $gray-2;
    }
  }
  ol, ul {
    margin: var(--md-resize) 0;
    padding-left: 19px;
  }
  ol.main-list {
    counter-reset: number-of-lists;
    list-style-type: none;
    li{
      position: relative;
      padding-left: 1em;
      margin-bottom: 2px;
      line-height: initial;

      &:before {
        position: absolute;
        top: 0;
        left: -15px;
        counter-increment: number-of-lists;
        content: counter(number-of-lists)".";
        color: $green-1;
        font-weight: $font-weight-alt;
      }
    }
  }
  ol[type='A'] {
    list-style-type: lower-alpha;
    padding-left: 34px;
    li::marker { font-family: monospace; font-weight: $font-weight-alt;}
    @include media-mobile {
      padding-left: 27px;
    }
  }
  img {
    max-width: 840px;
    width: 100%;
    margin-bottom: var(--md-resize);
  }
  /* styled for Responsible Gambling page*/
  .responsible-gambling {
    &-questionnaire p {
      margin-top: 0;
    }
    &-questionnaire h3 {
      margin: 0;
    }
    &__navigation {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: var(--md-resize) 0;

      @include media-md {
        flex-direction: column;
      }
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        color: var(--black);
        font-weight: 400;
        text-align: center;

        @include media-md {
          flex-direction: initial;
          justify-content: left;
          max-width: initial;
          width: 100%;
          padding: 0 0 var(--md-resize) 0;
        }

      }
      &-image {
        width: 28px;
        height: 28px;
        margin-bottom: 0;
        @include media-mobile {
          width: 18px;
          height: 20px;
        }
      }
      &-image-wrap {
        width: 50px;
        height: 50px;
        background-color: $orange-1;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: var(--sm-resize);
        @include media-md {
          margin-bottom: initial;
          margin-right: var(--md-resize);
        }
        @include media-mobile {
          width: 35px;
          height: 35px;
        }
      }
    }
    &__card {
      position: relative;
      padding: 27px 0 var(--lg-resize) 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: calc(100% + 60px);
        height: 1px;
        background: color('background', 'body');
      }
      &:last-of-type {
        padding-bottom: 18px;
      }
      &-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 100%;
        min-height: 220px;
        max-height: 220px;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(31, 34, 34, 0) 30%, rgba(31, 34, 34, 0) 70%, rgba(0, 0, 0, 0.5) 100%);
        }
        &-circle {
          border-radius: 100%;
          z-index: 1;

          &_border {
            width: 100px;
            height: 100px;
            padding: 5px;
            border: 3px solid $orange-1;
          }
          &_solid {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.85);
          }
        }
        &-line {
          width: 55px;
          height: 3px;
          background: $orange-1;
          opacity: 0.85;
        }
      }
    }
    &__accordion {
      margin: var(--md-resize) 0;
      &-tab {
        margin-bottom: 1px;
        &-content, input {
          display: none;
        }
        &-content {
          padding: 10px 20px;
          background: #f5f5f5;
          p {
            margin: var(--md-resize) 0;
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          @include media-mobile {
            padding-left: 15px;
          }
        }
        &-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: var(--sm-resize) var(--md-resize);
          font-weight: $font-weight-alt;
          color: color('background', 'post-login-menu');
          cursor: pointer;
          background: #f5f5f5;

          &::after {
            @include svg-icon(custom);
            content: '\0041';
            transform: rotate(90deg);
            transition: rotate ease-in-out .3s;
            font-weight: bold;
            color: $gray-7;
          }
        }
      }
    }
  }
  /* these selectors had to be moved due to the use of "+" "~" */
  .responsible-gambling__accordion-tab :checked + .responsible-gambling__accordion-tab-title::after {
    transform: rotate(-90deg);
  }
  .responsible-gambling__accordion-tab :checked ~ .responsible-gambling__accordion-tab-content {
    display: block;
  }
  .signature {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: var(--md-resize);
    &__text p {
      margin: 0;
    }
    &__logo {
      max-width: 244px;
      margin: 0;
    }
    @include media-mobile{
      flex-direction: column;
      align-items: flex-start;
      &__logo {
        margin-top: var(--md-resize);
        margin-left: auto;
      }
    }
  }
  @media screen and (max-width: 560px){
    font-size: 14px;
  }
}
.typography .static-postLogin {
  display: none;
}
.is-logged-in .typography {
  .static-postLogin {
    display: block;
  }
  .static-preLogin {
    display: none;
  }
}
.static-page__content .content-postLogin {
  display: none;
}
.post-login-content-wrap  .content-preLogin {
  display: none;
}

.content {
  &:first-child {
    .explanatory-information {
      &__title {
        height: 50px;
        padding-top: 20px;
      }
    }
  }
}
