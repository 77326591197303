.footer-menu {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .tablet & {
    justify-content: space-around;
  }
  @include media-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      width: 50%;
    }
  }
  a {
    @include link--root;
    @include link--color(light);
    @include link--hover(light);
    @include field-font__h3;

    position: relative;
    display: inline-block;
    padding: 18px 15px;
    font-size: 16px;
    letter-spacing: 0.6px;

    @include media-mobile {
      display: flex;
      align-items: center;
      padding: 7px 12px;
    }
  }
}
