.document-proof-form {
  &__content {
    display: flex;
    flex-wrap: wrap;
  }
  &__content-uploading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  &__selected-type {
    width: 100%;
  }
  &__spinner {
    margin: 0 auto;
  }
}
