.user-information {
  flex: 1;
  padding: 24px 12px;
  background: color(secondary, main);
  color: $black-1;

  h4 {
    margin: 0;
    font-size: 18px;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
}
