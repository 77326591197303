.netherlands-limits-modal {
  position: absolute;
  bottom: var(--sm);
  right: var(--md);
  min-width: 358px;
  max-width: calc(100vw - 2 * var(--md));
  border-radius: 5px;
  background: $white;
  z-index: 2001;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  &--nl {
    min-width: 385px;
  }
  @include media-mobile {
    min-width: 345px;
    &--nl {
      min-width: 365px;
    }
  }
  @media (max-width: 390px) {
    min-width: 315px;
    &--nl {
      min-width: 335px;
    }
  }
  &.modal-root .modal-paper {
    width: 600px;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
    padding: var(--md) var(--md) 0 var(--md);
    @include media-mobile {
      padding: var(--sm) var(--sm) 0 var(--sm);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__message {
    margin-top: var(--md-resize);

    p {
      margin: 0;
    }
  }

  &__limit-title {
    border-top: 1px solid #e3e3e3;
    padding: 15px var(--md);
    @include field-font__h6;
    @include media-mobile {
      padding: 10px var(--sm);
    }
    &--sub {
      font-weight: 700;
    }
    &--session {
      display: flex;
      margin-bottom: 10px;
      .netherlands-limits-modal__title--session {
        margin-right: 15px;
      }
    }
    &--prevLogin {
      background-color: #ccc;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__close-button{
    @include close-button;
    background: #57696B;
    position: absolute;
    right: 15px;
    top: 10px;

    @include media-xs {
      top: 3px;
    }

    &:hover {
      background: #57696B;
    }

    &:before {
      left: 36%;
      font-size: 14px;
    }
  }

  &__linear-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--row {
    &-title {
      font-weight: $font-weight-base;
      text-transform: uppercase;
    }
  }
  &--row-title, &--wrapper {
    padding: 10px;
  }

  &--limit {
    &-content {
      margin-bottom: 4px;
    }
  }

  .buttons-container {
    margin-top: var(--md-resize);
    justify-content: space-between;
  }
  &__session-circular-amount {
    display: flex;
    margin: 17.5px 0 -15px 0px;
    flex: 1 1 auto;
    justify-content: space-evenly;

    @include media-xs {
      justify-content: space-between;
      margin: 12px 0 -15px 0px;
    }

    &:has(.available-limit-amount-available__deposit){
      display: flex;
      flex-direction: column;
      margin: 0;
      gap: 2px;
    }

    .available-limit-amount {
      flex: 0 0 75px;
      font-size: $field-font__h6;
      &:nth-child(2) {
        margin: 0 4px;
      }
      @media (max-width: 390px) {
        flex-basis: 65px;
      }
      &__label {
        position: absolute;
        top: -8px;
        @include field-font__h6;
        @include media-mobile {
          top: -2px
        }
      }
      &__title {
        text-align: center;
        font-size: $field-font__h5--mobile;
        position: relative;
        bottom: -16px;

        @include media-xs {
          position: relative;
          font-size: 11px;
        }
      }
      &__content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        height: 100%;
        top: -10px;
      }
      &-available__deposit{
        font-size: 12px;

        @include media-xs {
          display: flex;
          flex-direction: column;
        }
      }
      &__available {
        @include field-font__h6;
        font-size: 12px;
        font-weight: $font-weight-alt;
        position: relative;
        bottom: -16px;
        width: calc(100% + 20px);
        text-align: center;

        @include media-xs {
          position: relative;
          font-size: 11px;
        }
      }
      &__percentage-label {
        background: $green-1;
        border-radius: 2px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        height: 18px;
        pointer-events: none;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding: 3px;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .circularProgress-back {
        stroke: color(limit-amount, progress-back);
      }
      .circularProgress-path {
        stroke: $green-1;
      }
    }
  }
  &__deposit-title {
    display: flex;

    &--caption {
      margin-right: 15px;
    }
  }
  &__deposit-limits {
    margin-left: 3px;
    flex-direction: column;
  }
  &__deposit-limit {
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
